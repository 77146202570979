import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { HttpService } from '@services/http.service';


//SUB COMPONENTS
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';

import { CommonModule, JsonPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@envs/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


import { ChartConfiguration, ChartData, ChartEvent } from 'chart.js';
import { isPlatformBrowser } from '@angular/common';

import { DashboardService } from '@services/dashboard.service';
import { ServerResponse } from '@interfaces/general.interface';
import { Rider, Consumer } from '@interfaces/models.interface';
import {RouterModule} from '@angular/router';
import { NgbModal,NgbAlertModule, NgbDatepickerModule, NgbDateStruct, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMap} from '@angular/google-maps';

@Component({
  selector: 'app-dashboard-home',
  standalone: true,
  imports:      [ CommonModule, HeaderComponent, NavComponent, FooterComponent, RouterModule , NgbDatepickerModule, NgbAlertModule, NgbTimepickerModule, FormsModule, GoogleMapsModule, GoogleMap],
  templateUrl: './dashboard-home.component.html',
  styleUrl: './dashboard-home.component.css'
})

export class DashboardHomeComponent {
  today = new Date();
  model?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };

  constructor(
    private httpService: HttpService,
    private dasher: DashboardService,
    private cooker: CookieService,
    private router: Router,
  ) { 
  }

  public consumerList: Consumer[] = [];
  public riderList: Rider[] = [];
  
  homepageSummary: Record<string, any> = {
    totalUser: 0,
    totalActiveUser: 0,
    totalDistance: 0,
    totalPartner: 0,
    totalSales: 0,
    totalEarnings: 0,
    vehicleOverview: {
      'Motorcycle': 0,
      'Sedan': 0,
      'SUV': 0,
      'MPV': 0,
      'Pickup': 0,
      'FB/Van': 0,
    }
  }
  
  polyLinePath: google.maps.LatLng[] = [];
  display: any;
  center: google.maps.LatLngLiteral = {
      lat: 22.2736308,
      lng: 70.7512555
  };
  zoom = 15;
  polyLineOptions: google.maps.PolylineOptions = {
    strokeColor: '#D81960',
    strokeOpacity: 1.0,
    strokeWeight: 5,
    clickable: false,
    editable: false,
    visible: true,
    geodesic: true, // Smoothen the edges
  };
  
  ngOnInit(): void {
    this.dasher.tryFetchHomepageSummary().subscribe((res: any) => {
      Object.keys(res).forEach((dataIndex : any) => {
        if(dataIndex == 'vehicleOverview'){
          res[dataIndex].forEach((instance: any) => {
            this.homepageSummary[dataIndex][instance['vehicle']] = instance['total'];
          });
        }else{
          this.homepageSummary[dataIndex] = res[dataIndex];
        }
      });
    });
    
    console.log(this.homepageSummary);
    
    //let encoded = google.maps.geometry.encoding.decodePath('aphxAarvaVlA_JhCZ`ARhALT?dCe@bBa@hAOfCm@dB[p@StE}@b@KG{C[eHCUOyFH{ATyBXuA`CeGlAeCt@uB|DsJx@{AfBkEp@w@Rk@]gB?Ku@sBQ_@yBqGpAi@bC}@PEs@}ByAmDx@]HNPFRCDEn@@');
    let encoded = google.maps.geometry.encoding.decodePath('aphxAarvaVlA_JhCZ`ARhALT?dCe@bBa@hAOfCm@dB[p@StE}@b@KG{C[eHCUOyFH{ATyBXuA`CeGlAeCt@uB|DsJx@{AfBkEp@w@Rk@]gB?Ku@sBQ_@yBqGpAi@bC}@PEs@}ByAmDx@]HNPFRCDEn@@');
    this.polyLinePath = google.maps.geometry.encoding.decodePath('aphxAarvaVlA_JhCZ`ARhALT?dCe@bBa@hAOfCm@dB[p@StE}@b@KG{C[eHCUOyFH{ATyBXuA`CeGlAeCt@uB|DsJx@{AfBkEp@w@Rk@]gB?Ku@sBQ_@yBqGpAi@bC}@PEs@}ByAmDx@]HNPFRCDEn@@');

     // Calculate the bounds of the polyline
     const bounds = new google.maps.LatLngBounds();
     encoded.forEach((coordinate: google.maps.LatLng) => {
       bounds.extend(coordinate);
     });
 
     // Center the map to the center of the polyline bounds
     this.center = bounds.getCenter().toJSON();

    /* this.dasher.tryFetchRiders().subscribe((res: any) => {
      if(res.length != 0){
        res.forEach((rider_isntance : any) => {
          let i = rider_isntance;
          this.riderList.push(i);
        });

        this.totalPartners = this.riderList.filter((x) => x.isApprove).length
      }//end if
    }); */
  }  
  
  /*------------------------------------------
  --------------------------------------------
  moveMap()
  --------------------------------------------
  --------------------------------------------*/
  moveMap(event: google.maps.MapMouseEvent) {
      if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  /*------------------------------------------
  --------------------------------------------
  move()
  --------------------------------------------
  --------------------------------------------*/
  move(event: google.maps.MapMouseEvent) {
      if (event.latLng != null) this.display = event.latLng.toJSON();
  }
}