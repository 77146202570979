import { gql } from 'apollo-angular';

export const WITHDRAWAL_REQUESTS = gql`
  query withdrawalRequests(
    $page: Int,
    $status: WithdrawalRequestStatusEnum!
    $withdrawalWalletFilterInput: WithdrawalWalletFilterInput
  ) {
    withdrawalRequests(
      page: $page,
      status: $status
      withdrawalWalletFilterInput: $withdrawalWalletFilterInput
    ) {
      partnerWalletRequestId
      bankCode
      bankName
      accountNumber
      accountName
      amount
      remarks
      status
      dateApproved
      dateCancelled
      dateUpdated
      dateCreated
      partner {
        partnerId
        firstName
        lastName
        email
      }
    }
  }
`; 