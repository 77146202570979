import { gql } from 'apollo-angular';

export const COMMAND_CENTER_EXPRESS_OVERVIEW = gql`
  query commandCenterExpressOverview {
    commandCenterExpressOverview {
      twoWheelsBookingCount
      fourWheelsBookingCount
      pending {
        twoWheels
        fourWheels
      }
      ongoing {
        twoWheels
        fourWheels
      }
      completed {
        twoWheels
        fourWheels
      }
      cancelled {
        twoWheels
        fourWheels
      }
    }
  }
`;

export const COMMAND_CENTER_MOTOTAXI_OVERVIEW = gql`
  query commandCenterMotoTaxiOverview {
    commandCenterMotoTaxiOverview {
      pending
      ongoing
      completed
      cancelled
    }
  }
`;

export const COMMAND_CENTER_OVERVIEW = gql`
  query commandCenterOverview {
    commandCenterOverview {
      totalUser
      twoWheels {
        totalApprovedPartners
        expressCount
        motoTaxiCount
      }
      fourWheels {
        totalApprovedPartners
        expressCount
        okRideCount
      }
    }
  }
`;

export const COMMAND_CENTER_OKRIDE_OVERVIEW = gql`
  query commandCenterOkRideOverview {
    commandCenterOkRideOverview {
      pending {
        fourSeaterCount
        sixSeaterCount
      }
      ongoing {
        fourSeaterCount
        sixSeaterCount
      }
      completed {
        fourSeaterCount
        sixSeaterCount
      }
      cancelled {
        fourSeaterCount
        sixSeaterCount
      }
    }
  }
`;