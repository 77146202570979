<div class="wrap">
    <app-header></app-header>
        <div class="main-holder" *ngIf="!this.showDetails; else showDetailComponent">
            <app-nav></app-nav>
            <div class="main">
                <div class="main-content">
                    <div class="bread">
                        <ol>
                            <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><span>Customers</span></li>
                        </ol>
                    </div>
                    <div class="content-title">
                        <h2>Customers</h2>
                    </div>
                    <hr>
                    <div class="cards">
                        <div class="row">
                            <div class="col-md">
                                <div class="card-item">
                                    <div class="card-content">
                                        <small>Verified Customers</small>
                                        <h4>{{ this.userOverview['totalVerified'] }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/verified.svg">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item">
                                    <div class="card-content">
                                        <small>Pending Registration</small>
                                        <h4>24</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/users.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards">
                        <div class="row">
                            <div class="col-md">
                                <div class="card-item">
                                    <div class="card-content">
                                        <small>Booked 2-wheels Express</small>
                                        <h4>223</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/riders.svg">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item">
                                    <div class="card-content">
                                        <small>Booked 2-wheels MotoTaxi</small>
                                        <h4>124</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/riders.svg">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item">
                                    <div class="card-content">
                                        <small>Booked 4-wheels Express</small>
                                        <h4>214</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item">
                                    <div class="card-content">
                                        <small>Booked 4-wheels Ride</small>
                                        <h4>240</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/supply.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="list-holder">
                        <div class="tools">
                            <div class="calendar">
                                <input type="text" name="" class="text-calendar" placeholder="04/18/2024">
                                <img src="./../../../assets/okito/images/calendar.svg">
                            </div>
                            <div class="pager">
                                <ol>
                                    <li class="active"><a href="#">Past 7 Days</a></li>
                                    <li><a href="#">Past 30 Days</a></li>
                                </ol>
                            </div>
                        </div>
                    </div> -->
                   <!--  <div class="cards">
                        <div class="row">
                            <div class="col-md">
                                <div class="card-item">
                                    <div class="card-content">
                                        <small>Registered</small>
                                        <h4>{{ this.userOverview['totalRegistered'] }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/users.svg">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item">
                                    <div class="card-content">
                                        <small>Verified</small>
                                        <h4>{{ this.userOverview['totalVerified'] }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/verified.svg">
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="card-item">
                                    <div class="card-content">
                                        <small>With Booking</small>
                                        <h4>{{ this.userOverview['totalWithBookings'] }}</h4>
                                    </div>
                                    <img src="../../../../assets/okito/images/card-icons/verification.svg">
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="list-holder">
                        <div class="tools">
                            <div class="search" style="width:80%;">
                                <img src="../../../../assets/okito/images/icons/magnifying.svg">
                                <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                    placeholder="Search Customer" style="width:30%;margin-right:10px;">
                                    <!-- <span style="font-weight:bold;margin-right:5px;margin-left: 1%;"><i class="fa fa-reorder"></i>&nbsp;Sort by: </span>
                                    <select class="form-select" style="width:30%;margin-right:10px;">
                                        <option value="name">Name</option>
                                        <option value="currentPoints">Current PTS</option>
                                        <option value="email">Email</option>
                                        </select>
                                        
                                        <div class="btn-group" role="group" aria-label="Sort by.." style="width:15%;">
                                            <button (click)="setSortOrder('asc')" [ngClass]="{'active': this.sortOrder['asc']}" type="button" class="btn btn-primary"><i class="fa fa-sort-amount-asc"></i>&nbsp; Asc</button>
                                            <button (click)="setSortOrder('desc')" [ngClass]="{'active': this.sortOrder['desc']}" type="button" class="btn btn-primary"><i class="fa fa-sort-amount-desc"></i>&nbsp; Desc</button>
                                        </div> -->
                            </div>
                            <div class="actions">
                                <button class="btn btn-sm btn-default"><i class="fa fa-download"></i>&nbsp;Download</button>
                            </div>
                        </div>
                       <!--  <div class="list-tabs">
                            <ul>
                                <li [ngClass]="{'active': this.consumerTabs['all']}"><a (click)="filterConsumers('all')" style="cursor: pointer;">All</a></li>
                                <li [ngClass]="{'active': this.consumerTabs['w_booking']}"><a (click)="filterConsumers('w_booking')" style="cursor: pointer;">With Booking</a></li>
                                <li [ngClass]="{'active': this.consumerTabs['in_transit']}"><a (click)="filterConsumers('in_transit')" style="cursor: pointer;">In-transit</a></li>
                                <li [ngClass]="{'active': this.consumerTabs['completed']}"><a (click)="filterConsumers('completed')" style="cursor: pointer;">Completed</a></li>
                                <li [ngClass]="{'active': this.consumerTabs['n_booking']}"><a (click)="filterConsumers('n_booking')" style="cursor: pointer;">No Booking</a></li>
                            </ul>
                        </div> -->
                        <div class="list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Customer ID</th>
                                        <th>Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                        <th>Date Created</th>
                                        <th>Current Pts</th>
                                        <th>Used Pts</th>
                                        <th>Total Pts</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                        <tr *ngFor="let consumer of this.currentSelectedPageList">
                                            <td>{{ consumer.membershipId }}</td>
                                            <td>{{ consumer.name }}</td>
                                            <td>{{ consumer.mobile }}</td>
                                            <td>{{ consumer.email }}</td>
                                            <td>{{ consumer.dateCreated | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</td>
                                            <td>{{ consumer.currentPoints | number: '1.2-2'}}</td>
                                            <td>{{ consumer.usedPoints | number: '1.2-2' }}</td>
                                            <td>{{ consumer.usedPoints + consumer.currentPoints | number: '1.2-2' }}</td>
                                            <td>
                                                <span [ngClass]="(consumer.isVerify) ? 'stat stat-active' : 'stat stat-pending'">{{
                                                    (consumer.isVerify) ? 'ACTIVE' :
                                                    'PENDING' }}</span>
                                            </td>
                                            <td><a (click)="viewDetails(consumer)" style="cursor: pointer;">View</a></td>
                                        </tr>
                                    </ng-container>
                                    <ng-template #noRecordsMain>
                                        <tr>
                                            <td colspan="8" class="no-records-msg">
                                                <h6 class="ponds-contents-med-bold">No records found</h6>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    
                                </tbody>
                            </table>
                        </div>
                        <div class="list-footer">
                            <div class="pager">
                                <button class="pager-prev" (click)="nextPrev('prev')"><img src="../../../../assets/okito/images/icons/prev.svg"></button>
                                <button class="pager-next" (click)="nextPrev('next')"><img src="../../../../assets/okito/images/icons/next.svg"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #showDetailComponent>
            <div class="main-holder">
                <app-nav></app-nav>
                <div class="main">
                    <div class="main-content">
                        <div class="bread">
                            <ol>
                                <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                                <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                                <li><a (click)="this.returnToList()" style="cursor:pointer;">Customers</a></li>
                                <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                                <li><span>Customer Details</span></li>
                            </ol>
                        </div>
                        <div class="profile-holder">
                            <div class="profile">
                                <div class="pfp">
                                    <img src="../../../../assets/okito/images/avatar.png">
                                </div>
                                <div class="profile-info">
                                    <h4>{{ this.detailViewed['name'] }}</h4>
                                    <div class="tags">
                                        <span class="stat-icon"
                                            *ngIf="this.detailViewed['isVerify']; else showNotVerified"><img
                                                src="../../../../assets/okito/images/badge/verified.svg">ACTIVE</span>
                                        <ng-template #showNotVerified>
                                            <span class="stat-icon"><img
                                                    src="../../../../assets/okito/images/badge/not-verified.svg">NOT
                                                VERIFIED</span>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>


                            <div class="points" *ngIf="this.consumerTabs2['bookings'] || this.consumerTabs2['rewards'] ">
                                <div class="points-grp">
                                    <small>Points Balance</small>
                                    <h4>1200</h4>
                                </div>
                                <div class="points-grp">
                                    <small>Used Points</small>
                                    <h4>400</h4>
                                </div>
                            </div>
                            <div class="profile-actions" *ngIf="this.consumerTabs2['info']">
                                <button class="btn btn-sm btn-default" style="margin-right: 1em;">Edit Customer</button>
                                <button class="btn btn-sm btn-default" (click)="this.returnToList()">Return to List</button>
                            </div>
                        </div>
                        <div class="list-tabs">
                            <ul>
                                <li [ngClass]="{'active': this.consumerTabs2['info']}"><a (click)="detailConsumerTabActive('info')" style="cursor: pointer;">Personal Information</a></li>
                                <li [ngClass]="{'active': this.consumerTabs2['bookings']}"><a (click)="detailConsumerTabActive('bookings')" style="cursor: pointer;">Booking History</a></li>
                                <li [ngClass]="{'active': this.consumerTabs2['rewards']}"><a (click)="detailConsumerTabActive('rewards')"
                                        style="cursor: pointer;">Rewards</a></li>
                            </ul>
                        </div>
                        <div class="panel" *ngIf="this.consumerTabs2['info']">
                            <h3>Account Information</h3>
                            <div class="panel-info">
                                <div class="panel-grp">
                                    <div class="mb-2 row">
                                        <label class="col-sm-2 col-form-label">Membership ID</label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" disabled
                                                [(ngModel)]="this.detailViewed['membershipId']">
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-2 row">
                                        <label class="col-sm-2 col-form-label">Email</label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" disabled
                                                [(ngModel)]="this.detailViewed['email']">
                                        </div>
                                    </div>
                                </div>
                                <div class="panel-grp">
                                    <div class="mb-2 row">
                                        <label class="col-sm-2 col-form-label">Mobile</label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" disabled
                                                [(ngModel)]="this.detailViewed['mobile']">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel" *ngIf="this.consumerTabs2['bookings']">
                            <div class="list-holder">
                                <div class="list table-responsive">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Booking ID</th>
                                                <th>Rider</th>
                                                <th>Pick-up</th>
                                                <th>Drop-off</th>
                                                <th>Date</th>
                                                <th>Fee</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngIf="booking_currentSelectedPageList.length != 0; else noRecordsBooking">
                                                <tr *ngFor="let booking of this.booking_currentSelectedPageList">
                                                    <td>{{ booking.id }}</td>
                                                    <td *ngIf="booking.partner === null">---</td>
                                                    <td *ngIf="booking.partner !== null">{{ booking.user.name }}</td>
                                                    <td>{{ booking.startFormattedAddress }}</td>
                                                    <td>{{ booking.endFormattedAddress }}</td>
                                                    <td>{{ booking.dateCreated | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</td>
                                                    <td>{{ booking.totalPrice | currency:'PHP' }}</td>
                                                    <td>
                                                        <span class="stat stat-canceled" *ngIf="booking.status === 'pending'">Pending</span>
                                                        <span class="stat stat-ongoing" *ngIf="booking.status === 'accepted'">Accepted</span>
                                                        <span class="stat stat-ongoing" *ngIf="booking.status === 'ongoing_pickup'">Ongoing - Pickup</span>
                                                        <span class="stat stat-ongoing" *ngIf="booking.status === 'ongoing_destination'">Ongoing - Destination</span>
                                                        <span class="stat stat-completed" *ngIf="booking.status === 'completed'">Completed</span>
                                                        <span class="stat stat-canceled" *ngIf="booking.status === 'canceled'">Cancelled</span>
                                                    </td>
                                                    <td><a (click)="viewBookingDetails(booking, bookingDetail)" style="cursor: pointer;">View</a></td>
                                                </tr>
                                            </ng-container>
                                            <ng-template #noRecordsBooking>
                                                <tr>
                                                    <td colspan="8" class="no-records-msg">
                                                        <h6 class="ponds-contents-med-bold">No records found</h6>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="panel" *ngIf="this.consumerTabs2['rewards']">
                            <div class="cards">
                                <div class="row">
                                    <div class="col-md">
                                        <div class="card-item">
                                            <div class="card-content">
                                                <small>Okito Voucher</small>
                                                <h4>0</h4>
                                            </div>
                                            <img src="../../../../assets/okito/images/card-icons/points.svg">
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="card-item">
                                            <div class="card-content">
                                                <small>Digital Voucher</small>
                                                <h4>0</h4>
                                            </div>
                                            <img src="../../../../assets/okito/images/card-icons/digital.svg">
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="card-item">
                                            <div class="card-content">
                                                <small>Raffle Item</small>
                                                <h4>2</h4>
                                            </div>
                                            <img src="../../../../assets/okito/images/card-icons/raffle.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="list-holder">
                                <div class="list">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Title</th>
                                                <th>Type</th>
                                                <th>Draw Date</th>
                                                <th>Number of Winners</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1231232</td>
                                                <td>Rewards 1</td>
                                                <td>Raffle Item</td>
                                                <td>June 22, 2024</td>
                                                <td>5</td>
                                                <td>
                                                    <span class="stat stat-ongoing">Ongoing</span>
                                                </td>
                                                <td><a href="#" data-bs-toggle="offcanvas" data-bs-target="#reward-ongoing"
                                                        aria-controls="offcanvasRight">View</a></td>
                                            </tr>
                                            <tr>
                                                <td>1231232</td>
                                                <td>Rewards 2</td>
                                                <td>Raffle Item</td>
                                                <td>June 20, 2024</td>
                                                <td>5</td>
                                                <td>
                                                    <span class="stat stat-completed">Ended</span>
                                                </td>
                                                <td><a href="#" data-bs-toggle="offcanvas" data-bs-target="#reward-ended"
                                                        aria-controls="offcanvasRight">View</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="list-footer">
                                    <div class="pager">
                                        <button class="pager-prev"><img src="../../../../assets/okito/images/icons/prev.svg"></button>
                                        <button class="pager-next"><img src="../../../../assets/okito/images/icons/next.svg"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #bookingDetail let-offcanvas>
            <div class="offcanvas-header">
                <h5 id="offcanvasRightLabel">
                    <small>{{ this.bookingdetailViewed['dateCreated'] | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</small>
                    <small>Booking ID</small>
                    {{ this.bookingdetailViewed['id'] }}
                </h5>
                <span class="stat stat-canceled" *ngIf="this.bookingdetailViewed['status'] === 'pending'">Pending</span>
                <span class="stat stat-ongoing" *ngIf="this.bookingdetailViewed['status'] === 'accepted'">Accepted</span>
                <span class="stat stat-ongoing" *ngIf="this.bookingdetailViewed['status'] === 'ongoing_pickup'">Ongoing - Pickup</span>
                <span class="stat stat-ongoing" *ngIf="this.bookingdetailViewed['status'] === 'ongoing_destination'">Ongoing - Destination</span>
                <span class="stat stat-completed" *ngIf="this.bookingdetailViewed['status'] === 'completed'">Completed</span>
                <span class="stat stat-canceled" *ngIf="this.bookingdetailViewed['status'] === 'canceled'">Cancelled</span>
                <button type="button" class="btn-close text-reset" (click)="offcanvas.dismiss('Cross click')"></button>
            </div>
            <div class="offcanvas-body">
                <div class="booking-holder">
                    <google-map height="250px" width="370px" [center]="center" [zoom]="zoom" (mapClick)="moveMap($event)"
                        (mapMousemove)="move($event)">
                        <map-polyline [path]="polyLinePath" [options]="polyLineOptions" />
                    </google-map>
                    <div class="booking-rider-details">
                        <div class="pfp">
                            <img src="./../../../assets/okito/images/avatar.png">
                        </div>
                        <div class="rider-info" *ngIf="this.bookingdetailViewed['partner'] !== null">
                            {{ this.bookingdetailViewed['partner']['firstName'] }} {{ this.bookingdetailViewed['partner']['middleName'] }} {{ this.bookingdetailViewed['partner']['lastName'] }}<br>
                            <small>{{ this.bookingdetailViewed['vehicleCategory'] }} - {{ this.bookingdetailViewed['vehicleId'] }}</small>
                        </div>
                        <div class="rider-info" *ngIf="this.bookingdetailViewed['partner'] === null">
                            ---
                        </div>
                        <div class="rider-rating" *ngIf="this.bookingdetailViewed['partner'] !== null">
                            <img src="./../../../assets/okito/images/star.svg">
                            
                            <h4 *ngIf="this.bookingdetailViewed['rating'] === null">0.00</h4>
                            <h4 *ngIf="this.bookingdetailViewed['rating'] !== null">{{ this.bookingdetailViewed['rating']['rate'] | number: '1.2-2' }}</h4>
                        </div>
                    </div>
                    <div class="booking-address">
                        <div class="booking-address-details">
                            <img src="./../../../assets/okito/images/pickup.svg">
                            <div class="sender-details">
                                <label>Pick-up Location</label>
                                <h4>{{ this.bookingdetailViewed['startFormattedAddress'] }}</h4>
                                <div class="sender">
                                    <span>{{  this.bookingdetailViewed['senderName'] }}</span>
                                    <span>{{ this.bookingdetailViewed['senderMobile'] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="booking-address-details">
                            <img src="./../../../assets/okito/images/dropoff.svg">
                            <div class="sender-details">
                                <label>Drop-off Location</label>
                                <h4>{{ this.bookingdetailViewed['endFormattedAddress'] }}</h4>
                                <div class="sender">
                                    <span>{{ this.bookingdetailViewed['receipientName'] }}</span>
                                    <span>{{ this.bookingdetailViewed['receipientMobile'] }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="booking-note">
                        <label>Note to rider</label>
                        <p>{{ this.bookingdetailViewed['notes'] }}</p>
                    </div>
                    <div class="booking-distance">
                        <div class="fees-grp">
                            <div class="distance-label">
                                <img src="./../../../assets/okito/images/dropoff.svg">
                                <label>Distance</label>
                            </div>
                            <span>{{ this.bookingdetailViewed['totalDistance'] }} km</span>
                        </div>
                    </div>
                    <div class="booking-fees">
                        <div class="fees-grp">
                            <label>Delivery Fee</label>
                            <span>{{ this.bookingdetailViewed['farePrice'] | currency:'PHP' }}</span>
                        </div>
                        <div class="fees-grp">
                            <label>Discount</label>
                            <span class="discount">-{{ this.bookingdetailViewed['discountPrice'] | currency:'PHP' }}</span>
                        </div>
                        <div class="fees-grp">
                            <label>Insurance</label>
                            <span>{{ this.bookingdetailViewed['insurancePrice'] | currency:'PHP' }}</span>
                        </div>
                        <div class="total-fees">
                            <label>Total Delivery Fee</label>
                            <div class="total">
                            <img src="./../../../assets/okito/images/cash.svg">
                                <span>{{ this.bookingdetailViewed['totalPrice'] | currency:'PHP' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
</div>
