import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '@envs/environment';
import { CookieService } from 'ngx-cookie-service';

export const authenticatorGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const cooker = inject(CookieService);

  if(cooker.get(environment.tokenName) != '' && cooker.get(environment.otpValidVar) != undefined && cooker.get(environment.otpValidVar) == '1') {
    return true;
  }else{
    router.navigate(['/admin/login']);
    return false;
  }//end if
};

export const loggeduserGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const cooker = inject(CookieService);

  if(cooker.get(environment.tokenName) != ''  && cooker.get(environment.otpValidVar) != undefined && cooker.get(environment.otpValidVar) == '1'){
    router.navigate(['/']);
    return false;
  }else{
    return true;
  }//end if
};

