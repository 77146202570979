import { Component, inject, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { decrement, increment, reset } from '@actions/counter.actions';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { CookieService } from 'ngx-cookie-service';

import { loginForm } from '@interfaces/auth.interfaces';
import { ServerResponse } from '@interfaces/general.interface';
import { environment } from '@envs/environment';

import { ToastrService } from 'ngx-toastr';
import { Apollo } from 'apollo-angular';
import { AuthStore } from '@store/auth.store';
import { 
  ADMIN_LOGIN,
  VERIFY_ADMIN_OTP,
  REQUEST_FORGOT_PASSWORD_OTP,
  VERIFY_FORGOT_PASSWORD_OTP,
  RESET_ADMIN_PASSWORD,
  RESEND_ADMIN_OTP,
} from '@gql_queries/authentication/mutations';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports:      [ 
    CommonModule, 
    FormsModule,
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.css',
})

export class LoginFormComponent {
  @ViewChild('emailLogin') emailLogin!: ElementRef;
  @ViewChild('emailForgotPass') emailForgotPass!: ElementRef;
  @ViewChild('otpForgotPass') otpForgotPass!: ElementRef;
  @ViewChild('newpassForgotPass') newpassForgotPass!: ElementRef;
  
  @ViewChild('otpLogin') otpLogin!: ElementRef;
  
  
  token: string | null = null;
  //###################################
  //VARIABLES
  //###################################
  public formtoShow: string = 'login';
  public user_email: string = '';
  public otp_code: string = '';
  public isPasswordShown: any = {
    'txt-pass-1': 'password',
    'txt-pass-2': 'password',
    'txt-pass-3': 'password',
    'txt-pass-4': 'password',
    'txt-pass-5': 'password',
  };
  public userForm: loginForm = {
    email: '', //dev.rtabora@gmail.com
    password: '', //jaoski
  };

  public forgot_passemail: string = '';
  public forgot_pass_otp: string = '';  
  public forgot_pass_newpassword: string = '';

  //VARIABLES FOR STORE SAMPLE
  private store = inject(Store);
  count$?: Observable<number>

  //###################################
  //CONSTRUCTOR
  //###################################
  constructor(
    private AuthService: AuthService, 
    private toastr: ToastrService,
    private cooker: CookieService,
    //private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
    private authStore: AuthStore
  ) {
    this.count$ = this.store.select('counter');
    console.log(environment.apiUrl);
  }

  //###################################
  //FUNCTIONS / METHODS
  //###################################
  attempLogin(loginForm: loginForm) {
    this.apollo.mutate({
      mutation: ADMIN_LOGIN,
      variables: {
        "authenticationInput": loginForm
      }
    }).subscribe({
      next: (result) => {
        let res:any = result.data;
        if(res != null){
          const token: string = res.adminLogin.token;
          
          // Update store
          this.authStore.setToken(token);
          this.authStore.setOtpVerified(false);
          
          this.toastr.success(res.msg, 'LOGIN SUCCESSFUL - OTP CONFIRM', {
            closeButton: true,
            timeOut: 3000
          });
          this.formtoShow = "verify_otp";
          this.focusInitialInput();
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
  }//end fn
  
  togglePasswordShow(indx: any){
    this.isPasswordShown[indx] = this.isPasswordShown[indx] == 'password' ? this.isPasswordShown[indx] = 'text' : this.isPasswordShown[indx] = 'password';
  }//end fn

  verifyOTP(){
    this.apollo.mutate({
      mutation: VERIFY_ADMIN_OTP,
      variables: {
        "otp": this.otp_code.toString()
      }
    }).subscribe({
      next: (result) => {
        let res:any = result.data;

        if(res != null) {
          let otpNameValidotp = environment.otpValidVar;
          this.cooker.set(otpNameValidotp, '1');
          
          // Update store first
          this.authStore.setOtpVerified(true);
          
          this.toastr.success(res.msg, 'LOGIN SUCCESSFUL - OTP CONFIRM', {
            closeButton: true,
            timeOut: 3000
          });

          // Navigate after a brief delay to ensure state is updated
          setTimeout(() => {
            this.router.navigate(['/']);
          }, 100);
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
  }//end fn

  resetPassword(){
    this.apollo.mutate({
      mutation: REQUEST_FORGOT_PASSWORD_OTP,
      variables: {
        "email": this.forgot_passemail
      }
    }).subscribe({
      next: (result) => {
        let res:any = result.data;

        if(res != null){
          let msg: string = res.requestAdminForgotPassword.message;
          this.toastr.success(msg, 'SENT OTP VERIFICATION', {
            closeButton: true,
            timeOut: 3000
          });

          this.formtoShow = "forgot_password_otp_verify";
          this.focusInitialInput();
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
  }//end fn

  resentEmailVlink(){
    this.AuthService.tryResendEmailValidationLink(this.user_email).subscribe((res: ServerResponse) => {
      if(res.status){
        this.toastr.success(res.msg, 'VERIFY EMAIL', {
          closeButton: true,
          timeOut: 3000
        });
      }else{
        this.toastr.error(res.msg, 'ERROR RESEND VERIFICATION LINK', {
          closeButton: true,
          timeOut: 3000
        });
      }//end if
    });
  }//end fn

  resendAuthOTP(){
    this.apollo.mutate({
      mutation: RESEND_ADMIN_OTP
    }).subscribe({
      next: (result) => {
        let res:any = result.data;
        if(res != null){
          let msg: string = res.resendAdminOtp.message;
          this.toastr.success(msg, 'OTP RESENT SUCCESSFULL', {
            closeButton: true,
            timeOut: 3000
          });

          this.focusInitialInput();
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
  }//end fn

  verifyForgotPasswordOTP(){
    this.apollo.mutate({
      mutation: VERIFY_FORGOT_PASSWORD_OTP,
      variables: {
        "email": this.forgot_passemail,
        "otp": this.forgot_pass_otp
      }
    }).subscribe({
      next: (result) => {
        let res:any = result.data;
        if(res != null){
          let msg: string = res.verifyAdminForgotPassword.message;
          this.toastr.success(msg, 'OTP VERIFIED SUCCESSFULL', {
            closeButton: true,
            timeOut: 3000
          });
          this.forgot_pass_otp = '';
          this.formtoShow = 'forgot_password_update';
          this.focusInitialInput();
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
  }

  requestPasswordUpdate(){
    this.apollo.mutate({
      mutation: RESET_ADMIN_PASSWORD,
      variables: {
        "email": this.forgot_passemail,
        "newPassword": this.forgot_pass_newpassword
      }
    }).subscribe({
      next: (result) => {
        let res:any = result.data;
        if(res != null){
          let msg: string = res.resetAdminPassword.message;
          this.toastr.success(msg, 'PASSWORD RESET SUCCESSFULL', {
            closeButton: true,
            timeOut: 3000
          });
          this.formtoShow = 'login';
          this.forgot_pass_newpassword = '';
          this.forgot_passemail = '';
          this.focusInitialInput();
        }else{
          this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
            closeButton: true,
            timeOut: 3000
          });
        }//end if
      },
      error: (error) => {
        this.toastr.error(error.message, error.code, {
          closeButton: true,
          timeOut: 3000
        });
      },
    });
  }

  increment(){
    this.store.dispatch(increment());
  }

  decrement(){
    this.store.dispatch(decrement());
  }

  reset(){
    this.store.dispatch(reset());
  }

  focusInitialInput(): void {
    // Add a small delay to ensure ViewChild references are available
    setTimeout(() => {
      if (this.formtoShow === 'login' && this.emailLogin?.nativeElement) {
        this.emailLogin.nativeElement.focus();
      }

      if (this.formtoShow === 'forgot_password' && this.emailForgotPass?.nativeElement) {
        this.emailForgotPass.nativeElement.focus();
      }

      if (this.formtoShow === 'forgot_password_otp_verify' && this.otpForgotPass?.nativeElement) {
        this.otpForgotPass.nativeElement.focus();
      }

      if (this.formtoShow === 'forgot_password_update' && this.newpassForgotPass?.nativeElement) {
        this.newpassForgotPass.nativeElement.focus();
      }

      if (this.formtoShow === 'verify_otp' && this.otpLogin?.nativeElement) {
        this.otpLogin.nativeElement.focus();
      }
        
    }, 100);
  }

  ngOnInit(): void {
    // Add small delay to ensure DOM is ready
    setTimeout(() => {
      this.focusInitialInput();
    }, 100);
  }

  formtoShowUpdate(formtoShow: string): void {
    this.formtoShow = formtoShow;

    setTimeout(() => {
      this.focusInitialInput();
    }, 100);
  }
}
