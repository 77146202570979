import { Component, TemplateRef, inject } from '@angular/core';
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule, JsonPipe } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { Coupon, LocationData, City, Province } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { NgbModal,NgbAlertModule, NgbDatepickerModule, NgbDateStruct, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import { AppCurrencyFormatterDirective } from '@directive/app-currency-formatter.directive'
import { MomentModule } from 'ngx-moment';
import { Apollo, gql } from 'apollo-angular';
import { cloneDeep } from 'lodash';
import { AuthStore } from '@store/auth.store';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { 
  COUPON_OVERVIEW_QUERY,
  FETCH_COUPONS_QUERY
} from '@gql_queries/coupon/queries';
import {
  CREATE_COUPON_MUTATION,
  UPDATE_COUPON_MUTATION
} from '@gql_queries/coupon/mutations';

@Component({
    selector: 'app-dashboard-coupon-management',
    standalone: true,
    templateUrl: './dashboard-coupon-management.component.html',
    styleUrl: './dashboard-coupon-management.component.css',
    imports: [NgbDatepickerModule, NgbAlertModule, FooterComponent, HeaderComponent, NavComponent, CommonModule, 
    FormsModule, JsonPipe, NgbTimepickerModule, RouterModule, AppCurrencyFormatterDirective, MomentModule],
})
export class DashboardCouponManagementComponent {
    imageSrc?: string | ArrayBuffer | null; 
    today = new Date();
    
    model?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
    model2?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };

    time = { hour: 0, minute: 0 };
	meridian = false;

    constructor(
        private router: Router,
        private dasher: DashboardService,
        private localservice: LocalService,
        private apollo: Apollo,
        private authStore: AuthStore
    ) { }
    
    locationData: LocationData = {
        provinces: [],
        cities: [],
    };

    selectedProvinceCode: string = '';
    filteredCities: City[] = [];
    selectedProvince: Province = {
        id: 0,
        provCode: '',
        provDesc: '',
        psgcCode: '',
        regCode: '',
    };
    
    selectedCity: City = {
        citymunCode: '',
        citymunDesc: '',
        id: 0,
        provCode: '',
        psgcCode: '',
        regDesc: '',
    };

    private modalService = inject(NgbModal);
    public couponOverview: Record<string, any> = {
        totalActive: 0,
        totalRedeemed: 0,
        totalUnused: 0,
        totalExpired: 0
    };

    public couponTabs: Record<string, any> = {
        all: true,
        first_order: false,
        fixed_fee: false,
        percentage: false,
        location: false,
    };

    public couponList: Coupon[] = [];

    public searchKeyword: string = '';
    public currentSelectedPageList: Coupon[] = [];
    public maxPages: number = 0;
    public disableActionBtns: any = {
        next: false,
        prev: false,
    };
    public couponPages: number[] = [];
    public currentPage: number = 1;
    public pageSize: number = 15;
    
    public detailViewed: Record<string, any> = {};
    public showDetails: boolean = false;

    public couponFormNew: Record<string, any> = {
        type: '',
        service: '',
        value: 0,
        category: 'promos',
        title: '',
        subTitle: '',
        mechanics: '',
        photo: '',
        quantity: 0,
        minValue: 0,
        maxValue: 0,
        audience: 'consumer',
        province: '',
        city: '',
        dateStart: '',
        dateEnd: '',
        isDistributed: true,
    }
    
    couponStatusFilter: string = 'all';
    couponFilter: Record<string, any> = {
        audience: 'consumer', //default and to be removed
        service_type: 'all'
    };

    public newAdPublishToggle: boolean = false;
    couponPhoto: File | null = null;
    public enableEdit: boolean = false;
    couponUpdateForm: Record<string, any> = {
        service: "",
        //category: "",
        type: "",
        value: 0,
        title: "",
        subTitle: "",
        mechanics: "",
        photo: "",
        quantity: 0,
        minValue: 0,
        maxValue: 0,
        province: "",
        city: "",
        dateStart: "",
        dateEnd: ""
    };

    ngOnInit(): void {
        this.fetchCoupons('all');
        this.fetchOverview();
        this.populateLocationDropdowns();
    }

    fetchOverview(){
        this.apollo
        .watchQuery({
            fetchPolicy: 'network-only',
            query: COUPON_OVERVIEW_QUERY,
            variables: {}
        }).valueChanges.subscribe({
            next: (res: any) => {
                if(res.data['couponOverview']?.length != 0){
                    var completeInstance = res.data['couponOverview'];
                    Object.keys(this.couponOverview).forEach((x) => {
                        this.couponOverview[x] = completeInstance[x];
                    });
                }
            },
            error: (error) => {
                if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                    this.authStore.logout().subscribe(() => {
                        // Optional: Redirect to login page or show message
                        this.router.navigate(['/admin/login']);
                    });
                }
            }
        });
    }//end fn
    
    fetchCoupons(status:string, action: string = ''){
        this.couponStatusFilter = status;
        var fromPage = 1;

        if(action != ''){
            fromPage = this.currentPage;
            switch(action){
                case 'next':
                    this.currentPage++
                break;
      
                case 'prev':
                    if(this.currentPage != 1){
                        this.currentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if
        
        this.apollo
        .watchQuery({
            fetchPolicy: 'network-only',
            query: FETCH_COUPONS_QUERY,
            variables: {
                "page": 1,
                "type": status,
                "audience": this.couponFilter['audience'],
                "service": this.couponFilter['service_type'],
                "query":"",
            }
        }).valueChanges.subscribe({
            next: (res: any) => {
                if(action != ''){
                    if(res.data['coupons']?.length != 0){
                        this.couponList = [];
                        this.couponList = res.data['coupons'];
                        this.currentSelectedPageList = this.couponList;
                    }else{
                        this.currentPage = fromPage;
                    }
                }else{
                    this.couponList = [];
                    this.couponList = res.data['coupons'];
                    this.currentSelectedPageList = this.couponList;
                }
            },
            error: (error) => {
                if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                    this.authStore.logout().subscribe(() => {
                        // Optional: Redirect to login page or show message
                        this.router.navigate(['/admin/login']);
                    });
                }
            }
        });
    }//end fn

    nextPrev(action: string){
        var tabActive = '';
        Object.keys(this.couponTabs).forEach((x) => {
            if(this.couponTabs[x]){
                tabActive = x;
            }//end if
        });

        switch(action){
            case 'next':
                this.fetchCoupons(tabActive , 'next');
            break;
  
            case 'prev':
                this.fetchCoupons(tabActive , 'prev');
            break;
        }//end swithc
    }//end fn

    searchData(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchKeyword = inputElement.value;

        if (this.searchKeyword.trim() === '') {
            console.log('Input is empty');
            this.currentSelectedPageList = this.couponList;
        } else {
            this.currentSelectedPageList = this.couponList.filter(object => {
                // Helper function to recursively search through nested objects
                const searchInObject = (obj: any): boolean => {
                    for (const key in obj) {
                        const value = obj[key];
                        
                        // Skip if null or undefined
                        if (value == null) continue;
                        
                        // If value is an object or array, search recursively
                        if (typeof value === 'object') {
                            if (searchInObject(value)) return true;
                        }
                        // Check if the value includes the search term
                        else if (value.toString().toLowerCase().includes(this.searchKeyword.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                };
                
                return searchInObject(object);
            });
        }
    }//end nfn

    filterDiscountCoupons(tabindex: string){
        this.couponList = [];
        this.currentSelectedPageList = [];

        Object.keys(this.couponTabs).forEach((x) => {
            this.couponTabs[x] = false;
        });

        this.couponTabs[tabindex] = true;

        this.currentPage = 1;
        this.fetchCoupons(tabindex);
    }//end


    viewDetails(details: Coupon){
        this.showDetails = true;
        this.detailViewed = cloneDeep(details);

        console.log(this.detailViewed);

        var cityData = this.locationData.cities.filter((x) => x.citymunDesc == details.city);
        var provinceData = this.locationData.provinces.filter((x) => x.provDesc == details.province);
        
        if(cityData.length != 0){
            this.selectedCity = cityData[0];
        }//emd

        if(provinceData.length != 0){
            this.selectedProvince = provinceData[0];
        }//emd

        let dateStart = new Date(details.dateStart);
        let dateEnd = new Date(details.dateEnd);
        
        this.model = { year: dateStart.getFullYear(), month: dateStart.getMonth() + 1, day: dateStart.getDate() };
        this.model2 = { year: dateEnd.getFullYear(), month: dateEnd.getMonth() + 1, day: dateEnd.getDate() };
    }//end fn

    onFileSelected(event: any): void {
        const file: File = event.target.files[0];
        if (file) {
          const reader = new FileReader(); 
          reader.onload = (e: any) => {
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(file);
          this.couponPhoto = file;
        }//end
    }//end fn
    
    onSelectProvince(event: any) {
        const selectedIndex = event.target.selectedIndex;
        const provcode = event.target.options[selectedIndex].getAttribute('data-provcode');
        
        this.filteredCities = this.locationData.cities.filter((x) => x.provCode == provcode);
        this.couponFormNew['city'] = this.filteredCities[0].citymunDesc;
        console.log(this.locationData.cities[0]);
    }

    createCouponData(confirmModal: TemplateRef <any>){
        var errors: number = 0;
        let startdate = this.model?.year + '-' + ((this.model!.month.toString().length > 1) ? this.model!.month: '0'+this.model!.month) + '-' + ((this.model!.day.toString().length > 1) ? this.model!.day: '0'+this.model!.day);
        let enddate = this.model2?.year + '-' + ((this.model2!.month.toString().length > 1) ? this.model2!.month: '0'+this.model2!.month) + '-' + ((this.model2!.day.toString().length > 1) ? this.model2!.day: '0'+this.model2!.day);
        
        this.couponFormNew['dateStart'] = startdate + ' 00:00:01';
        this.couponFormNew['dateEnd'] = enddate + ' 23:59:59';

        Object.keys(this.couponFormNew).forEach((x) => {
            if(x == 'value' || x == 'minValue' || x == 'maxValue' || x == 'quantity'){
                if( this.couponFormNew[x] != ''){
                    let numberValue = parseFloat(String(this.couponFormNew[x]).replace(/₱\s?|(,*)/g, ""));

                    if (isNaN(numberValue)) {
                        this.couponFormNew[x] = 0.00;
                    } else {
                        this.couponFormNew[x] = numberValue;
                    }//end if
                }//end if
            }//end if

            if(x != 'photo'){
                if(this.couponFormNew[x] == ''){
                    //console.log(x);
                    errors ++;
                }//end if
            }//end if
        });

        console.log(this.couponFormNew);    

        if(errors != 0){
            alert('Please enter all required fields');
        }else{
            this.modalService.open(confirmModal, { size: 'md',  backdrop: 'static', keyboard: false });
        }//end if
    }//end fn

    proceedCreateCoupon(successModal: TemplateRef<any>){
        const formData:any = new FormData();      
        if (this.couponPhoto) {
          formData.append('file', this.couponPhoto, this.couponPhoto.name);
        }
    
        this.dasher.uploadPhoto(formData, 'coupon_photo').subscribe((res: any) => {
            this.couponFormNew['photo'] = res.photo;

            this.apollo
            .mutate({
                mutation: CREATE_COUPON_MUTATION,
                variables:{
                   "createCouponInput": this.couponFormNew
                }
            }).subscribe({
                next: (res: any) => {
                    if(res.data['createCoupon'].length != 0){
                        let completeInstance = res.data['createCoupon'];
                        this.fetchOverview();
                        this.modalService.dismissAll();
                        Object.keys(this.couponFormNew).forEach((x) => {
                            if(x == 'audience' || x == 'value' || x == 'minValue' || x == 'maxValue' || x == 'quantity' || x == 'isDistributed'){
                                if(x == 'audience'){
                                    this.couponFormNew[x] = 'consumer';
                                }//end if

                                if(x == 'value' || x == 'minValue' || x == 'maxValue' || x == 'quantity'){
                                    this.couponFormNew[x] = 0.00;
                                }

                                if(x == 'isDistributed'){
                                    this.couponFormNew[x] = true;
                                }
                            }else{
                                this.couponFormNew[x] = '';
                            }
                        });
                        this.imageSrc = null;
                        this.couponPhoto = null;
                        this.model = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
                        this.model2 = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
                        
                        this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false });

                        if((completeInstance.type == this.couponStatusFilter || this.couponStatusFilter == 'all') && (completeInstance.service == this.couponFilter['service_type'] || this.couponFilter['service_type'] == 'all')){
                            this.couponList = [completeInstance, ...this.couponList];
                            this.currentSelectedPageList = this.couponList;
                        }

                    }//end if
                },
                error: (error) => {
                    if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                        this.authStore.logout().subscribe(() => {
                            // Optional: Redirect to login page or show message
                            this.router.navigate(['/admin/login']);
                        });
                    }
                }
            });
        });
      }//end fn

    showCreateCouponForm(modal: TemplateRef<any>){
        this.modalService.open(modal,{ size: 'lg',  backdrop: 'static', keyboard: false });
    }//end fn

    closeAllModals(){
        this.modalService.dismissAll();
    }//end fn

    returnToList(){
        this.showDetails = false;
        this.detailViewed = {};
  
        this.currentPage = 1;
        this.fetchCoupons('all');
    }//end fn

    enableEditForm(){
        this.enableEdit = true;
      }//end fn
    
    disableEditForm(){
        this.enableEdit = false;
    }//end fn
    
    updateThisDetails(){
        const formData:any = new FormData();      
        var errors: number = 0;
        let startdate = this.model?.year + '-' + ((this.model!.month.toString().length > 1) ? this.model!.month: '0'+this.model!.month) + '-' + ((this.model!.day.toString().length > 1) ? this.model!.day: '0'+this.model!.day);
        let enddate = this.model2?.year + '-' + ((this.model2!.month.toString().length > 1) ? this.model2!.month: '0'+this.model2!.month) + '-' + ((this.model2!.day.toString().length > 1) ? this.model2!.day: '0'+this.model2!.day);
        
        this.detailViewed['dateStart'] = startdate + ' 00:00:01';
        this.detailViewed['dateEnd'] = enddate + ' 23:59:59';

        Object.keys(this.detailViewed).forEach((x) => {
            if(x == 'value' || x == 'minValue' || x == 'maxValue' || x == 'quantity'){
                if(this.detailViewed[x] != ''){
                    let numberValue = parseFloat(String(this.detailViewed[x]).replace(/₱\s?|(,*)/g, ""));

                    if (isNaN(numberValue)) {
                        this.detailViewed[x] = 0.00;
                    } else {
                        this.detailViewed[x] = numberValue;
                    }//end if
                }//end if
            }//end if

            if(x != 'photo'){
                if(this.detailViewed[x] == ''){
                    //console.log(x);
                    errors ++;
                }//end if
            }//end if
        });


        Object.keys(this.couponUpdateForm).forEach((x) => {
            this.couponUpdateForm[x] = this.detailViewed[x];
        });

        console.log('Coupon Update Form');
        console.log(this.couponUpdateForm);
        
        if (this.couponPhoto) {
            formData.append('file', this.couponPhoto, this.couponPhoto.name);
            this.dasher.uploadPhoto(formData, 'coupon_photo').subscribe((res: any) => {
                this.couponUpdateForm['photo'] = res.photo;
                
                this.apollo
                .mutate({
                    mutation: UPDATE_COUPON_MUTATION,
                    variables:{
                        "couponId": this.detailViewed['couponId'],
                        "updateCouponInput": this.couponUpdateForm
                    }
                }).subscribe({
                    next: (res: any) => {
                        if(res.data['updateCoupon'].length != 0){
                            let completeInstance = res.data['updateCoupon'];
                            
                            console.log(completeInstance);

                            this.modalService.dismissAll();
                            Object.keys(this.couponFormNew).forEach((x) => {
                                if(x == 'audience' || x == 'value' || x == 'minValue' || x == 'maxValue' || x == 'quantity' || x == 'isDistributed'){
                                    if(x == 'audience'){
                                        this.couponFormNew[x] = 'consumer';
                                    }//end if

                                    if(x == 'value' || x == 'minValue' || x == 'maxValue' || x == 'quantity'){
                                        this.couponFormNew[x] = 0.00;
                                    }

                                    if(x == 'isDistributed'){
                                        this.couponFormNew[x] = true;
                                    }
                                }else{
                                    this.couponFormNew[x] = '';
                                }
                            });
                            
                            this.imageSrc = null;
                            this.couponPhoto = null;
                            this.model = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
                            this.model2 = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
                            this.disableEditForm();
                            this.couponPhoto = null;
              
                        }//end if
                    },
                    error: (error) => {
                        if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                            this.authStore.logout().subscribe(() => {
                                // Optional: Redirect to login page or show message
                                this.router.navigate(['/admin/login']);
                            });
                        }
                    }
                });
            });
            
        }else{
            this.apollo
            .mutate({
                mutation: UPDATE_COUPON_MUTATION,
                variables:{
                    "couponId": this.detailViewed['couponId'],
                    "updateCouponInput": this.couponUpdateForm
                }
            }).subscribe({
                next: (res: any) => {
                    if(res.data['updateCoupon'].length != 0){
                        let completeInstance = res.data['updateCoupon'];
                        
                        console.log(completeInstance);

                        this.modalService.dismissAll();
                        Object.keys(this.couponFormNew).forEach((x) => {
                            if(x == 'audience' || x == 'value' || x == 'minValue' || x == 'maxValue' || x == 'quantity' || x == 'isDistributed'){
                                if(x == 'audience'){
                                    this.couponFormNew[x] = 'consumer';
                                }//end if

                                if(x == 'value' || x == 'minValue' || x == 'maxValue' || x == 'quantity'){
                                    this.couponFormNew[x] = 0.00;
                                }

                                if(x == 'isDistributed'){
                                    this.couponFormNew[x] = true;
                                }
                            }else{
                                this.couponFormNew[x] = '';
                            }
                        });
                        
                        this.imageSrc = null;
                        this.couponPhoto = null;
                        this.model = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
                        this.model2 = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
                        this.disableEditForm();
                        this.couponPhoto = null;
              
                    }//end if
                },
                error: (error) => {
                    if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                        this.authStore.logout().subscribe(() => {
                            // Optional: Redirect to login page or show message
                            this.router.navigate(['/admin/login']);
                        });
                    }
                }
            });
        }//end if
    }//end fn

    populateLocationDropdowns(){
        this.dasher.tryFetchLocations().subscribe((res: any) => {
            if(res.length != 0){
                res.forEach((location_instance : any) => {
                    this.locationData.provinces.push(location_instance['province']);
                    location_instance['cities'].forEach((city_instance: any) => {
                        this.locationData.cities.push(city_instance);
                    });

                    this.filteredCities = this.locationData.cities;
                });
            }//end if
        });
    }//end fn
    
    viewLargerModal(modalLarger: TemplateRef<any>){
        this.modalService.open(modalLarger, {fullscreen:true, size: 'xl',  backdrop: 'static'});
    }//endd fn
}