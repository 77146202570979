import { gql } from 'apollo-angular';

export const BOOKING_EXPRESS_TRANSACTIONS = gql`
  query bookingExpressTxns($sortBy:BookingExpressSortingInput) {
    bookingExpressTxns(sortBy:$sortBy) {
      bookingExpressId
      status
      notes
      dateCreated
      startFormattedAddress
      endFormattedAddress
      partnerRating {
        rate
      }
      user {
        dateCreated
        email
        isVerify
        membershipId
        mobile
        name
        currentPoints
        usedPoints
        userId
      }
      partner {
        partnerId
        tier
        membershipId
        firstName
        middleName
        lastName
        mobile
        email
        distributionArea
        vehicleType
        licensePlate
        isOtpVerify
        isApprove
        dateCreated
        currentPoints
        usedPoints
      }
      totalPrice
      orderRoutes
      totalDistance
      discountPrice
      farePrice
    }
  }
`;

export const BOOKING_RIDE_MOTOTAXI_TRANSACTIONS = gql`
  query bookingRideTxns($service:BookingRideServiceFilterInput!, $sortBy:BookingRideSortingInput) {
    bookingRideTxns(service:$service, sortBy:$sortBy) {
      bookingRideId
      status
      dateCreated
      startFormattedAddress
      endFormattedAddress
      rating {
        rate
      }
      user {
        dateCreated
        email
        isVerify
        membershipId
        mobile
        name
        currentPoints
        usedPoints
        userId
      }
      partner {
        partnerId
        tier
        membershipId
        firstName
        middleName
        lastName
        mobile
        email
        distributionArea
        vehicleType
        licensePlate
        isOtpVerify
        isApprove
        dateCreated
        currentPoints
        usedPoints
      }
      totalPrice
      routes
      kmDistance 
      discountPrice 
      farePrice
    }
  }
`;