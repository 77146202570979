import { Component } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { NavComponent } from "../navigation/nav.component";
import { CommonModule } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { Admin } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { FooterComponent } from '../footer/footer.component';
import {RouterModule} from '@angular/router';
import { MomentModule } from 'ngx-moment';

@Component({
    selector: 'app-dashboard-admins',
    standalone: true,
    templateUrl: './dashboard-admins.component.html',
    styleUrl: './dashboard-admins.component.css',
    imports: [FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule, MomentModule],
})

export class DashboardAdminsComponent {
    constructor(
        private dasher: DashboardService,
        private localservice: LocalService,
    ) { }

    public adminList: Admin[] = [];

    public searchKeyword: string = '';
    public currentSelectedPageList: Admin[] = [];
    public maxPages: number = 0;
    public disableActionBtns: any = {
        next: false,
        prev: false,
    };
    public riderPages: number[] = [];
    public currentPage: number = 1;
    public pageSize: number = 15;

    public detailViewed: Record<string, any> = {};
    public showDetails: boolean = false;


    ngOnInit(): void {
        this.dasher.tryFetchAdmins().subscribe((res: ServerResponse) => {
            if(res.status){
            res.body.forEach((consumer_instance : any) => {
                let i = consumer_instance;
                this.adminList.push(i);
            });

            this.maxPages = this.localservice.getTotalPages(this.adminList, this.pageSize);
            this.riderPages = Array.from({ length: this.maxPages}, (_, i) => i + 1);
            this.currentSelectedPageList = this.localservice.paginateData(this.adminList, this.currentPage, this.pageSize)
            
            if(this.maxPages == 1){
                this.disableActionBtns.next = true;
                this.disableActionBtns.prev = true;
            }else{
                this.disableActionBtns.next = false;
                this.disableActionBtns.prev = true;
            }//end if
            }//end fn
        });
    }

    setPageSelected(setPage: number){
        if(setPage > this.maxPages){
            this.currentPage = this.maxPages;
        }else{
            this.currentPage = setPage;
        }//end if
  
        if(this.currentPage == 1){
            this.disableActionBtns.prev = true;
        }else{
            this.disableActionBtns.prev = false;
        }//end if
  
        if(this.currentPage == this.maxPages){
            this.disableActionBtns.next = true;
        }else{
            this.disableActionBtns.next = false;
        }//end if
        
        this.currentSelectedPageList = this.localservice.paginateData(this.adminList, this.currentPage, this.pageSize)
    }//end fn
  
    nextPrev(action: string){
        switch(action){
            case 'next':
                this.currentPage++
            break;
  
            case 'prev':
                this.currentPage--
            break;
        }//end swithc
  
        if(this.currentPage == 1){
            this.disableActionBtns.prev = true;
        }else{
            this.disableActionBtns.prev = false;
        }//end if
  
        if(this.currentPage == this.maxPages){
            this.disableActionBtns.next = true;
        }else{
            this.disableActionBtns.next = false;
        }//end if
  
        this.currentSelectedPageList = this.localservice.paginateData(this.adminList, this.currentPage, this.pageSize)
    }//end fn
  
  
    searchData(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchKeyword = inputElement.value;
  
        if (this.searchKeyword.trim() === '') {
          console.log('Input is empty');
          this.currentSelectedPageList = this.localservice.paginateData(this.adminList, this.currentPage, this.pageSize)
        }else{
            this.currentSelectedPageList =  this.adminList.filter(object =>
                Object.values(object).some(value => 
                    (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
                )
            );
        }//end if
    }

    viewDetails(details: Admin){
        this.showDetails = true;
        this.detailViewed = details;
        console.log(details);
    }//end fn

    returnToList(){
        this.showDetails = false;
        this.detailViewed = {};

        this.currentPage = 1;
        this.currentSelectedPageList = this.adminList;
    }//end fn
}
