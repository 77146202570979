import { gql } from 'apollo-angular';

export const COUPON_OVERVIEW_QUERY = gql`
  query couponOverview {
    couponOverview {
      totalActive
      totalRedeemed
      totalUnused
      totalExpired
    }
  }
`;

export const FETCH_COUPONS_QUERY = gql`
  query coupons($page: Int, $type: CouponTypeFilter!, $audience: String, $query: String, $service: BookingServiceFilter!) {
    coupons(page: $page, type: $type, audience: $audience, query: $query, service: $service) {
      couponId
      service
      couponCode
      type
      category
      value
      title
      subTitle
      mechanics
      photo
      quantity
      minValue
      maxValue
      audience
      province
      city
      status
      dateStart
      dateEnd
      isDistributed
      dateUpdated
      dateCreated
      dateUsed
    }
  }
`; 