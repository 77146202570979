import { gql } from 'apollo-angular';

export const CREATE_COUPON_MUTATION = gql`
  mutation createCoupon($createCouponInput: CreateCouponInput!) {
    createCoupon(createCouponInput: $createCouponInput) {
      couponId
      service
      type
      value
      title
      subTitle
      photo
      quantity
      minValue
      maxValue
      audience
      province
      city
      status
      dateStart
      dateEnd
      isDistributed
      dateUpdated
      dateCreated
      dateUsed
    }
  }
`;

export const UPDATE_COUPON_MUTATION = gql`
  mutation updateCoupon($couponId: HashedId!, $updateCouponInput: UpdateCouponInput!) {
    updateCoupon(couponId: $couponId, updateCouponInput: $updateCouponInput) {
      couponId
      service
      type
      value
      title
      subTitle
      photo
      quantity
      minValue
      maxValue
      audience
      province
      city
      status
      dateStart
      dateEnd
      isDistributed
      dateUpdated
      dateCreated
      dateUsed
    }
  }
`; 