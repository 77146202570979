import { Component, TemplateRef, inject } from '@angular/core';
//SUB COMPONENTS
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { Booking, Consumer } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import {RouterModule} from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { Apollo, gql } from 'apollo-angular';
import { AuthStore } from '@store/auth.store';

//FOR THE MAP
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMap} from '@angular/google-maps';
import { ExcelService } from '@services/excel.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-dashboard-users',
  standalone: true,
  imports: [FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule, MomentModule, GoogleMapsModule, GoogleMap],
  templateUrl: './dashboard-users.component.html',
  styleUrl: './dashboard-users.component.css'
})

export class DashboardUsersComponent {
  private offcanvasService = inject(NgbOffcanvas);
  
  constructor(
      private router: Router,
      private dasher: DashboardService,
      private localservice: LocalService,
      private excelService: ExcelService,
      private apollo: Apollo,
      private authStore: AuthStore
  ) { }

  public consumerList: Consumer[] = [];
  public bookingList: Booking[] = [];

  public searchKeyword: string = '';
  public currentSelectedPageList: Consumer[] = [];
  public booking_currentSelectedPageList: Booking[] = [];
  public maxPages: number = 0;
  public disableActionBtns: any = {
      next: false,
      prev: false,
  };
  public disableActionBtnsBooking: any = {
        next: false,
        prev: false,
    };
  public riderPages: number[] = [];
  public currentPage: number = 1;
  public pageSize: number = 15;

  public bookingPages: number[] = [];
  public bookingcurrentPage: number = 1;
  public bookingpageSize: number = 15;
  
  public detailViewed: Record<string, any> = {};
  public bookingdetailViewed: Record<string, any> = {};

  public showDetails: boolean = false;
  public consumerTabs: Record<string, any> = {
    all: true,
    w_booking: false,
    in_transit: false,
    completed: false,
    n_booking: false,
  }

  public consumerTabs2: Record<string, any> = {
    info: true,
    bookings: false,
    rewards: false,
  }

  public userOverview: Record<string, any> = {
    totalRegistered: 0,
    totalVerified: 0,
    totalUnVerified: 0,
    rideHailingFourWheelsCount: 0,
    rideHailingTwoWheelsCount: 0,
    expressFourWheelsCount: 0,
    expressTwoWheelsCount: 0,
  };

  //FOR THE MAP (BOOKING DETAILS)
  polyLinePath: google.maps.LatLng[] = [];
  display: any;
  center: google.maps.LatLngLiteral = {
      lat: 22.2736308,
      lng: 70.7512555
  };
  zoom = 15;
  polyLineOptions: google.maps.PolylineOptions = {
    strokeColor: '#D81960',
    strokeOpacity: 1.0,
    strokeWeight: 5,
    clickable: false,
    editable: false,
    visible: true,
    geodesic: true, // Smoothen the edges
  };
    
  public sortOrder: Record<string, any> = {
    asc: true,
    desc: false,
  }

  userSortBy: string = 'dateCreated'; // default sort field
  userSortDirection: string = 'desc';  // default sort direction
  selectedServiceType: string = 'express'; // Default value
  
  bookingSortBy: string = 'dateCreated';
  bookingSortDirection: string = 'desc';

  public searchKeywordBooking: string = '';

    ngOnInit(): void {
        this.fetchUsers();
    }

    fetchOverview(){
        this.apollo
        .watchQuery({
            fetchPolicy: 'network-only',
            query: gql`
            query usersOverview {
                usersOverview {
                    totalRegistered
                    totalVerified
                    totalUnVerified
                    rideHailingTwoWheelsCount
                    rideHailingFourWheelsCount
                    expressTwoWheelsCount
                    expressFourWheelsCount
                }
            }           
            `,
        }).valueChanges.subscribe({
            next: (res: any) => {
                if(res.data['usersOverview']?.length != 0){
                    var completeInstance = res.data['usersOverview'];
                    Object.keys(this.userOverview).forEach((x) => {
                        this.userOverview[x] = completeInstance[x];
                    });
                }
            },
            error: (error) => {
                if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                    this.authStore.logout().subscribe();
                }
            }
        });
    }//end fn
    
    fetchUserSelectedBookings(customerID:string, action: string = ''){
        var fromPage = 1;

        if(action != ''){
            fromPage = this.currentPage;
            switch(action){
                case 'next':
                    this.currentPage++
                break;
      
                case 'prev':
                    if(this.currentPage != 1){
                        this.currentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if
        
        this.bookingList = [];
        this.booking_currentSelectedPageList = [];

        this.dasher.tryFetchCustomerBookings(customerID, this.bookingcurrentPage).subscribe((res: any) => {
            if(res.length != 0){
                res.forEach((booking_instance : any) => {
                    let i = booking_instance;
                    this.bookingList.push(i);
                });

                this.booking_currentSelectedPageList = this.bookingList;
            }else{
                this.bookingcurrentPage = fromPage;
            }//end if//end fn

        });
    }//end fn

    fetchTransactions(servicetype:string = 'express', action: string = ''){
        var fromPage = 1;
    
        if(action != ''){
            fromPage = this.bookingcurrentPage;
            switch(action){
                case 'next':
                    this.bookingcurrentPage++
                break;
      
                case 'prev':
                    if(this.bookingcurrentPage != 1){
                        this.bookingcurrentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if
    
        let requestQry = null;
        if(servicetype == 'express'){
             requestQry = this.apollo
            .watchQuery({
                fetchPolicy: 'network-only',
                query: gql`
                query bookingExpressTxns($userId:UserHashedId, $sortBy:BookingExpressSortingInput) {
                    bookingExpressTxns(userId:$userId, sortBy:$sortBy) {
                        bookingExpressId
                        status
                        notes
                        dateCreated
                        startFormattedAddress
                        endFormattedAddress
                        partnerRating {
                            rate
                        }
                        user {
                            dateCreated
                            email
                            isVerify
                            membershipId
                            mobile
                            name
                            currentPoints
                            usedPoints
                            userId
                        }
                        partner {
                            partnerId
                            tier
                            membershipId
                            firstName
                            middleName
                            lastName
                            mobile
                            email
                            distributionArea
                            vehicleType
                            licensePlate
                            isOtpVerify
                            isApprove
                            dateCreated
                            currentPoints
                            usedPoints
                        }
                        totalPrice
                        orderRoutes
                        totalDistance
                        discountPrice
                        farePrice
                    }
                }
                `,
                variables: {
                    userId: this.detailViewed['userId'],
                    page: this.bookingcurrentPage,
                    sortBy: {
                        "sort": this.bookingSortBy,
                        "direction": this.bookingSortDirection
                    }
                }, // Variables should be here
            });
        }else{
            requestQry = this.apollo
            .watchQuery({
                fetchPolicy: 'network-only',
                query: gql`
                query bookingRideTxns($userId:UserHashedId, $service:BookingRideServiceFilterInput!, $sortBy:BookingRideSortingInput) {
                    bookingRideTxns(userId:$userId, service:$service, sortBy:$sortBy) {
                        bookingRideId
                        status
                        dateCreated
                        startFormattedAddress
                        endFormattedAddress
                        rating {
                            rate
                        }
                        user {
                            dateCreated
                            email
                            isVerify
                            membershipId
                            mobile
                            name
                            currentPoints
                            usedPoints
                            userId
                        }
                        partner {
                            partnerId
                            tier
                            membershipId
                            firstName
                            middleName
                            lastName
                            mobile
                            email
                            distributionArea
                            vehicleType
                            licensePlate
                            isOtpVerify
                            isApprove
                            dateCreated
                            currentPoints
                            usedPoints
                        }
                        totalPrice
                        routes
                        kmDistance
                        discountPrice 
                        farePrice
                    }
                }
                `,
                variables: {
                    page: this.bookingcurrentPage,
                    service: servicetype,
                    userId: this.detailViewed['userId'],
                    sortBy: {
                        "sort": this.bookingSortBy,
                        "direction": this.bookingSortDirection
                    }
                }, // Variables should be here
            });
        }
        
        requestQry.valueChanges
        .subscribe({
          next: (res: any) => {
            this.bookingList = [];
            this.booking_currentSelectedPageList = [];
            if(servicetype == 'express'){
                if (res && res.data && res.data.bookingExpressTxns && res.data.bookingExpressTxns.length !== 0) {
                    this.bookingList = res.data.bookingExpressTxns;
                    this.booking_currentSelectedPageList = this.bookingList;
        
                    this.bookingList.map((x) => {
                        // Create a new object for each entry
                        const fieldListings = {
                            bookingID: x.bookingExpressId,
                            dateCreated: x.dateCreated,
                            notes: x.notes,
                            consumer: x.user != null ? x.user.name : '---',
                            partner: x.partner != null ? `${x.partner.firstName} ${x.partner.lastName}` : '---',
                            status: x.status,
                            totalPrice: x.totalPrice
                        };
                    });
                    
                    //fieldListings['bookingID'] = this.transactionList.
                }else{
                    this.currentPage = fromPage;
                } // end if
            }else{
                if (res && res.data && res.data.bookingRideTxns && res.data.bookingRideTxns.length !== 0) {
                    this.bookingList = res.data.bookingRideTxns;
                    this.booking_currentSelectedPageList = this.bookingList;
        
                    this.bookingList.map((x) => {
                        // Create a new object for each entry
                        const fieldListings = {
                            bookingID: x.bookingExpressId,
                            dateCreated: x.dateCreated,
                            notes: x.notes,
                            consumer: x.user != null ? x.user.name : '---',
                            partner: x.partner != null ? `${x.partner.firstName} ${x.partner.lastName}` : '---',
                            status: x.status,
                            totalPrice: x.totalPrice
                        };
                    });
                    
                    //fieldListings['bookingID'] = this.transactionList.
                }else{
                    this.currentPage = fromPage;
                } // end if
            }
          },
          error: (error) => {
            if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                this.authStore.logout().subscribe(() => {
                    // Optional: Redirect to login page or show message
                    this.router.navigate(['/admin/login']);
                });
            }
          }
        });
    }//end fn

    nextPrev(action: string){
        /* var tabActive = '';
        Object.keys(this.couponTabs).forEach((x) => {
            if(this.couponTabs[x]){
                tabActive = x;
            }//end if
        }); */

        switch(action){
            case 'next':
                this.fetchUsers('next');
            break;
  
            case 'prev':
                this.fetchUsers('prev');
            break;
        }//end swithc
    }//end fn


    searchData(event: Event) {
        /* const inputElement = event.target as HTMLInputElement;
        this.searchKeyword = inputElement.value;
  
        if (this.searchKeyword.trim() === '') {
            console.log('Input is empty');
            this.currentSelectedPageList = this.consumerList
        }else{
            this.currentSelectedPageList =  this.consumerList.filter(object =>
                Object.values(object).some(value => 
                    (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
                )
            );
        }//end if */

        const inputElement = event.target as HTMLInputElement;
        this.searchKeyword = inputElement.value;

        if (this.searchKeyword.trim() === '') {
            console.log('Input is empty');
            this.currentSelectedPageList = this.consumerList;
        } else {
            this.currentSelectedPageList = this.consumerList.filter(object => {
                // Helper function to recursively search through nested objects
                const searchInObject = (obj: any): boolean => {
                    for (const key in obj) {
                        const value = obj[key];
                        
                        // Skip if null or undefined
                        if (value == null) continue;
                        
                        // If value is an object or array, search recursively
                        if (typeof value === 'object') {
                            if (searchInObject(value)) return true;
                        }
                        // Check if the value includes the search term
                        else if (value.toString().toLowerCase().includes(this.searchKeyword.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                };
                
                return searchInObject(object);
            });
        }
    }//end nfn

    viewDetails(details: Consumer){
        this.showDetails = true;
        this.detailViewed = details;
        console.log(details);

        //this.fetchUserSelectedBookings(details.userId);
        this.fetchTransactions('express');
    }//end fn

    viewBookingDetails(details: Booking, offcanvas: TemplateRef<any>, selectedServiceType: string){
        this.showDetails = true;
        this.bookingdetailViewed = details;
        console.log(this.bookingdetailViewed);
        if(selectedServiceType == 'express'){
            if(details.orderRoutes != ''){
                    this.polyLinePath = google.maps.geometry.encoding.decodePath(details.orderRoutes);
                    // Calculate the bounds of the polyline
                    const bounds = new google.maps.LatLngBounds();
                    this.polyLinePath.forEach((coordinate: google.maps.LatLng) => {
                    bounds.extend(coordinate);
                });
            
                // Center the map to the center of the polyline bounds
                this.center = bounds.getCenter().toJSON();
            }
        }else{
            if(details.routes != ''){
                    this.polyLinePath = google.maps.geometry.encoding.decodePath(details.routes);
                    // Calculate the bounds of the polyline
                    const bounds = new google.maps.LatLngBounds();
                    this.polyLinePath.forEach((coordinate: google.maps.LatLng) => {
                    bounds.extend(coordinate);
                });
            
                // Center the map to the center of the polyline bounds
                this.center = bounds.getCenter().toJSON();
            }
        }
        this.offcanvasService.open(offcanvas, { position: 'end' });
    }//end fn

    returnToList(){
        this.showDetails = false;
        this.detailViewed = {};

        this.currentPage = 1;
        this.currentSelectedPageList = this.consumerList;
    }//end fn
    
    filterConsumers(tabindex: string){
        this.setSelectedPageDataBasedOnStatus(tabindex);
    }//end

    setSelectedPageDataBasedOnStatus(tabindex: string){
        Object.keys(this.consumerTabs).forEach((x) => {
            this.consumerTabs[x] = false;
        });

        this.consumerTabs[tabindex] = true;
        /* switch (tabindex) {
            case 'all':
                this.currentPage = 1;
                this.filteredRiderList = this.riderList;
            break;
        
            case 'for_verification':
                this.currentPage = 1;
                this.filteredRiderList = this.riderList.filter((x) => (x.isApprove == false))
            break;

            case 'verified':
                this.currentPage = 1;
                this.filteredRiderList = this.riderList.filter((x) => (x.isApprove == true))
            break;
        }//end if */

        /* this.maxPages = this.localservice.getTotalPages(this.filteredRiderList, this.pageSize);
        this.riderPages = Array.from({ length: this.maxPages}, (_, i) => i + 1);
        this.currentSelectedPageList = this.localservice.paginateData(this.filteredRiderList, this.currentPage, this.pageSize)
        
        if(this.maxPages == 1){
            this.disableActionBtns.next = true;
            this.disableActionBtns.prev = true;
        }else{
            this.disableActionBtns.next = false;
            this.disableActionBtns.prev = true;
        }//end if     */
    }//end fn

    detailConsumerTabActive(tabindex: string){
        Object.keys(this.consumerTabs2).forEach((x) => {
            this.consumerTabs2[x] = false;
        });

        this.consumerTabs2[tabindex] = true;
    }//end fn

    /*------------------------------------------
    --------------------------------------------
    moveMap()
    --------------------------------------------
    --------------------------------------------*/
    moveMap(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.center = (event.latLng.toJSON());
    }

    /*------------------------------------------
    --------------------------------------------
    move()
    --------------------------------------------
    --------------------------------------------*/
    move(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.display = event.latLng.toJSON();
    }

    setSortOrder(order: string){
        Object.keys(this.sortOrder).forEach((x) => {
            this.sortOrder[x] = false;
        });

        this.sortOrder[order] = true;
    }//end fn

    fetchUsers(action: string = '', searchQuery: string = '') {
        var fromPage = 1;

        if(action != ''){
            fromPage = this.currentPage;
            switch(action){
                case 'next':
                    this.currentPage++
                break;
    
                case 'prev':
                    if(this.currentPage != 1){
                        this.currentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if

        this.apollo
            .watchQuery({
                fetchPolicy: 'network-only',
                query: gql`
                    query users($page: Int, $querySearch: String, $sortBy: UserSortingInput) {
                        users(page: $page, querySearch: $querySearch, sortBy: $sortBy) {
                            userId
                            userReferrer
                            membershipId
                            name
                            email
                            mobile
                            photo
                            isVerify
                            dateCreated
                            dateUpdated
                            currentPoints
                            usedPoints
                            totalPoints
                        }
                    }
                `,
                variables: {
                    page: this.currentPage,
                    querySearch: searchQuery,
                    sortBy:{
                        "sort": this.userSortBy,
                        "direction": this.userSortDirection
                    }
                }
            }).valueChanges.subscribe({
                next: (res: any) => {
                    if (res.data['users']?.length !== 0) {
                        this.consumerList = res.data['users'];
                        this.currentSelectedPageList = this.consumerList;
                    } else {
                        this.currentPage = fromPage;
                    }
                    this.fetchOverview();
                },
                error: (error) => {
                    if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                        this.authStore.logout().subscribe();
                    }
                }
            });
    }

    onSortChange(){
        this.fetchUsers();
    }

    nextPrevBooking(action: string){
        switch(action){
            case 'next':
                this.fetchTransactions(this.selectedServiceType, 'next');
            break;
  
            case 'prev':
                this.fetchTransactions(this.selectedServiceType, 'next');
            break;
        }//end switch
    }//end fn

    onServiceTypeChange(event: any) {
        // Reset pagination if needed
        // this.currentPage = 1;
        
        // Call your API or filter method based on selected service type
        this.loadBookingsForServiceType(this.selectedServiceType);
    }

    // Helper method to load bookings
    private loadBookingsForServiceType(serviceType: string) {
        // Example implementation - adjust based on your actual API/service
        switch(serviceType) {
            case 'express':
                // Load OK! Express bookings
                this.fetchTransactions('express');
                break;
            case 'okride':
                // Load OK! Ride bookings
                this.fetchTransactions('okride');
                break;
            case 'mototaxi':
                // Load OK! MotoTaxi bookings
                this.fetchTransactions('mototaxi');
                break;
        }
        
        // After loading data, you may want to:
        // 1. Update partnerBookings array
        // 2. Reset pagination
        // 3. Apply any filters/sorting
    }

    onBookingSortChange(){
        this.fetchTransactions(this.selectedServiceType);
    }

    searchDataBooking(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchKeywordBooking = inputElement.value;

        if (this.searchKeywordBooking.trim() === '') {
            console.log('Input is empty');
            this.booking_currentSelectedPageList = this.bookingList;
        } else {
            this.booking_currentSelectedPageList = this.bookingList.filter(object => {
                // Helper function to recursively search through nested objects
                const searchInObject = (obj: any): boolean => {
                    for (const key in obj) {
                        const value = obj[key];
                        
                        // Skip if null or undefined
                        if (value == null) continue;
                        
                        // If value is an object or array, search recursively
                        if (typeof value === 'object') {
                            if (searchInObject(value)) return true;
                        }
                        // Check if the value includes the search term
                        else if (value.toString().toLowerCase().includes(this.searchKeyword.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                };
                
                return searchInObject(object);
            });
        }
    }//end nfn
}
