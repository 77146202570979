import { gql } from 'apollo-angular';

export const CANCEL_WITHDRAW_REQUEST = gql`
  mutation cancelWithdrawRequest($walletRequestId: WalletHashedId!, $remarks: String!) {
    cancelWithdrawRequest(walletRequestId: $walletRequestId, remarks: $remarks) {
      partnerWalletRequestId
      bankName
      bankCode
      accountName
      accountNumber
      bankCode
      status
      dateApproved
      dateCancelled
      remarks
      dateCreated
      dateUpdated
    }
  }
`;

export const PROCESS_WITHDRAW_REQUEST = gql`
  mutation processWithdrawRequest($walletRequestId: WalletHashedId!) {
    processWithdrawRequest(walletRequestId: $walletRequestId) {
      partnerWalletRequestId
      bankName
      bankCode
      accountName
      accountNumber
      bankCode
      dateCreated
      dateUpdated
    }
  }
`;

export const COMPLETE_WITHDRAW_REQUEST = gql`
  mutation completeWithdrawRequest($walletRequestId: WalletHashedId!) {
    completeWithdrawRequest(walletRequestId: $walletRequestId) {
      partnerWalletRequestId
      bankName
      bankCode
      accountName
      accountNumber
      bankCode
      status
      dateApproved
      dateCancelled
      remarks
      dateCreated
      dateUpdated
    }
  }
`; 