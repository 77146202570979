import { Component, TemplateRef, inject, OnInit } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { NavComponent } from "../navigation/nav.component";
import { DashboardService } from '@services/dashboard.service';
import { LocalService } from '@services/local.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {RouterModule} from '@angular/router';
import { NgbModal,NgbAlertModule, NgbDatepickerModule, NgbDateStruct, NgbTimepickerModule, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { PlatformVehicle, Surge } from '@interfaces/models.interface';
import { Apollo, gql } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';
import { AuthStore } from '@store/auth.store';
import { Router } from '@angular/router';
import { TimeFormatPipe } from '../../../shared/pipes/time-format.pipe';
import { cloneDeep, cloneDeepWith } from 'lodash';

@Component({
  selector: 'app-surge-cms',
  standalone: true,
  imports: [NgbDatepickerModule, NgbAlertModule, HeaderComponent, NavComponent, CommonModule, FormsModule, NgbTimepickerModule, RouterModule, TimeFormatPipe ],
  templateUrl: './surge-cms.component.html',
  styleUrl: './surge-cms.component.css'
})

export class SurgeCmsComponent implements OnInit {
    today = new Date();
    model?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate(), };
    model2?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate(), };
    time: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
    time2: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
    meridian = false;   
    
  constructor(
      private dasher: DashboardService,
      private localservice: LocalService,
      private modalService: NgbModal,
      private apollo: Apollo,
      private toastr: ToastrService,
      private router: Router,
      private authStore: AuthStore,
  ) { }
    public searchKeyword: string = '';
    public showDetails: boolean = false;
    public surgeTabs: { [key: string]: boolean } = {
        'express': true,
        'mototaxi': false,
        'ride': false
    };

    public surgePriceForm: Record<string, any> = {
      title: "",
      serviceType: "",
      isPriority: false,
      dateStart: "",
      dateEnd: "",
      timeStart: "",
      timeEnd: "",
      multiplier: 1.1
    };
    
    public updateSurgePriceForm: Record<string, any> = {
      title: "",
      serviceType: "",
      timeStart: "",
      timeEnd: "",
      multiplier: 1.1,
      isPriority: false,
      isActive: false
    };

    surgeIsAllDay: boolean = false;
    isRateValid: boolean = true;

    public enableEdit: boolean = false;
    surgeSortBy: string = 'dateCreated'; //multiplier, dateCreated
    surgeSortDirection: string = 'desc'; //desc, asc
    public currentSelectedPageList: Surge[] = [];
    public detailViewed: Record<string, any> = {};

    surgeList: any[] = [];
    currentPage: number = 1;
    
    setTabPanelActive(tabName: string) {
      Object.keys(this.surgeTabs).forEach(key => {
        this.surgeTabs[key] = false;
      });
      this.surgeTabs[tabName] = true;
      this.fetchSurgePrices();
    }

    closeAllModals(){
      this.modalService.dismissAll();
    }//end fn

    openAddSurgePrice(modal: TemplateRef<any>) {
      this.modalService.open(modal, { 
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      });
    }
    openConfirmCreate(modal: TemplateRef<any>) {
      this.modalService.open(modal, { 
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      });
    }

    createSurgeData(modal: TemplateRef<any>) {
      // Format dates and times separately
      const startDateStr = `${this.model!.year}-${String(this.model!.month).padStart(2, '0')}-${String(this.model!.day).padStart(2, '0')}`;
      const startTimeStr = `${String(this.time.hour).padStart(2, '0')}:${String(this.time.minute).padStart(2, '0')}:00`;
      const endDateStr = `${this.model2!.year}-${String(this.model2!.month).padStart(2, '0')}-${String(this.model2!.day).padStart(2, '0')}`;
      const endTimeStr = `${String(this.time2.hour).padStart(2, '0')}:${String(this.time2.minute).padStart(2, '0')}:00`;

      // Combine date and time for final format
      const startDate = `${startDateStr}`;
      const endDate = `${endDateStr}`;
      
      if (!this.model || !this.model2 || !this.time || !this.time2 || !this.surgePriceForm['title'] ) {
        this.toastr.error('Please fill in all required fields');
        return;
      }

      this.surgePriceForm['serviceType'] = this.surgeTabs['express'] ? 'express' : this.surgeTabs['mototaxi'] ? 'mototaxi' : 'okride';
      this.surgePriceForm['dateStart'] = startDate;
      this.surgePriceForm['dateEnd'] = endDate;
      this.surgePriceForm['timeStart'] = startTimeStr;
      this.surgePriceForm['timeEnd'] = endTimeStr;

      this.apollo.mutate({
        mutation: gql`
          mutation createPriceSurge($createPriceSurgeInput: CreatePriceSurgeInput!) {
            createPriceSurge(createPriceSurgeInput: $createPriceSurgeInput) {
              surgeConfigId
              surgeDate
              timeStart
              timeEnd
              multiplier
              dateCreated
            }
          }
        `,
        variables: {
          "createPriceSurgeInput": this.surgePriceForm
        }    
      }).subscribe({
        next: ({ data }: any) => {
          this.modalService.open(modal, { 
            backdrop: 'static',
            keyboard: false,
            size: 'lg'
          });

          this.toastr.success('Surge price created successfully');
          this.modalService.dismissAll();
          // Reset form
          this.model = undefined;
          this.model2 = undefined;
          this.time = { hour: 0, minute: 0, second: 0 };
          this.time2 = { hour: 0, minute: 0, second: 0 };
          this.surgePriceForm = {
            title: "",
            isPriority: false,
            serviceType: "express",
            dateStart: "",
            dateEnd: "",
            timeStart: "",
            timeEnd: "",
            multiplier: 1.5
          };
          this.fetchSurgePrices(); // Assuming you have this method to refresh the list
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || 
              (error.graphQLErrors && 
               error.graphQLErrors[0]?.extensions?.status === 403)) {
            this.authStore.logout().subscribe(() => {
              this.router.navigate(['/admin/login']);
            });
          } else {
            this.toastr.error(error.message || 'Error creating surge price');
          }
        }
      });
    }

    confirmUpdateSurgeData(modal: TemplateRef<any>) {
      this.modalService.open(modal, { 
        backdrop: 'static',
        keyboard: false,
        size: 'lg'
      });
    }
    
    updateSurgeData(modal: TemplateRef<any>){
      if (!this.time || !this.time2 || !this.updateSurgePriceForm['title'] ) {
        this.toastr.error('Please fill in all required fields');
        return;
      }
      const startTimeStr = `${String(this.time.hour).padStart(2, '0')}:${String(this.time.minute).padStart(2, '0')}:00`;
      const endTimeStr = `${String(this.time2.hour).padStart(2, '0')}:${String(this.time2.minute).padStart(2, '0')}:00`;
      this.updateSurgePriceForm['timeStart'] = startTimeStr;
      this.updateSurgePriceForm['timeEnd'] = endTimeStr;

      
      this.apollo.mutate({
        mutation: gql`
          mutation updatePriceSurge(
            $surgeId: HashedId!
            $updatePriceSurgeInput: UpdatePriceSurgeInput!
          ) {
            updatePriceSurge(
              surgeId: $surgeId
              updatePriceSurgeInput: $updatePriceSurgeInput
            ) {
              surgeId
              title
              startDate
              endDate
              timeStart
              timeEnd
              multiplier
              serviceType
              isPriority
              isActive
              dateCreated
              dateUpdated
            }
          }
        `,
        variables: {
          "surgeId":this.detailViewed['surgeId'],
          "updatePriceSurgeInput": this.updateSurgePriceForm
        }
      }).subscribe({
        next: ({ data }: any) => {
          if(data['updatePriceSurge']?.length != 0){
              var completeInstance = data['updatePriceSurge'];
              Object.keys(this.updateSurgePriceForm).forEach((x) => {
                  this.updateSurgePriceForm[x] = completeInstance[x];
              });

              this.modalService.open(modal, { 
                backdrop: 'static',
                keyboard: false,
                size: 'lg'
              });
    
              this.toastr.success('Surge price updated successfully');
              this.modalService.dismissAll();
              this.fetchSurgePrices(); // Assuming you have this method to refresh the list
              console.log('triggers fetch');
              this.enableEdit = false;
              // Reset form
              /* this.model = undefined;
              this.model2 = undefined;
              this.time = { hour: 0, minute: 0, second: 0 };
              this.time2 = { hour: 0, minute: 0, second: 0 }; */
          }//end if
        },
        error: (error) => {
            if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                this.authStore.logout().subscribe(() => {
                    // Optional: Redirect to login page or show message
                    this.router.navigate(['/admin/login']);
                });
            }
        }
      });
    }

    fetchSurgePrices(action: string = '') {
      var fromPage = 1;

      if(action != ''){
          fromPage = this.currentPage;
          switch(action){
              case 'next':
                  this.currentPage++
              break;
  
              case 'prev':
                  if(this.currentPage != 1){
                      this.currentPage--
                  }//end if
              break;
          }//end swithc    
      }//end if

      this.apollo.watchQuery({
          fetchPolicy: 'network-only',
          query: gql`
          query surgeList($page: Int, $filter: PriceSurgeFilterInput, $sortBy: PriceSurgeSortingInput) {
              surgeList(page: $page, filter: $filter, sortBy: $sortBy) {
                  title
                  serviceType
                  surgeId
                  startDate
                  endDate
                  timeStart
                  timeEnd
                  multiplier
                  dateCreated
                  dateUpdated
                  createdBy
                  isPriority
                  isActive
              }
          }`,
          variables: {
              "filter": {
                "serviceType": this.surgeTabs['express'] ? 'express' : this.surgeTabs['mototaxi'] ? 'mototaxi' : 'okride'
              },
              "page": this.currentPage,
              "sortBy": {
                sort: this.surgeSortBy,
                direction: this.surgeSortDirection
              }
          }
        }).valueChanges.subscribe({
          next: (res: any) => {
              this.surgeList = [];
              if(res.data['surgeList'].length != 0){
                  for (const surge of res.data['surgeList']) {
                    let copySurge = cloneDeep(surge);
                    
                    // Create Date objects without 'Z' to respect client timezone
                    copySurge.timeStart = new Date(`${copySurge.startDate}T${copySurge.timeStart}`);
                    copySurge.timeEnd = new Date(`${copySurge.endDate}T${copySurge.timeEnd}`);

                    this.surgeList.push(copySurge);
                  }
                  this.currentSelectedPageList = this.surgeList;
              } else {
                  this.currentPage = fromPage;
              }
          },
          error: (error) => {
              if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
                  this.authStore.logout().subscribe(() => {
                      // Optional: Redirect to login page or show message
                      this.router.navigate(['/admin/login']);
                  });
              }
          }
      });
    }

    validateRate() {
      if (!this.surgePriceForm['multiplier']) {
        this.surgePriceForm['multiplier'] = 1.1;
      }
      
      const rate = parseFloat(this.surgePriceForm['multiplier']);
      if (rate < 1.1) {
        this.surgePriceForm['multiplier'] = 1.1;
      } else if (rate > 2.0) {
        this.surgePriceForm['multiplier'] = 2.0;
      }
      
      this.isRateValid = rate >= 1.1 && rate <= 2.0;
    }

    decreaseRate() {
      const currentRate = parseFloat(this.surgePriceForm['multiplier']);
      if (currentRate > 1.1) {
        this.surgePriceForm['multiplier'] = Math.round((currentRate - 0.1) * 10) / 10;
        this.updateSurgePriceForm['multiplier'] = Math.round((currentRate - 0.1) * 10) / 10;
        this.validateRate();
      }
    }

    increaseRate() {
      const currentRate = parseFloat(this.surgePriceForm['multiplier']);
      if (currentRate < 2.0) {
        this.surgePriceForm['multiplier'] = Math.round((currentRate + 0.1) * 10) / 10;
        this.updateSurgePriceForm['multiplier'] = Math.round((currentRate + 0.1) * 10) / 10;
        this.validateRate();
      }
    }

    onAllDayChange(event: any) {
      if (event.target.checked) {
        // Set start time to 00:00
        this.time = {
          hour: 0,
          minute: 0,
          second: 0
        };
        
        // Set end time to 23:59
        this.time2 = {
          hour: 23,
          minute: 59,
          second: 0
        };
      }
    }

    ngOnInit() {
        this.fetchSurgePrices();
    }

    returnToList(){
      //this.setSelectedPageDataBasedOnStatus('all');
      this.showDetails = false;
      this.detailViewed = {};
      
      this.currentPage = 1;
      
      this.fetchSurgePrices();
    }//end fn

    viewDetails(details: Surge) {
        this.showDetails = true;
        this.detailViewed = cloneDeepWith(details, (value) => {
            if (value && value.__typename) {
                const { __typename, ...rest } = value;
                return rest;
            }
        });
        
        this.detailViewed = cloneDeep(this.detailViewed);

        // Copy values from detailViewed to updateSurgePriceForm
        Object.keys(this.updateSurgePriceForm).forEach(key => {
          if(key != 'startDate' && key != 'endDate'){
            switch(key) {
              /*  case 'startDate':
               case 'endDate':
                   this.updateSurgePriceForm[key] = this.detailViewed[key];
                   // Parse start and end dates
                   const startDate = new Date(this.detailViewed['startDate']);
                   const endDate = new Date(this.detailViewed['endDate']);

                   // Set model and model2 with the parsed dates
                   this.model = {
                       year: startDate.getFullYear(),
                       month: startDate.getMonth() + 1,
                       day: startDate.getDate()
                   };
                   
                   this.model2 = {
                       year: endDate.getFullYear(),
                       month: endDate.getMonth() + 1,
                       day: endDate.getDate()
                   };
                   break; */

               case 'timeStart':
                   // Parse and set start time
                   const startTime = new Date(this.detailViewed['timeStart']);
                   this.time = {
                       hour: startTime.getHours(),
                       minute: startTime.getMinutes(),
                       second: 0
                   };
                   this.updateSurgePriceForm[key] = this.detailViewed[key];
                   break;

               case 'timeEnd':
                   // Parse and set end time
                   const endTime = new Date(this.detailViewed['timeEnd']);
                   this.time2 = {
                       hour: endTime.getHours(),
                       minute: endTime.getMinutes(),
                       second: 0
                   };
                   this.updateSurgePriceForm[key] = this.detailViewed[key];
                   break;

               default:
                   this.updateSurgePriceForm[key] = this.detailViewed[key];
           }
          }//end if
        });
    }//end fn

    onSortChange(){
      this.fetchSurgePrices();
    }

    enableEditForm(){
        this.enableEdit = true;
    }//end fn

  disableEditForm(){
      this.enableEdit = false;
  }//end fn
    
  searchData(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchKeyword = inputElement.value;

    if (this.searchKeyword.trim() === '') {
        console.log('Input is empty');
        this.currentSelectedPageList = this.surgeList;
    } else {
        this.currentSelectedPageList = this.surgeList.filter(object => {
            // Helper function to recursively search through nested objects
            const searchInObject = (obj: any): boolean => {
                for (const key in obj) {
                    const value = obj[key];
                    
                    // Skip if null or undefined
                    if (value == null) continue;
                    
                    // If value is an object or array, search recursively
                    if (typeof value === 'object') {
                        if (searchInObject(value)) return true;
                    }
                    // Check if the value includes the search term
                    else if (value.toString().toLowerCase().includes(this.searchKeyword.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            };
            
            return searchInObject(object);
        });
    }
  }//end nfn
}
