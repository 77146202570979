import { Component, TemplateRef, inject } from '@angular/core';
//SUB COMPONENTS
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule, JsonPipe } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { Notification } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';

@Component({
  selector: 'app-dashboard-notification',
  standalone: true,
  imports: [FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule],
  templateUrl: './dashboard-notification.component.html',
  styleUrl: './dashboard-notification.component.css'
})

export class DashboardNotificationComponent {
  imageSrc?: string | ArrayBuffer | null; 
  private modalService = inject(NgbModal);
  
  constructor(
      private dasher: DashboardService,
      private localservice: LocalService,
  ) { }

  notifForm: Record<string, any> = {
    title: "",
    subTitle: "",
    type: "",
    description: "",
    photo: "",
    serviceArea: "",
    userType: ""
  }

  notifPhoto: File | null = null;

  public notifList: Notification[] = [];

  public searchKeyword: string = '';
  public currentSelectedPageList: Notification[] = [];
  public maxPages: number = 0;
  public currentPage: number = 1;
  public detailViewed: Record<string, any> = {};
  public showDetails: boolean = false;
  public enableEdit: boolean = false;
  
  ngOnInit(): void {
      this.fetchNotifications();
      //this.populateLocationDropdowns();
  }

  fetchNotifications(action: string = ''){
      var fromPage = 1;

      if(action != ''){
          fromPage = this.currentPage;
          switch(action){
              case 'next':
                  this.currentPage++
              break;
    
              case 'prev':
                  if(this.currentPage != 1){
                      this.currentPage--
                  }//end if
              break;
          }//end swithc    
      }//end if
      
      this.dasher.tryFetchNotification(this.currentPage).subscribe((res: any) => {
          if(res.length != 0){
              this.notifList = [];
              res.forEach((rider_isntance : any) => {
                  let i = rider_isntance;
                  this.notifList.push(i);
              });

              this.currentSelectedPageList = this.notifList;
          }else{
              this.currentPage = fromPage;
          }//end if
      });
  }//end fn

  nextPrev(action: string){
      switch(action){
          case 'next':
              this.fetchNotifications('next');
          break;

          case 'prev':
              this.fetchNotifications('prev');
          break;
      }//end swithc
  }//end fn

  searchData(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      this.searchKeyword = inputElement.value;

      if (this.searchKeyword.trim() === '') {
          console.log('Input is empty');
          this.currentSelectedPageList = this.notifList;
      } else {
          this.currentSelectedPageList = this.notifList.filter(object => {
              // Helper function to recursively search through nested objects
              const searchInObject = (obj: any): boolean => {
                  for (const key in obj) {
                      const value = obj[key];
                      
                      // Skip if null or undefined
                      if (value == null) continue;
                      
                      // If value is an object or array, search recursively
                      if (typeof value === 'object') {
                          if (searchInObject(value)) return true;
                      }
                      // Check if the value includes the search term
                      else if (value.toString().toLowerCase().includes(this.searchKeyword.toLowerCase())) {
                          return true;
                      }
                  }
                  return false;
              };
              
              return searchInObject(object);
          });
      }
  }//end nfn

  viewDetails(details: Notification){
    this.showDetails = true;
    this.detailViewed = details;
}//end fn

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader(); 
      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
      };
      reader.readAsDataURL(file);
      this.notifPhoto = file;
    }//end
  }//end fn

  createNotification(confirmModal: TemplateRef <any>){
    var errors: number = 0;

    Object.keys(this.notifForm).forEach((x) => {
      if(x != 'photo'){
        if(this.notifForm[x] == ''){
          errors ++;
        }//end if
      }//end if
    });

    if(errors != 0){
      alert('Please enter all required fields');
    }else{
      this.modalService.open(confirmModal, { size: 'md',  backdrop: 'static', keyboard: false });
    }//end if
  }//end fn

  proceedCreateNotification(successModal: TemplateRef<any>){
    const formData:any = new FormData();      
    if (this.notifPhoto) {
      formData.append('file', this.notifPhoto, this.notifPhoto.name);
    }
    
    this.dasher.uploadPhoto(formData, 'news_photo').subscribe((res: any) => {
      this.notifForm['photo'] = res.photo;
      this.dasher.createNotification(this.notifForm).subscribe((res: any) => {
        if(res.length != 0){
          this.modalService.dismissAll();
          Object.keys(this.notifForm).forEach((x) => {
            this.notifForm[x] = '';
          });
          this.imageSrc = null;
          this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false });
        }//end if
      });
    });
  }//end fn

  clearForm(){
    Object.keys(this.notifForm).forEach((x) => {
      this.notifForm[x] = '';
    });

    this.imageSrc = null;
  }//end fn

  closeAllModals(){
    this.modalService.dismissAll();
  }//end fn

  showCreateNotif(modal: TemplateRef<any>){
    this.modalService.open(modal,{ size: 'lg',  backdrop: 'static', keyboard: false });
  }//end fn

  returnToList(){
    this.showDetails = false;
    this.detailViewed = {};

    this.currentPage = 1;
    this.fetchNotifications();
}//end fn
}
