import { gql } from 'apollo-angular';

export const ADMIN_LOGIN = gql`
  mutation adminLogin($authenticationInput: AuthenticationInput!) {
    adminLogin(authenticationInput: $authenticationInput) {
      token
    }
  }
`;

export const VERIFY_ADMIN_OTP = gql`
  mutation verifyAdminOtp($otp: String!) {
    verifyAdminOtp(otp: $otp) {
      message
    }
  }
`;

export const REQUEST_FORGOT_PASSWORD_OTP = gql`
  mutation requestAdminForgotPassword($email: String!) {
    requestAdminForgotPassword(email: $email) {
      message
    }
  }
`;

export const VERIFY_FORGOT_PASSWORD_OTP = gql`
  mutation verifyAdminForgotPassword($email: String!, $otp: String!) {
    verifyAdminForgotPassword(email: $email, otp: $otp) {
      message
    }
  }
`;

export const RESET_ADMIN_PASSWORD = gql`
  mutation resetAdminPassword($email: String!, $newPassword: String!) {
    resetAdminPassword(email: $email, newPassword: $newPassword) {
      message
    }
  }
`;

export const RESEND_ADMIN_OTP = gql`
  mutation resendAdminOtp {
    resendAdminOtp {
      message
    }
  }
`; 