import { gql } from 'apollo-angular';

export const GET_ADMINS = gql`
    query admins($page: Int, $querySearch: String) {
        admins(page: $page, querySearch: $querySearch) {
            adminId
            name
            email
            dateCreated
            status
            isVerify
            isNew
            createdBy
            dateUpdated
        }
    }
`; 