import { Component, inject, TemplateRef } from '@angular/core';
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { Booking, RiderEarning, Transactions, partnerEarningSummaryValues } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import {RouterModule} from '@angular/router';
import { Apollo } from 'apollo-angular';
import { ExcelService } from '@services/excel.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthStore } from '@store/auth.store';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MomentModule } from 'ngx-moment';
//FOR THE MAP
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMap} from '@angular/google-maps';
import { ToastrService } from 'ngx-toastr';
import { 
  BOOKING_EXPRESS_TRANSACTIONS,
  BOOKING_RIDE_MOTOTAXI_TRANSACTIONS,
} from '@gql_queries/transactions/queries';

import { 
  REPORT_TRANSACTION_EXPRESS,
  REPORT_TRANSACTION_OKRIDE,
  REPORT_TRANSACTION_MOTOTAXI,
} from '@gql_queries/report_download/queries';


@Component({
  selector: 'app-dashboard-transactions',
  standalone: true,
  imports: [FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule, MomentModule, GoogleMapsModule, GoogleMap],
  templateUrl: './dashboard-transactions.component.html',
  styleUrl: './dashboard-transactions.component.css'
})
export class DashboardTransactionsComponent {
private offcanvasService = inject(NgbOffcanvas);
  constructor(private toastr: ToastrService, private apollo: Apollo, private excel: ExcelService, private authStore: AuthStore, private router: Router) {}

  public partnerEarningTabs: Record<string, any> = {
      orders: true,
      incentive: false,
      bonus: false,
      tip: false,
      withdraw: false
  };

  public transactionList: Transactions[] = [];
  public filteredTransactionList: Transactions[] = [];
  public searchKeyword: string = '';
  public currentSelectedPageList: Transactions[] = [];
  public maxPages: number = 0;
  public disableActionBtns: any = {
      next: false,
      prev: false,
  };
  public riderPages: number[] = [];
  public currentPage: number = 1;
  public pageSize: number = 15;

  public detailViewed: Record<string, any> = {};
  public showDetails: boolean = false;

  //FOR THE MAP (BOOKING DETAILS)
  polyLinePath: google.maps.LatLng[] = [];
  display: any;
  center: google.maps.LatLngLiteral = {
      lat: 22.2736308,
      lng: 70.7512555
  };
  zoom = 15;
  polyLineOptions: google.maps.PolylineOptions = {
      strokeColor: '#D81960',
      strokeOpacity: 1.0,
      strokeWeight: 5,
      clickable: false,
      editable: false,
      visible: true,
      geodesic: true, // Smoothen the edges
  };
  
  partnerSumamry: partnerEarningSummaryValues = {
      totalPartnerCommisions: 0,
      totalEarnings: 0,
      totalPayout: 0,
  }

  transTabs: Record<string, any> = {
    'express': true,
    'okride': false,
    'mototaxi': false
  };

  reportData: any[] = [];
  completeCount = 0;
  ongoingCount = 0;
  cancelledCount = 0;
  pendingCount = 0;

  transSortBy: string = 'dateCreated';
  transSortDirection: string = 'desc';

  isDownloading = false;

  ngOnInit(): void {
      this.fetchTransactions('express');
  }

  fetchTransactions(servicetype:string = 'all', action: string = ''){
    var fromPage = 1;

    if(action != ''){
        fromPage = this.currentPage;
        switch(action){
            case 'next':
                this.currentPage++
            break;
  
            case 'prev':
                if(this.currentPage != 1){
                    this.currentPage--
                }//end if
            break;
        }//end swithc    
    }//end if

    let requestQry = null;
    if(servicetype == 'express'){
      requestQry = this.apollo
        .watchQuery({
          fetchPolicy: 'network-only',
          query: BOOKING_EXPRESS_TRANSACTIONS,
          variables: {
            sortBy: {
              "sort": this.transSortBy,
              "direction": this.transSortDirection
            }
          }
        });
    } else {
      requestQry = this.apollo
        .watchQuery({
          fetchPolicy: 'network-only',
          query: BOOKING_RIDE_MOTOTAXI_TRANSACTIONS,
          variables: {
            service: servicetype,
            sortBy: {
              "sort": this.transSortBy,
              "direction": this.transSortDirection
            }
          }
        });
    }
    
    requestQry.valueChanges
    .subscribe({
      next: (res: any) => {
        if(servicetype == 'express'){
            if (res && res.data && res.data.bookingExpressTxns && res.data.bookingExpressTxns.length !== 0) {
                this.transactionList = res.data.bookingExpressTxns;
                this.currentSelectedPageList = this.transactionList;
                
                this.completeCount = this.transactionList.filter((x) => (x.status === 'completed')).length;
                this.pendingCount = this.transactionList.filter((x) => (x.status === 'pending')).length;
                const ongoingPattern = /^ongoing_/; // Pattern to match any status starting with 'ongoing_'
                this.ongoingCount = this.transactionList.filter((x) => ongoingPattern.test(x.status)).length;
                this.ongoingCount = this.ongoingCount + this.transactionList.filter((x) => (x.status === 'accepted')).length;
                this.cancelledCount = this.transactionList.filter((x) => (x.status === 'canceled')).length;
    
                this.transactionList.map((x) => {
                    // Create a new object for each entry
                    const fieldListings = {
                        bookingID: x.bookingExpressId,
                        dateCreated: x.dateCreated,
                        notes: x.notes,
                        consumer: x.user != null ? x.user.name : '---',
                        partner: x.partner != null ? `${x.partner.firstName} ${x.partner.lastName}` : '---',
                        status: x.status,
                        totalPrice: x.totalPrice
                    };
                
                    // Push the new object to the reportData array
                    this.reportData.push(fieldListings);
                });
                
                //fieldListings['bookingID'] = this.transactionList.
            }else{
                this.currentPage = fromPage;
            } // end if
        }else{
            if (res && res.data && res.data.bookingRideTxns && res.data.bookingRideTxns.length !== 0) {
                this.transactionList = res.data.bookingRideTxns;
                this.currentSelectedPageList = this.transactionList;
                
                this.completeCount = this.transactionList.filter((x) => (x.status === 'completed')).length;
                this.pendingCount = this.transactionList.filter((x) => (x.status === 'pending')).length;
                const ongoingPattern = /^ongoing_/; // Pattern to match any status starting with 'ongoing_'
                this.ongoingCount = this.transactionList.filter((x) => ongoingPattern.test(x.status)).length;
                this.ongoingCount = this.ongoingCount + this.transactionList.filter((x) => (x.status === 'accepted')).length;
                this.cancelledCount = this.transactionList.filter((x) => (x.status === 'canceled')).length;
    
                this.transactionList.map((x) => {
                    // Create a new object for each entry
                    const fieldListings = {
                        bookingID: x.bookingExpressId,
                        dateCreated: x.dateCreated,
                        notes: x.notes,
                        consumer: x.user != null ? x.user.name : '---',
                        partner: x.partner != null ? `${x.partner.firstName} ${x.partner.lastName}` : '---',
                        status: x.status,
                        totalPrice: x.totalPrice
                    };
                
                    // Push the new object to the reportData array
                    this.reportData.push(fieldListings);
                });
                
                //fieldListings['bookingID'] = this.transactionList.
            }else{
                this.currentPage = fromPage;
            } // end if
        }
      },
      error: (error) => {
        if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
                // Optional: Redirect to login page or show message
                this.router.navigate(['/admin/login']);
            });
        }
      }
    });
  }//end fn

  nextPrev(action: string){
    let activeTab = '';
    Object.keys(this.transTabs).forEach((tab) => {
        if (this.transTabs[tab]) {
            activeTab = tab;
        }
    });
      
      switch(action){
          case 'next':
              this.fetchTransactions(activeTab , 'next');
          break;

          case 'prev':
              this.fetchTransactions(activeTab , 'prev');
          break;
      }//end swithc
  }//end fn


  searchData(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchKeyword = inputElement.value;

    if (this.searchKeyword.trim() === '') {
        console.log('Input is empty');
        this.currentSelectedPageList = this.transactionList;
    } else {
        this.currentSelectedPageList = this.transactionList.filter(object => {
            // Helper function to recursively search through nested objects
            const searchInObject = (obj: any): boolean => {
                for (const key in obj) {
                    const value = obj[key];
                    
                    // Skip if null or undefined
                    if (value == null) continue;
                    
                    // If value is an object or array, search recursively
                    if (typeof value === 'object') {
                        if (searchInObject(value)) return true;
                    }
                    // Check if the value includes the search term
                    else if (value.toString().toLowerCase().includes(this.searchKeyword.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            };
            
            return searchInObject(object);
        });
    }
  }//end nfn

  viewDetails(details: Booking){
    this.showDetails = true;
    if(this.transTabs['express']){
        if(details.orderRoutes != ''){
                this.polyLinePath = google.maps.geometry.encoding.decodePath(details.orderRoutes);
                // Calculate the bounds of the polyline
                const bounds = new google.maps.LatLngBounds();
                this.polyLinePath.forEach((coordinate: google.maps.LatLng) => {
                bounds.extend(coordinate);
            });
        
            // Center the map to the center of the polyline bounds
            this.center = bounds.getCenter().toJSON();
        }
    }else{
        if(details.routes != ''){
                this.polyLinePath = google.maps.geometry.encoding.decodePath(details.routes);
                // Calculate the bounds of the polyline
                const bounds = new google.maps.LatLngBounds();
                this.polyLinePath.forEach((coordinate: google.maps.LatLng) => {
                bounds.extend(coordinate);
            });
        
            // Center the map to the center of the polyline bounds
            this.center = bounds.getCenter().toJSON();
        }
    }//end if
    this.detailViewed = details;
    console.log(details);
  }//end fn

  returnToList(){
      //this.setSelectedPageDataBasedOnStatus('all');
      this.showDetails = false;
      this.detailViewed = {};

      this.currentPage = 1;
      
      this.fetchTransactions();
  }//end fn

  showTabContents(tabindex: string){
      Object.keys(this.partnerEarningTabs).forEach((x) => {
          this.partnerEarningTabs[x] = false;
      });

      this.partnerEarningTabs[tabindex] = true;
  }//end

  downloadReport() {
    if (this.isDownloading) return;
    
    this.isDownloading = true;
    
    setTimeout(() => {
      Object.keys(this.transTabs).forEach((x) => {
          if(this.transTabs[x]){
              this.downloadReportByStatus(x);
          }//end if
      });
      
      // Reset the downloading state after 2 seconds
      setTimeout(() => {
        this.isDownloading = false;
      }, 2000);
    }, 300);
  }//end fn

  downloadReportByStatus(status: string){
    if(status == 'express'){
      this.apollo.watchQuery({
        fetchPolicy: 'network-only',
        query: REPORT_TRANSACTION_EXPRESS,
        variables: {}
      }).valueChanges.subscribe({
        next: (result) => {
          let res:any = result.data;
          if(res != null){
            let msg: string = res.expressTransactionReport.message;
            this.toastr.success(msg, status.toUpperCase() + ' Report', {
              closeButton: true,
              timeOut: 3000
            });
          }else{
            this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
              closeButton: true,
              timeOut: 3000
            });
          }//end if
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }else{
            this.toastr.error(error.message, error.code, {
              closeButton: true,
              timeOut: 3000
            });
          }
        },
      });
    }//end if

    if(status == 'okride'){
      this.apollo.watchQuery({
        fetchPolicy: 'network-only',
        query: REPORT_TRANSACTION_OKRIDE,
        variables: {}
      }).valueChanges.subscribe({
        next: (result) => {
          let res:any = result.data;
          if(res != null){
            let msg: string = res.okRideTransactionReport.message;
            this.toastr.success(msg, status.toUpperCase() + ' Report', {
              closeButton: true,
              timeOut: 3000
            });
          }else{
            this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
              closeButton: true,
              timeOut: 3000
            });
          }//end if
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }else{
            this.toastr.error(error.message, error.code, {
              closeButton: true,
              timeOut: 3000
            });
          }
        },
      });
    }//end if

    if(status == 'mototaxi'){
      this.apollo.watchQuery({
        fetchPolicy: 'network-only',
        query: REPORT_TRANSACTION_MOTOTAXI,
        variables: {}
      }).valueChanges.subscribe({
        next: (result) => {
          let res:any = result.data;
          if(res != null){
            let msg: string = res.motoTaxiTransactionReport.message;
            this.toastr.success(msg, status.toUpperCase() + ' Report', {
              closeButton: true,
              timeOut: 3000
            });
          }else{
            this.toastr.error('An error occured, please try again', 'UNKNWN_ERR', {
              closeButton: true,
              timeOut: 3000
            });
          }//end if
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }else{
            this.toastr.error(error.message, error.code, {
              closeButton: true,
              timeOut: 3000
            });
          }
        },
      });
    }//end if
  }

  tabCallFunction(tabIndex: string){
    Object.keys(this.transTabs).forEach((x) => {
        this.transTabs[x] = false;
    });

    this.transTabs[tabIndex] = true;
    this.fetchTransactions(tabIndex);
  }//end fn

  viewTopupDetails(details: Transactions, offcanvas: TemplateRef<any>){
    this.detailViewed = details;
    console.log(details);
    this.offcanvasService.open(offcanvas, { position: 'end' });
    }//end fn

    onSortChange(){
        let activeTab = '';
        Object.keys(this.transTabs).forEach((tab) => {
            if (this.transTabs[tab]) {
                activeTab = tab;
            }
        });
        
        this.fetchTransactions(activeTab);
    }

    /*------------------------------------------
    --------------------------------------------
    moveMap()
    --------------------------------------------
    --------------------------------------------*/
    moveMap(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.center = (event.latLng.toJSON());
    }

    /*------------------------------------------
    --------------------------------------------
    move()
    --------------------------------------------
    --------------------------------------------*/
    move(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.display = event.latLng.toJSON();
    }
}
