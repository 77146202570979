import { gql } from 'apollo-angular';

export const REPORT_TRANSACTION_EXPRESS = gql`
  query expressTransactionReport {
    expressTransactionReport {
      message
    }
  }
`;

export const REPORT_TRANSACTION_OKRIDE = gql`
  query okRideTransactionReport {
    okRideTransactionReport {
      message
    }
  }
`;

export const REPORT_TRANSACTION_MOTOTAXI = gql`
  query motoTaxiTransactionReport {
    motoTaxiTransactionReport {
      message
    }
  }
`; 