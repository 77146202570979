import { Component } from '@angular/core';
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { RiderEarning, Transactions, partnerEarningSummaryValues } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import {RouterModule} from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { ExcelService } from '@services/excel.service';

@Component({
  selector: 'app-dashboard-transactions',
  standalone: true,
  imports: [FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule],
  templateUrl: './dashboard-transactions.component.html',
  styleUrl: './dashboard-transactions.component.css'
})
export class DashboardTransactionsComponent {
  constructor(private apollo: Apollo, private excel: ExcelService) {}

  public partnerEarningTabs: Record<string, any> = {
      orders: true,
      incentive: false,
      bonus: false,
      tip: false,
      withdraw: false
  };

  public transactionList: Transactions[] = [];
  public filteredTransactionList: Transactions[] = [];
  public searchKeyword: string = '';
  public currentSelectedPageList: Transactions[] = [];
  public maxPages: number = 0;
  public disableActionBtns: any = {
      next: false,
      prev: false,
  };
  public riderPages: number[] = [];
  public currentPage: number = 1;
  public pageSize: number = 15;

  public detailViewed: Record<string, any> = {};
  public showDetails: boolean = false;

  partnerSumamry: partnerEarningSummaryValues = {
      totalPartnerCommisions: 0,
      totalEarnings: 0,
      totalPayout: 0,
  }

  transTabs: Record<string, any> = {
    'express': true,
    'ok_ride': false,
    'ok_mototaxi': false
  };

  reportData: any[] = [];
  completeCount = 0;
  ongoingCount = 0;
  cancelledCount = 0;
  pendingCount = 0;

  ngOnInit(): void {
      this.fetchTransactions();
  }

  fetchTransactions(status:string = 'all', action: string = ''){
    var fromPage = 1;

    if(action != ''){
        fromPage = this.currentPage;
        switch(action){
            case 'next':
                this.currentPage++
            break;
  
            case 'prev':
                if(this.currentPage != 1){
                    this.currentPage--
                }//end if
            break;
        }//end swithc    
    }//end if

    this.apollo
    .watchQuery({
        fetchPolicy: 'network-only',
        query: gql`
        query bookingExpressTxns {
            bookingExpressTxns {
                bookingExpressId
                status
                notes
                dateCreated
                user {
                    dateCreated
                    email
                    isVerify
                    membershipId
                    mobile
                    name
                    currentPoints
                    usedPoints
                    userId
                }
                partner {
                    partnerId
                    tier
                    membershipId
                    firstName
                    middleName
                    lastName
                    mobile
                    email
                    distributionArea
                    vehicleType
                    licensePlate
                    isOtpVerify
                    isApprove
                    dateCreated
                    currentPoints
                    usedPoints
                }
                totalPrice
            }
        }
        `,
        variables: {}, // Variables should be here
    })
    .valueChanges
    .subscribe((res: any) => {
        if (res && res.data && res.data.bookingExpressTxns && res.data.bookingExpressTxns.length !== 0) {
            this.transactionList = res.data.bookingExpressTxns;
            this.currentSelectedPageList = this.transactionList;
            
            this.completeCount = this.transactionList.filter((x) => (x.status === 'completed')).length;
            this.pendingCount = this.transactionList.filter((x) => (x.status === 'pending')).length;
            const ongoingPattern = /^ongoing_/; // Pattern to match any status starting with 'ongoing_'
            this.ongoingCount = this.transactionList.filter((x) => ongoingPattern.test(x.status)).length;
            this.ongoingCount = this.ongoingCount + this.transactionList.filter((x) => (x.status === 'accepted')).length;
            this.cancelledCount = this.transactionList.filter((x) => (x.status === 'canceled')).length;

            this.transactionList.map((x) => {
                // Create a new object for each entry
                const fieldListings = {
                    bookingID: x.bookingExpressId,
                    dateCreated: x.dateCreated,
                    notes: x.notes,
                    consumer: x.user != null ? x.user.name : '---',
                    partner: x.partner != null ? `${x.partner.firstName} ${x.partner.lastName}` : '---',
                    status: x.status,
                    totalPrice: x.totalPrice
                };
            
                // Push the new object to the reportData array
                this.reportData.push(fieldListings);
            });
            
            //fieldListings['bookingID'] = this.transactionList.
        } // end if
    });
  }//end fn

  countPerPartnerStatus(){
      
  }
  /* 
  setPageSelected(setPage: number){
      if(setPage > this.maxPages){
          this.currentPage = this.maxPages;
      }else{
          this.currentPage = setPage;
      }//end if

      if(this.currentPage == 1){
          this.disableActionBtns.prev = true;
      }else{
          this.disableActionBtns.prev = false;
      }//end if

      if(this.currentPage == this.maxPages){
          this.disableActionBtns.next = true;
      }else{
          this.disableActionBtns.next = false;
      }//end if
      
      this.currentSelectedPageList = this.localservice.paginateData(this.filteredRiderList, this.currentPage, this.pageSize)
  }//end fn */

  nextPrev(action: string){
      var tabActive = '';
      
      switch(action){
          case 'next':
              this.fetchTransactions(tabActive , 'next');
          break;

          case 'prev':
              this.fetchTransactions(tabActive , 'prev');
          break;
      }//end swithc
  }//end fn


  searchData(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.searchKeyword = inputElement.value;

    if (this.searchKeyword.trim() === '') {
        console.log('Input is empty');
        this.currentSelectedPageList = this.transactionList
    }else{
        this.currentSelectedPageList =  this.transactionList.filter(object =>
            Object.values(object).some(value => 
                (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
            )
        );
    }//end if
}//end nfn

  viewDetails(details: RiderEarning){
      this.showDetails = true;
      this.detailViewed = details;
      console.log(details);
  }//end fn

  returnToList(){
      //this.setSelectedPageDataBasedOnStatus('all');
      this.showDetails = false;
      this.detailViewed = {};

      this.currentPage = 1;
      
      this.fetchTransactions();
  }//end fn

  showTabContents(tabindex: string){
      Object.keys(this.partnerEarningTabs).forEach((x) => {
          this.partnerEarningTabs[x] = false;
      });

      this.partnerEarningTabs[tabindex] = true;
  }//end

  downloadReport(){
    this.excel.generateExcel(this.reportData, 'transactions');
  }//end fn

  tabCallFunction(tabIndex: string){
    Object.keys(this.transTabs).forEach((x) => {
        this.transTabs[x] = false;
    });

    this.transTabs[tabIndex] = true;
  }//end fn
}
