<div class="wrap">
    <app-header></app-header>
    <div class="main-holder" *ngIf="!this.showDetails; else showDetailComponent">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Dashboard Accounts</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Dashboard Accounts</h2>
                </div>
                <hr>
                <div class="list-holder" >
                    <div class="tools">
                        <div class="search">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                placeholder="Search">
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default">Download</button>
                        </div>
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Registration Date</th>
                                    <th>Status</th>
                                    <th>Verified</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                    <tr *ngFor="let user of this.currentSelectedPageList">
                                        <td>{{ user.name }}</td>
                                        <td>{{ user.email }}</td>
                                        <td>{{ user.created_at | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</td>
                                        <td>
                                            <span [ngClass]="(user.status) ? 'stat stat-active' : 'stat stat-pending'">{{
                                                (user.status) ? 'ACTIVE' :
                                                'INACTIVE' }}</span>
                                        </td>
                                        <td>
                                            <span [ngClass]="(user.is_verified_email) ? 'stat stat-active' : 'stat stat-pending'">{{
                                                (user.is_verified_email) ? 'VERIFIED' :
                                                'NOT VERIFIED YET' }}</span>
                                        </td>
                                        <td><a (click)="viewDetails(user)" style="cursor: pointer;">View</a></td>
                                    </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')" [disabled]="this.disableActionBtns.prev"><img
                                    src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <ol>
                                <li *ngFor="let page of this.riderPages" style="cursor: pointer;" (click)="setPageSelected(page)"><a>{{
                                        page }}</a>
                                </li>
                
                            </ol>
                            <button (click)="nextPrev('next')" [disabled]="this.disableActionBtns.next"><img
                                    src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #showDetailComponent>
        <div class="main-holder">
            <app-nav></app-nav>
            <div class="main">
                <div class="main-content">
                    <div class="bread">
                        <ol>
                            <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><a (click)="this.returnToList()" style="cursor: pointer;">Dashboard Accounts</a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><span>User Details</span></li>
                        </ol>
                    </div>
                    <div class="profile-holder">
                        <div class="profile">
                            <div class="pfp">
                                <img src="../../../../assets/okito/images/avatar.png">
                            </div>
                            <div class="profile-info">
                                <h4>{{ this.detailViewed['name'] }}</h4>
                                <div class="tags">
                                    <span class="stat-icon" *ngIf="this.detailViewed['status']; else showNotActive"><img
                                            src="../../../../assets/okito/images/badge/verified.svg">ACTIVE</span>
                                    <ng-template #showNotActive>
                                        <span class="stat-icon"><img src="../../../../assets/okito/images/badge/not-verified.svg">NOT
                                            ACTIVE</span>
                                    </ng-template>

                                    <!-- <span class="stat stat-active">Active</span> -->
                                    <span class="stat-icon"
                                        *ngIf="this.detailViewed['is_verified_email']; else showNotVerified"><img
                                            src="../../../../assets/okito/images/badge/verified.svg">EMAIL VERIFIED</span>
                                    <ng-template #showNotVerified>
                                        <span class="stat-icon"><img
                                                src="../../../../assets/okito/images/badge/not-verified.svg">NOT YET
                                            EMAIL VERIFIED</span>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="profile-actions">
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;">Edit User</button>
                            <button class="btn btn-sm btn-default" (click)="this.returnToList()">Return to List</button>
                        </div>
                    </div>
                    <div class="list-tabs">
                        <ul>
                            <li class="active"><a>Personal Information</a></li>
                        </ul>
                    </div>
                    <div class="panel">
                        <h3>Account Information</h3>
                        <div class="panel-info">
                            <div class="panel-grp">
                                <div class="mb-2 row">
                                    <label class="col-sm-2 col-form-label">Name</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled
                                            [(ngModel)]="this.detailViewed['name']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-2 row">
                                    <label class="col-sm-2 col-form-label">Email</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled
                                            [(ngModel)]="this.detailViewed['email']">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>