import { Component, inject, TemplateRef, ViewEncapsulation } from '@angular/core';

//SUB COMPONENTS
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { DashboardService } from '@services/dashboard.service';
import { Rider, Document, partnerSummaryValues, Booking, WithdrawalRequest, TopupRequest } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { CommonModule } from '@angular/common';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { NgbModal, NgbDateStruct, NgbDatepickerModule, NgbAlertModule, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { Apollo, gql } from 'apollo-angular';
//FOR THE MAP
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMap} from '@angular/google-maps';
// Import the library
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { cloneDeepWith, cloneDeep } from 'lodash';

@Component({
    selector: 'app-dashboard-riders',
    standalone: true,
    templateUrl: './dashboard-riders.component.html',
    styleUrl: './dashboard-riders.component.css',
    imports: [NgbDatepickerModule, NgbAlertModule, FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule, MomentModule, GoogleMapsModule, GoogleMap, NgxImageZoomModule],
})

export class DashboardRidersComponent {
    imageSrc?: string | ArrayBuffer | null; 
    today = new Date();
    model?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() };
    private offcanvasService = inject(NgbOffcanvas);
    dateToday: Date;
    dateSevenDaysAgo: Date;

    constructor(
        private dasher: DashboardService,
        private localservice: LocalService,
        private apollo: Apollo
        
    ) { 
        this.dateToday = new Date();
        this.dateSevenDaysAgo = new Date(this.today);
        this.dateSevenDaysAgo.setDate(this.today.getDate() - 7);
    }
    
    
    
    private modalService = inject(NgbModal);
    public riderList: Rider[] = [];
    //public filteredRiderList: Rider[] = [];
    public searchKeyword: string = '';
    public searchKeywordWithdrawal: string = '';
    public searchKeywordTopup: string = '';

    public currentSelectedPageList: Rider[] = [];
    public maxPages: number = 0;
    public disableActionBtns: any = {
        next: false,
        prev: false,
    };
    public disableActionBtnsBooking: any = {
        next: false,
        prev: false,
    };

    public disableActionBtnsWithdrawals: any = {
        next: false,
        prev: false,
    };

    public disableActionBtnsTopups: any = {
        next: false,
        prev: false,
    };
    public riderPages: number[] = [];
    public currentPage: number = 1;
    public currentPageBooking: number = 1;
    public currentPageWithdrawals: number = 1;
    public currentPageTopups: number = 1;

    public pageSize: number = 2;
    public detailViewed: Record<string, any> = {};
    public showDetails: boolean = false;
    public detailTabs: Record<string, any> = {
        personal: true,
        service_details: false,
        documents: false,
        transactions: false,
        topups: false,
        withdrawals: false
    }
    public documentDictionary: Record<string, any> = {
        selfie: 'Selfie Photo',
        id: 'ID',
        medical_certificate: 'Medical Certificate',
        tnvs_certificate: 'TNVS Certification',
        insurance: 'Insurance',
        vehicle_registration: 'Vehicle Registration',
        vehicle_photo: 'Vehicle Photo',
        drivers_license: 'Driver`s License',
        nbi_certificate: 'NBI Certifacte',
        gcash_settlement: 'G-CASH Settlement',
        spa_letter: 'Authorization/SPA Letter',
        drug_test_certificate: 'Drug Test Certificate',
        driving_track_certificate: 'Driving / Track Pass Certificate',
        no_modification_certification: 'No Modification Certificate',
        truck_permit: 'Truck Permit',
    }

    

    public partnerTabs: Record<string, any> = {
        /* FOR_ALL: true,
        FOR_VERIFICATION: false,
        FOR_APPROVAL: false,
        FOR_AMMENDMENT: false,
        FOR_RESUBMISSION: false,
        VERIFIED: false,
        APPROVED: false */
        FOR_ALL: true,
        APPROVED: false,
        FOR_REVIEW: false,
        FOR_AMMENDMENT: false,
        INITIAL_REGISTERED: false,
    }
    
    /* public partnerTotalStatus: Record<string, any> = {
        all: 0,
        for_verification: 0,
        for_approval: 0,
        for_ammendment: 0,
        for_resubmission: 0,
        verified: 0,
        approved: 0
    } */

    public formDocumentType: Record<string, any> = {
        selfie: false,
        id: false,
        medical_certificate: false,
        spa_letter: false,
        tnvs_certificate: false,
        gcash_settlement: false,
        nbi_certificate: false,
        truck_permit: false,
        insurance: {
            front: false,
            back: false
        },
        drivers_license: {
            front: false,
            back: false
        },
        vehicle_registration: {
            official_receipt: false,
            certificate_registraiton: false
        },
        vehicle_photo: {
            front: false,
            right: false,
            left: false,
            back: false,
        },
        drug_test_certificate: false,
        driving_track_certificate: false,
        no_modification_certification: false,
    }
    currentSelectedDocument = '';
    selectedDocInfo: Document = {
        partnerDocumentId: '',
        idNumber: '',
        firstName: '',
        middleName: '',
        lastName: '',
        isDone: false,
        link: '',
        status: 0,
        type: '',
        subType: '',
        address: '',
        dateCreated: '',
        expirationDate: '',
        birthdate: null,
        dateApprove: null,
        gender: false, // Usually, gender would be 'male', 'female', or another string identifier, boolean is uncommon for this.
    };
    
    public confirmSelection: Record<string, any> = {
        amend: false,
        verify_doc: false,
        verify_partner: false,
        remove_doc: false,
    }

    public enableEdit: boolean = false;
    partnerSumamry: partnerSummaryValues = {
        forVerification: 0,
        forApproval: 0,
        forAmmendment: 0,
        forResubmission: 0,
        verified: 0,
        approved: 0,    
    }


    partnerBookings: Booking[] = [];
    public bookingdetailViewed: Record<string, any> = {};
    public withdrawdetailViewed: Record<string, any> = {};
    public topupdetailViewed: Record<string, any> = {};

    topUpForm: Record<any, any> = {
        amt: 0,
        partnerId: '',  
    }

    //FOR THE MAP (BOOKING DETAILS)
    polyLinePath: google.maps.LatLng[] = [];
    display: any;
    center: google.maps.LatLngLiteral = {
        lat: 22.2736308,
        lng: 70.7512555
    };
    zoom = 15;
    polyLineOptions: google.maps.PolylineOptions = {
        strokeColor: '#D81960',
        strokeOpacity: 1.0,
        strokeWeight: 5,
        clickable: false,
        editable: false,
        visible: true,
        geodesic: true, // Smoothen the edges
    };
        
    public detailViewdDocuments: Document[] = [];

    public withdrawalList: WithdrawalRequest[] = [];
    public currentSelectedPageListWithdrawals: WithdrawalRequest[] = [];

    public topUpList: TopupRequest[] = [];
    public currentSelectedPageListTopups: TopupRequest[] = [];

    public dayViewWithdrawals: Record<string, any> = {
        days_7: true,
        days_30: false,
    };

    public dayViewtopUps: Record<string, any> = {
        days_7: true,
        days_30: false,
    };

    documentPhoto: File | null = null;

    public documentFormNew: Record<string, any> = {
        partnerId: '',
        type: '',
        subType: '',
        link: '',
        status: 'pending'
    }

    public documentForRemoval: Document = {
        partnerDocumentId: '',
        idNumber: '',
        firstName: '',
        middleName: '',
        lastName: '',
        isDone: false,
        link: '',
        status: 0,
        type: '',
        subType: '',
        address: '',
        dateCreated: '',
        expirationDate: '',
        birthdate: null,
        dateApprove: null,
        gender: false, // Usually, gender would be 'male', 'female', or another string identifier, boolean is uncommon for this.
    };

    ngOnInit(): void {
        this.fetchPartnersByStatus();
    }

    fetchPartnersByStatus(status:string = 'FOR_ALL', action: string = ''){
        var fromPage = 1;

        if(action != ''){
            fromPage = this.currentPage;
            switch(action){
                case 'next':
                    this.currentPage++
                break;
    
                case 'prev':
                    if(this.currentPage != 1){
                        this.currentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if

        this.apollo
        .watchQuery({
            fetchPolicy: 'network-only',
            query: gql`
            query partners(
                $page: Int
                $status: PartnerStatusFilter
                $querySearch: String
                $start: String
                $end: String
                $ids: [PartnerHashedId!]
                $sortBy: PartnerSortingInput
            ) {
                partners(page: $page, status: $status, querySearch: $querySearch, start: $start, end: $end, ids: $ids, sortBy: $sortBy) {
                    partnerId
                    tier
                    membershipId
                    firstName
                    middleName
                    lastName
                    mobile
                    email
                    distributionArea
                    vehicleType
                    licensePlate
                    isOtpVerify
                    isApprove
                    latitude
                    longitude
                    dateCreated
                    rating
                    currentPoints
                    usedPoints
                    status
                    partnerDocuments{
                        partnerDocumentId
                        type
                        subType
                        link
                        isDone
                        status
                        idNumber
                        firstName
                        middleName
                        lastName
                        address
                        expirationDate
                        gender
                        birthdate
                        dateApprove
                        dateCreated
                    }
                    partnerVehicles{
                        vehicleId
                        dateCreated
                        status
                        isExpress
                        isOkRide
                        isMototaxi
                    }
                }
            }
            `,
            variables: {
                "page": this.currentPage,
                "status": status,
            }
        }).valueChanges.subscribe((res: any) => {
            if(res.data['partners'].length != 0){
                this.riderList = [];
                this.riderList = res.data['partners'];
                this.currentSelectedPageList = this.riderList;
                this.countPerPartnerStatus();
            }else{
                this.currentPage = fromPage;
            }//end if
        });
    }//end fn

    countPerPartnerStatus(){
        this.dasher.tryFetchPartnerSummary().subscribe((res: any) => {
            if(res.length != 0){
                this.partnerSumamry = res;
            }//end if
        });
    }

    nextPrev(action: string){
        var tabActive = '';
        Object.keys(this.partnerTabs).forEach((x) => {
            if(this.partnerTabs[x]){
                tabActive = x;
            }//end if
        });

        switch(action){
            case 'next':
                this.fetchPartnersByStatus(tabActive , 'next');
            break;
  
            case 'prev':
                this.fetchPartnersByStatus(tabActive , 'prev');
            break;
        }//end swithc
    }//end fn


    nextPrevBooking(action: string){
        switch(action){
            case 'next':
                this.fetchPartnersBookings(this.detailViewed['partnerId'],  'next');
            break;
  
            case 'prev':
                this.fetchPartnersBookings(this.detailViewed['partnerId'], 'prev');
            break;
        }//end switch
    }//end fn


    nextPrevWithdrawals(action: string){
        switch(action){
            case 'next':
                this.fetchWithdrawalRequests('all', this.detailViewed['partnerId'], this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday), 'next');
            break;
                
            case 'prev':
                this.fetchWithdrawalRequests('all', this.detailViewed['partnerId'], this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday), 'prev');   
            break;
        }//end switch
    }//end fn
            
    nextPrevTopups(action: string){
        switch(action){
            case 'next':
                this.fetchTopupRequests('all', '', null, this.detailViewed['partnerId'], this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday), 'next');
            break;
  
            case 'prev':
                this.fetchTopupRequests('all', '', null, this.detailViewed['partnerId'], this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday), 'prev');
            break;
        }//end switch
    }//end fn


    searchData(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchKeyword = inputElement.value;
  
        if (this.searchKeyword.trim() === '') {
            console.log('Input is empty');
            this.currentSelectedPageList = this.riderList
        }else{
            this.currentSelectedPageList =  this.riderList.filter(object =>
                Object.values(object).some(value => 
                    (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
                )
            );
        }//end if
    }//end nfn

    searchTopUpData(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchKeywordTopup = inputElement.value;
  
        if (this.searchKeywordTopup.trim() === '') {
            console.log('Input is empty');
            this.currentSelectedPageListTopups = this.topUpList;
        }else{
            this.currentSelectedPageListTopups =  this.topUpList.filter(object =>
                Object.values(object).some(value => 
                    (value ? value.toString().toLowerCase() : '').includes(this.searchKeywordTopup.toLowerCase())
                )
            );
        }//end if
    }//end nfn


    searchWithdrawalData(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchKeywordWithdrawal = inputElement.value;
  
        if (this.searchKeywordWithdrawal.trim() === '') {
            console.log('Input is empty');
            this.currentSelectedPageListWithdrawals = this.withdrawalList
        }else{
            this.currentSelectedPageListWithdrawals =  this.withdrawalList.filter(object =>
                Object.values(object).some(value => 
                    (value ? value.toString().toLowerCase() : '').includes(this.searchKeywordWithdrawal.toLowerCase())
                )
            );
        }//end if
    }//end nfn

    viewDetails(details: Rider){
        console.log(details);
        this.showDetails = true;
        this.detailViewed = cloneDeepWith(details, (value) => {
            if (value && value.__typename) {
                const { __typename, ...rest } = value;
                return rest;
            }
        });
        this.detailViewed = cloneDeep(this.detailViewed);
        this.detailViewdDocuments = cloneDeepWith(details.partnerDocuments, (value) => {
            if (value && value.__typename) {
                const { __typename, ...rest } = value;
                return rest;
            }
        });
        this.detailViewdDocuments = cloneDeep(this.detailViewdDocuments);
        console.log(this.detailViewdDocuments);
        this.fetchPartnersBookings(details.partnerId);
        //loads 7 days
        this.fetchWithdrawalRequests('all', details.partnerId, this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday));
        this.fetchTopupRequests('all', '', null, details.partnerId, this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday));
    }//end fn

    fetchWidthdrawalsByDateRange(daysBetweenToday: number){
        this.setDaysBetween(daysBetweenToday);
        if(daysBetweenToday == 7){
            this.dayViewWithdrawals['days_7'] = true;
            this.dayViewWithdrawals['days_30'] = false;
        }else{
            this.dayViewWithdrawals['days_7'] = false;
            this.dayViewWithdrawals['days_30'] = true;
        }
        this.fetchWithdrawalRequests('all', this.detailViewed['partnerId'], this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday));
    }//end fn


    fetchWTopUpsByDateRange(daysBetweenToday: number){
        this.setDaysBetween(daysBetweenToday);
        if(daysBetweenToday == 7){
            this.dayViewtopUps['days_7'] = true;
            this.dayViewtopUps['days_30'] = false;
        }else{
            this.dayViewtopUps['days_7'] = false;
            this.dayViewtopUps['days_30'] = true;
        }


        this.fetchTopupRequests('all', '', null, this.detailViewed['partnerId'], this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday));
    }//end fn

    setDaysBetween(datesBetween: number){
        this.dateToday = new Date();
        this.dateSevenDaysAgo = new Date(this.today);
        this.dateSevenDaysAgo.setDate(this.today.getDate() - datesBetween);
    }//end fn

    formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    fetchPartnersBookings(partnerId:string, action: string = ''){
        var fromPage = 1;

        if(action != ''){
            fromPage = this.currentPage;
            switch(action){
                case 'next':
                    this.currentPage++
                break;
      
                case 'prev':
                    if(this.currentPage != 1){
                        this.currentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if

        this.dasher.tryFetchPartnerBookings(partnerId, this.currentPage).subscribe((res: any) => {
            if(res.length != 0){
                this.partnerBookings = [];
                res.forEach((booking_instance : any) => {
                    let i = booking_instance;
                    this.partnerBookings.push(i);
                });
            }else{
                this.currentPage = fromPage;
            }//end if
        });
    }//end fn


    viewBookingDetails(details: Booking, offcanvas: TemplateRef<any>){
        this.bookingdetailViewed = details;
        console.log(this.bookingdetailViewed);
        if(details.orderRoute != ''){
            this.polyLinePath = google.maps.geometry.encoding.decodePath(details.orderRoute);
            // Calculate the bounds of the polyline
            const bounds = new google.maps.LatLngBounds();
                this.polyLinePath.forEach((coordinate: google.maps.LatLng) => {
                bounds.extend(coordinate);
            });
        
            // Center the map to the center of the polyline bounds
            this.center = bounds.getCenter().toJSON();
        }
        this.offcanvasService.open(offcanvas, { position: 'end' });
    }//end fn


    viewWithdrawDetails(details: WithdrawalRequest, offcanvas: TemplateRef<any>){
        this.withdrawdetailViewed = details;
        this.offcanvasService.open(offcanvas, { position: 'end' });
    }//end fn


    viewTopupDetails(details: TopupRequest, offcanvas: TemplateRef<any>){
        this.topupdetailViewed = details;
        this.offcanvasService.open(offcanvas, { position: 'end' });
    }//end fn

    returnToList(){
        //this.setSelectedPageDataBasedOnStatus('all');
        this.showDetails = false;
        this.detailViewed = {};
        
        this.currentPage = 1;
        
        this.fetchPartnersByStatus();
    }//end fn

    setTabPanelActive(panelIndex: string){
        Object.keys(this.detailTabs).forEach((x) => {
            this.detailTabs[x] = false;
        });

        this.detailTabs[panelIndex] = true;
    }//end fn
    
    verifyDocumentName(document_index: string){
        return this.documentDictionary[document_index];
    }//end fn

    filterUser(tabindex: string){
        //this.setSelectedPageDataBasedOnStatus(tabindex);
        this.riderList = [];
        this.currentSelectedPageList = [];

        Object.keys(this.partnerTabs).forEach((x) => {
            this.partnerTabs[x] = false;
        });

        this.partnerTabs[tabindex] = true;

        this.currentPage = 1;
        this.fetchPartnersByStatus(tabindex);
    }//end

    /* setSelectedPageDataBasedOnStatus(tabindex: string){
        this.currentPage = 1;
        Object.keys(this.partnerTabs).forEach((x) => {
            this.partnerTabs[x] = false;
        });
        this.partnerTabs[tabindex] = tabindex;
        this.fetchPartnersByStatus(tabindex);   
    }//end fn */

    /* amendThisDocument(modal: TemplateRef<any>, rider_id: string, document_details: Document){
        var params = {
            id: document_details.id,
            type: document_details.type,
            link: '',
            isDone: true,
            dateCreated: document_details.dateCreated
        }

        this.dasher.tryValidateDocument(rider_id, params).subscribe((res) => {
            this.detailViewed['documents'].filter((x: any) => x.id == params.id);
            this.modalService.open(modal,{ size: 'md',  backdrop: 'static', keyboard: false});
        });
    }//end fn */

    validateThisDocument(modal: TemplateRef<any>, action_id: number , rider_id: string, document_details: Document){
        switch (action_id) {
            case 0: //pending
                //document_details.isDone = false;
                document_details.status = 0;
            break;

            case 1: //approve 
                if((document_details.type == 'drivers_license' && document_details.subType == 'front') || (document_details.type == 'vehicle_registration' && document_details.subType == 'official_receipt') || (document_details.type == 'vehicle_registration' && document_details.subType == 'certificate_registraiton') || (document_details.type == 'insurance' && document_details.subType == 'front') || (document_details.type == 'insurance' && document_details.subType == 'back')){
                    let pubdate = this.model?.year + '-' + ((this.model!.month.toString().length > 1) ? this.model!.month: '0'+this.model!.month) + '-' + ((this.model!.day.toString().length > 1) ? this.model!.day: '0'+this.model!.day);
                    document_details.expirationDate = pubdate.toString();
                }//end if
                
                //document_details.isDone = true;
                document_details.status = 1;
            break;
        
            case 2: //ammendment
            //document_details.isDone = false;
                document_details.status = 2;
            break;

            case 3: //resubmission
                //document_details.isDone = false;
                document_details.status = 3; 
        }//end switch
        
        this.apollo
        .mutate({
            mutation: gql`
             mutation updatePartnerDocuments($partnerId:PartnerHashedId!, $documentInput: DocumentInput!) {
                updatePartnerDocuments(partnerId:$partnerId, documentInput:$documentInput) {
                    partnerDocumentId
                    partnerId
                    type
                    link
                    idNumber
                    firstName
                    middleName
                    lastName
                    address
                    expirationDate
                    gender
                    birthdate
                    status
                    dateApprove
                    dateUpdated
                    dateCreated
                    isDone
                }
            }
            `,
            variables:{
                "partnerId": rider_id,
                "documentInput": document_details
            }
        }).subscribe((res: any) => {
            if(res.data['updatePartnerDocuments'].length != 0){
                let completeInstance = res.data['updatePartnerDocuments'];

                this.detailViewdDocuments = this.detailViewdDocuments.map(doc => {
                    console.log('papasok here');
                    if (doc.partnerDocumentId === document_details.partnerDocumentId) {
                        console.log('pumasoik here');
                        return { ...doc, status: completeInstance.status, isDone: completeInstance.isDone };
                    }
                    return doc;
                });

                
                this.modalService.open(modal,{ size: 'md',  backdrop: 'static', keyboard: false});
            }//end if
        });

        /* this.dasher.tryValidateDocument(rider_id, document_details).subscribe((res) => {
            //updates document validity in frontend
            
            this.detailViewdDocuments.map(doc => {
                if (doc.partnerDocumentId === document_details.partnerDocumentId) {
                    return { ...doc, status: document_details.status };
                }
                return doc;
            });
            this.modalService.open(modal,{ size: 'md',  backdrop: 'static', keyboard: false});
        }); */
    }//end fn

    verifyPartner(modal:TemplateRef<any>, rider_id: string){
        this.dasher.tryVerifyPartner(rider_id).subscribe((res) => {
            this.detailViewed['isApprove'] = true;
            const index_orig = this.riderList.findIndex((x) => x.partnerId === rider_id);
            const index_filtered = this.riderList.findIndex((x) => x.partnerId === rider_id);

            if (index_orig !== -1) {
                // Replace the found item with the new document
                this.riderList[index_orig].isApprove = true;
            }//end if
            
            if (index_orig !== -1) {
                // Replace the found item with the new document
                this.riderList[index_filtered].isApprove = true;
            }//end if
            
            this.modalService.open(modal,{ size: 'md' });
        });
    }//end fn

    viewDocument(modal: TemplateRef<any>, docInfo: any){
        this.currentSelectedDocument = docInfo.type;
        this.selectedDocInfo = cloneDeepWith(docInfo, (value) => {
            if (value && (value.__typename || value.isDone)) {
              const { __typename, isDone, ...rest } = value;
              return rest;
            }
          });

        this.selectedDocInfo = cloneDeep(this.selectedDocInfo);
        
        console.log(this.selectedDocInfo);
        
        Object.keys(this.formDocumentType).forEach((x) => {
            switch(x){
                case 'selfie': case 'tnvs_certificate': case 'nbi_certificate': case 'gcash_settlement': case 'spa_letter': 
                case 'drug_test_certificate': case 'driving_track_certificate': case 'no_modification_certification':
                case 'id': case 'medical_certificate': case 'truck_permit':
                    this.formDocumentType[x] = false;
                break;
    
                case 'drivers_license': case 'vehicle_registration': case 'vehicle_photo': case 'insurance':
                    Object.keys(this.formDocumentType[x]).forEach((y) => {
                        this.formDocumentType[x][y] = false;
                    });
                break;
            }//end switchj
        });
        
        switch(docInfo.type){
            case 'selfie': case 'tnvs_certificate': case 'nbi_certificate': case 'gcash_settlement': case 'spa_letter': 
            case 'drug_test_certificate': case 'driving_track_certificate': case 'no_modification_certification':
            case 'id': case 'medical_certificate': case 'truck_permit':
                this.formDocumentType[docInfo.type] = true;
            break;

            case 'drivers_license': case 'vehicle_registration': case 'vehicle_photo': case 'insurance':
                this.formDocumentType[docInfo.type][docInfo.subType] = true;
            break;
        }//end switchj

        this.modalService.open(modal,{ size: 'lg' });
    }//end fn

    showConfirmModal(modal: TemplateRef<any>, confirmModalIndex: string){
        Object.keys(this.confirmSelection).forEach((x) => {
            this.confirmSelection[x] = false;
        });

        this.confirmSelection[confirmModalIndex] = true;
        this.modalService.open(modal,{ size: 'md',  backdrop: 'static', keyboard: false });
    }//fn

    closeAllModals(){
        Object.keys(this.confirmSelection).forEach((x) => {
            this.confirmSelection[x] = false;
        });

        this.modalService.dismissAll();
    }//end fn

    enableEditForm(){
        this.enableEdit = true;
    }//end fn

    disableEditForm(){
        this.enableEdit = false;
    }//end fn

    updateThisDetails(){
        this.dasher.updatePartner(this.detailViewed['partnerId'], this.detailViewed).subscribe((res) => {
            alert(res.message);
            this.disableEditForm();
        });
    }//end fn

    viewLargerModal(modalLarger: TemplateRef<any>){
        this.modalService.open(modalLarger, { size: 'lg',  backdrop: 'static'});
    }//endd fn

    manualTopUp(partnerId: string, modal: TemplateRef<any>){
        this.topUpForm['partnerId'] = partnerId;
        this.modalService.open(modal, { size: 'md',  backdrop: 'static'});
    }//end fn

    confirmManualTopUp(modal: TemplateRef<any>){
        this.modalService.open(modal, { size: 'md',  backdrop: 'static'});
    }//end fn

    processTopUp(successModal: TemplateRef<any>){ 
        this.apollo
          .mutate({
            mutation: gql`
                mutation topUpManual($partnerId: PartnerHashedId!, $amount:Float!) {
                    topUpManual(partnerId:$partnerId, amount:$amount) {
                        message
                    }
                }
            `,
            variables:{
                "partnerId": this.topUpForm['partnerId'],
                "amount":  parseFloat(this.topUpForm['amt'])
            }
          }).subscribe((res: any) => {
            if(res.length != 0){
              let manualRes = res.data['topUpManual'];
              
              this.topUpForm['amt'] = 0;
              this.topUpForm['partnerId'] = '';
                
              this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false });
            }//end if
          });
      }//end processing

    //function triggers only on selected partner only (detail page only)
    checkIfAvailableforApproval(){
        let notDoneDocuments = 0;
        this.detailViewdDocuments.forEach((doc: Document) => {
            if(doc.type != "tnvs_certificate"){
                if(doc.status != 1){
                    notDoneDocuments ++;
                }//end if
            }//end if
        });
        
        if(notDoneDocuments != 0){
            return false;
        }else{
            return true;
        }//end if
    }//end fn

    /*------------------------------------------
    --------------------------------------------
    moveMap()
    --------------------------------------------
    --------------------------------------------*/
    moveMap(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.center = (event.latLng.toJSON());
    }

    /*------------------------------------------
    --------------------------------------------
    move()
    --------------------------------------------
    --------------------------------------------*/
    move(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.display = event.latLng.toJSON();
    }


    fetchWithdrawalRequests(status: string, partnerId: string, dateStart: string, dateEnd: string, action: string = '') {
        var fromPage = 1;
    
        if(action != ''){
            fromPage = this.currentPageWithdrawals;
            switch(action){
                case 'next':
                    this.currentPageWithdrawals++
                break;
      
                case 'prev':
                    if(this.currentPageWithdrawals != 1){
                        this.currentPageWithdrawals--
                    }//end if
                break;
            }//end swithc    
        }//end if
    
        this.apollo
          .watchQuery({
            fetchPolicy: 'network-only',
            query: gql`
              query withdrawalRequests(
                $page: Int,
                $status: WithdrawalRequestStatusEnum!
                $withdrawalWalletFilterInput: WithdrawalWalletFilterInput
                $dateFilterInput: WithdrawalWalletDateFilterInput!
              ) {
                withdrawalRequests(
                  page:$page,
                  status: $status
                  withdrawalWalletFilterInput: $withdrawalWalletFilterInput
                  dateFilterInput: $dateFilterInput
                ) {
                  partnerWalletRequestId
                  bankCode
                  bankName
                  accountNumber
                  accountName
                  amount
                  remarks
                  status
                  dateApproved
                  dateCancelled
                  dateUpdated
                  dateCreated
                  partner {
                    partnerId
                    firstName
                    lastName
                    email
                    vehicleType
                  }
                }
              }
            `,
            variables: {
                "status": status,
                "page": this.currentPageWithdrawals,
                "withdrawalWalletFilterInput":{
                    "partnerId": partnerId
                },
                "dateFilterInput":{
                  "dateStart": dateStart,
                  "dateEnd": dateEnd
                }
            }
          }).valueChanges.subscribe((res: any) => {
            if(res.length != 0){
              this.withdrawalList = res.data['withdrawalRequests'];
              this.currentSelectedPageListWithdrawals = this.withdrawalList;
              console.log(this.withdrawalList);
            }//end if
          });
      } 


      fetchTopupRequests(status: string, source: string, topupRequestID: any, partnerId: string, dateStart: string, dateEnd: string, action: string = '') {
        var fromPage = 1;
    
        if(action != ''){
            fromPage = this.currentPageTopups;
            switch(action){
                case 'next':
                    this.currentPageTopups++
                break;
      
                case 'prev':
                    if(this.currentPageTopups != 1){
                        this.currentPageTopups--
                    }//end if
                break;
            }//end swithc    
        }//end if
    
        this.apollo
          .watchQuery({
            fetchPolicy: 'network-only',
            query: gql`
              query topupRequestList(
                $page: Int
                $status: PartnerTopupStatusEnum!
                $source: PartnerTopupSourceEnum!
                $partnerFilter: TopupPartnerFilterInput
                $dateFilter: TopupDateFilterInput
                $topUpRequestId: TopupHashedId
            ) {
                topupRequestList(
                    page: $page
                    status: $status
                    source: $source
                    partnerFilter: $partnerFilter
                    dateFilter: $dateFilter
                    topUpRequestId: $topUpRequestId
                ) {
                    partnerTopupRequestId
                    source
                    amount
                    status
                    paymentId
                    dateCreated
                    dateUpdated
                    partner {
                        partnerId
                        firstName
                        lastName
                        mobile
                    }
                }
            }
            `,
            variables: {
                "status": "all",
                "source":"all",
                "page": this.currentPageTopups,
                "topUpRequestId": null,
                "partnerFilter":{
                  "partnerId": partnerId
                },
                "dateFilterInput":{
                  "dateStart": dateStart,
                  "dateEnd": dateEnd
                }
              }
          }).valueChanges.subscribe((res: any) => {
            if(res.length != 0){
              this.topUpList = res.data['topupRequestList'];
              this.currentSelectedPageListTopups = this.topUpList;
              console.log(this.topUpList);
            }//end if
          });
      } 


    showAddDocumentModal(modal: TemplateRef<any>){
        this.modalService.open(modal,{ size: 'md',  backdrop: 'static', keyboard: false });
    }//fn

    onFileSelected(event: any): void {
        const file: File = event.target.files[0];
        if (file) {
          const reader = new FileReader(); 
          reader.onload = (e: any) => {
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(file);
          this.documentPhoto = file;
        }//end
    }//end fn

    createDocumentData(confirmModal: TemplateRef <any>){
        var errors: number = 0;
        this.documentFormNew['subType'] = this.documentFormNew['type'];
        this.documentFormNew['partnerId'] = this.detailViewed['partnerId'];
        
        if(this.detailViewdDocuments.filter(x => x.type == this.documentFormNew['type']).length !=0){
            alert('Document already exists');
            return;   
        }
        
        Object.keys(this.documentFormNew).forEach((x) => {
            if(x != 'link'){
                if(this.documentFormNew[x] == ''){
                    //console.log(x);
                    errors ++;
                }//end if
            }//end if
        });

        if(errors != 0){
            alert('Please enter all required fields');
        }else{
            this.modalService.open(confirmModal, { size: 'md',  backdrop: 'static', keyboard: false });
        }//end if
    }//end fn

    proceedCreateDocumentData(successModal: TemplateRef<any>){
        const formData:any = new FormData();      
        if (this.documentPhoto) {
          formData.append('file', this.documentPhoto, this.documentPhoto.name);
        }
    
        this.dasher.uploadPhoto(formData, 'partner_documents').subscribe((res: any) => {
        this.documentFormNew['link'] = res.photo;
          
        //ENTER GQL HERE
        this.apollo
        .mutate({
            mutation: gql`
            mutation createPartnerDocument($createPartnerDocumentInput:CreatePartnerDocumentInput!) {
                createPartnerDocument(createPartnerDocumentInput:$createPartnerDocumentInput) {
                    partnerDocumentId
                    idNumber
                    firstName
                    middleName
                    lastName
                    isDone
                    link
                    status
                    type
                    subType
                    address
                    dateCreated
                    expirationDate
                    birthdate
                    dateApprove
                    gender
                }
            }
            `,
            variables:{
                "createPartnerDocumentInput": this.documentFormNew
            }
        }).subscribe((res: any) => {
            this.documentFormNew = {
                partnerId: '',
                type: '',
                subType: '',
                link: '',
                status: 'pending'
            };

            if(res.data['createPartnerDocument'].length != 0){
                let completeInstance = res.data['createPartnerDocument'];
                
                console.log(completeInstance);
                this.detailViewdDocuments.push(completeInstance);

                
                this.modalService.open(successModal,{ size: 'md',  backdrop: 'static', keyboard: false});
            }//end if
        });
        });
    }//end fn
    
    confirmRemoveDoc(doc: Document, confirmModal: TemplateRef<any>){
        this.documentForRemoval = doc;
        this.confirmSelection['remove_doc'] = true;
        this.modalService.open(confirmModal,{ size: 'md',  backdrop: 'static', keyboard: false});
        console.log(this.documentForRemoval);
    }

    removeDocument(doc: Document, successModal: TemplateRef<any>){
        this.apollo
        .mutate({
            mutation: gql`
                mutation deletePartnerDocument($partnerDocumentId:HashedId!) {
                    deletePartnerDocument(partnerDocumentId:$partnerDocumentId) {
                        message
                    }
                }
            `,
            variables:{
                "partnerDocumentId": doc.partnerDocumentId
            }
        }).subscribe((res: any) => {
            if(res.data['deletePartnerDocument'].length != 0){
                let completeInstance = res.data['deletePartnerDocument'];
                
                this.detailViewdDocuments = this.detailViewdDocuments.filter((x) => x.partnerDocumentId != doc.partnerDocumentId);
                this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false});
            }//end if
        });
    }
}//end file