import { Component, inject, TemplateRef } from '@angular/core';
import { NavComponent } from '../navigation/nav.component';
import { HeaderComponent } from '../header/header.component';
import { RouterModule } from '@angular/router';
import { DashboardService } from '@services/dashboard.service';
import { LocalService } from '@services/local.service';
import { Apollo, gql } from 'apollo-angular';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TopupRequest } from '@interfaces/models.interface';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { MomentModule } from 'ngx-moment';

@Component({
  selector: 'app-dashboard-topup',
  standalone: true,
  imports: [NavComponent, HeaderComponent, RouterModule, CommonModule, FormsModule, MomentModule],
  templateUrl: './dashboard-topup.component.html',
  styleUrl: './dashboard-topup.component.css'
})
export class DashboardTopupComponent {
  today = new Date();
  private offcanvasService = inject(NgbOffcanvas);
  dateToday: Date;
  dateSevenDaysAgo: Date;

  constructor(
    private dasher: DashboardService,
    private localservice: LocalService,
    private apollo: Apollo
    
  ) { 
      this.dateToday = new Date();
      this.dateSevenDaysAgo = new Date(this.today);
      this.dateSevenDaysAgo.setDate(this.today.getDate() - 7);
  }

  public searchKeywordTopup: string = '';
  public currentPageTopups: number = 1;
  public topupdetailViewed: Record<string, any> = {};
  public topUpList: TopupRequest[] = [];
  public currentSelectedPageListTopups: TopupRequest[] = [];
  public dayViewtopUps: Record<string, any> = {
      days_7: true,
      days_30: false,
  };

  public disableActionBtnsTopups: any = {
    next: false,
    prev: false,
 };

  ngOnInit(): void {
    this.fetchTopupRequests('all', '', null, '', this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday));
  }


  nextPrevTopups(action: string){
      switch(action){
          case 'next':
              this.fetchTopupRequests('all', '', null, '', this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday), 'next');
          break;

          case 'prev':
              this.fetchTopupRequests('all', '', null, '', this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday), 'prev');
          break;
      }//end switch
  }//end fn

  fetchTopupRequests(status: string, source: string, topupRequestID: any, partnerId: string, dateStart: string, dateEnd: string, action: string = '') {
    var fromPage = 1;

    if(action != ''){
        fromPage = this.currentPageTopups;
        switch(action){
            case 'next':
                this.currentPageTopups++
            break;
  
            case 'prev':
                if(this.currentPageTopups != 1){
                    this.currentPageTopups--
                }//end if
            break;
        }//end swithc    
    }//end if

    this.apollo
      .watchQuery({
        fetchPolicy: 'network-only',
        query: gql`
          query topupRequestList(
            $page: Int
            $status: PartnerTopupStatusEnum!
            $source: PartnerTopupSourceEnum!
            $partnerFilter: TopupPartnerFilterInput
            $dateFilter: TopupDateFilterInput
            $topUpRequestId: TopupHashedId
        ) {
            topupRequestList(
                page: $page
                status: $status
                source: $source
                partnerFilter: $partnerFilter
                dateFilter: $dateFilter
                topUpRequestId: $topUpRequestId
            ) {
                partnerTopupRequestId
                source
                amount
                status
                paymentId
                dateCreated
                dateUpdated
                partner {
                    partnerId
                    firstName
                    lastName
                    mobile
                }
            }
        }
        `,
        variables: {
            "status": "all",
            "source":"all",
            "page": this.currentPageTopups,
            "topUpRequestId": null,
            /* "partnerFilter":{
              "partnerId": "RYQ59XNX"
            },
            "dateFilter":{
              "dateStart":"2024-09-10 00:00:00",
              "dateEnd":"2024-09-17 23:59:59"
            } */
          }
      }).valueChanges.subscribe((res: any) => {
        if(res.data['topupRequestList'].length != 0){
          this.topUpList = res.data['topupRequestList'];
          this.currentSelectedPageListTopups = this.topUpList;
          console.log(this.topUpList);
        }else{
            this.currentPageTopups = fromPage;
        }//end if
      });
  }
  
  setDaysBetween(datesBetween: number){
      this.dateToday = new Date();
      this.dateSevenDaysAgo = new Date(this.today);
      this.dateSevenDaysAgo.setDate(this.today.getDate() - datesBetween);
  }//end fn

  formatDate(date: Date): string {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  viewTopupDetails(details: TopupRequest, offcanvas: TemplateRef<any>){
      this.topupdetailViewed = details;
      this.offcanvasService.open(offcanvas, { position: 'end' });
  }//end fn

  fetchWTopUpsByDateRange(daysBetweenToday: number){
      this.setDaysBetween(daysBetweenToday);
      if(daysBetweenToday == 7){
          this.dayViewtopUps['days_7'] = true;
          this.dayViewtopUps['days_30'] = false;
      }else{
          this.dayViewtopUps['days_7'] = false;
          this.dayViewtopUps['days_30'] = true;
      }


      this.fetchTopupRequests('all', '', null, '', this.formatDate(this.dateSevenDaysAgo), this.formatDate(this.dateToday));
  }//end fn

  searchTopUpData(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      this.searchKeywordTopup = inputElement.value;

      if (this.searchKeywordTopup.trim() === '') {
          console.log('Input is empty');
          this.currentSelectedPageListTopups = this.topUpList;
      }else{
          this.currentSelectedPageListTopups =  this.topUpList.filter(object =>
              Object.values(object).some(value => 
                  (value ? value.toString().toLowerCase() : '').includes(this.searchKeywordTopup.toLowerCase())
              )
          );
      }//end if
  }//end nfn
}
