import { Component, TemplateRef, inject } from '@angular/core';
//SUB COMPONENTS
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { Booking, Consumer } from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import {RouterModule} from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { Apollo, gql } from 'apollo-angular';

//FOR THE MAP
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMap} from '@angular/google-maps';
import { ExcelService } from '@services/excel.service';

@Component({
  selector: 'app-dashboard-users',
  standalone: true,
  imports: [FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, RouterModule, MomentModule, GoogleMapsModule, GoogleMap],
  templateUrl: './dashboard-users.component.html',
  styleUrl: './dashboard-users.component.css'
})

export class DashboardUsersComponent {
  private offcanvasService = inject(NgbOffcanvas);
  
  constructor(
      private dasher: DashboardService,
      private localservice: LocalService,
      private excelService: ExcelService,
      private apollo: Apollo
  ) { }

  public consumerList: Consumer[] = [];
  public bookingList: Booking[] = [];

  public searchKeyword: string = '';
  public currentSelectedPageList: Consumer[] = [];
  public booking_currentSelectedPageList: Booking[] = [];
  public maxPages: number = 0;
  public disableActionBtns: any = {
      next: false,
      prev: false,
  };
  public riderPages: number[] = [];
  public currentPage: number = 1;
  public pageSize: number = 15;

  public bookingPages: number[] = [];
  public bookingcurrentPage: number = 1;
  public bookingpageSize: number = 15;
  
  public detailViewed: Record<string, any> = {};
  public bookingdetailViewed: Record<string, any> = {};

  public showDetails: boolean = false;
  public consumerTabs: Record<string, any> = {
    all: true,
    w_booking: false,
    in_transit: false,
    completed: false,
    n_booking: false,
  }

  public consumerTabs2: Record<string, any> = {
    info: true,
    bookings: false,
    rewards: false,
  }

  public userOverview: Record<string, any> = {
    totalRegistered: 0,
    totalVerified: 0,
    totalUnVerified: 0,
    rideHailingFourWheelsCount: 0,
    rideHailingTwoWheelsCount: 0,
    expressFourWheelsCount: 0,
    expressTwoWheelsCount: 0,
  };

  //FOR THE MAP (BOOKING DETAILS)
  polyLinePath: google.maps.LatLng[] = [];
  display: any;
  center: google.maps.LatLngLiteral = {
      lat: 22.2736308,
      lng: 70.7512555
  };
  zoom = 15;
  polyLineOptions: google.maps.PolylineOptions = {
    strokeColor: '#D81960',
    strokeOpacity: 1.0,
    strokeWeight: 5,
    clickable: false,
    editable: false,
    visible: true,
    geodesic: true, // Smoothen the edges
  };
    
  public sortOrder: Record<string, any> = {
    asc: true,
    desc: false,
  }

    ngOnInit(): void {
        this.fetchConsumers();
    }

    fetchConsumers(action: string = ''){
        var fromPage = 1;

        if(action != ''){
            fromPage = this.currentPage;
            switch(action){
                case 'next':
                    this.currentPage++
                break;
      
                case 'prev':
                    if(this.currentPage != 1){
                        this.currentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if
        
        this.dasher.tryFetchConsumers(this.currentPage).subscribe((res: any) => {
            if(res.length != 0){
                this.consumerList = [];
                
                res.forEach((consumer_instance : any) => {
                    let i = consumer_instance;
                    this.consumerList.push(i);
                });

                this.currentSelectedPageList = this.consumerList;

                this.dasher.tryFetchUserSummary().subscribe((res: any) => {
                    this.userOverview = res;
                });
            }else{
                this.currentPage = fromPage;
            }//end if//end fn
        });
    }//end fn

    fetchOverview(){
        this.apollo
        .watchQuery({
            fetchPolicy: 'network-only',
            query: gql`
            query usersOverview {
                usersOverview {
                    totalRegistered
                    totalVerified
                    totalUnVerified
                    mototaxiCount
                    okrideCount
                    expressMotorcycleCount
                    expressRideCount
                }
            }           
            `,
            variables: {
                
            }
        }).valueChanges.subscribe((res: any) => {
            if(res.data['usersOverview'].length != 0){
                var completeInstance = res.data['usersOverview'];
                Object.keys(this.userOverview).forEach((x) => {
                    this.userOverview[x] = completeInstance[x];
                });
            }//end if
        });
    }//end fn
    
    fetchUserSelectedBookings(customerID:string, action: string = ''){
        var fromPage = 1;

        if(action != ''){
            fromPage = this.currentPage;
            switch(action){
                case 'next':
                    this.currentPage++
                break;
      
                case 'prev':
                    if(this.currentPage != 1){
                        this.currentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if
        
        this.bookingList = [];
        this.booking_currentSelectedPageList = [];

        this.dasher.tryFetchCustomerBookings(customerID, this.bookingcurrentPage).subscribe((res: any) => {
            if(res.length != 0){
                res.forEach((booking_instance : any) => {
                    let i = booking_instance;
                    this.bookingList.push(i);
                });

                this.booking_currentSelectedPageList = this.bookingList;
            }else{
                this.bookingcurrentPage = fromPage;
            }//end if//end fn

        });
    }//end fn

    nextPrev(action: string){
        /* var tabActive = '';
        Object.keys(this.couponTabs).forEach((x) => {
            if(this.couponTabs[x]){
                tabActive = x;
            }//end if
        }); */

        switch(action){
            case 'next':
                this.fetchConsumers('next');
            break;
  
            case 'prev':
                this.fetchConsumers('prev');
            break;
        }//end swithc
    }//end fn


    searchData(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.searchKeyword = inputElement.value;
  
        if (this.searchKeyword.trim() === '') {
            console.log('Input is empty');
            this.currentSelectedPageList = this.consumerList
        }else{
            this.currentSelectedPageList =  this.consumerList.filter(object =>
                Object.values(object).some(value => 
                    (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
                )
            );
        }//end if
    }//end nfn

    viewDetails(details: Consumer){
        this.showDetails = true;
        this.detailViewed = details;
        console.log(details);

        this.fetchUserSelectedBookings(details.userId);
    }//end fn

    viewBookingDetails(details: Booking, offcanvas: TemplateRef<any>){
        this.showDetails = true;
        this.bookingdetailViewed = details;
        console.log(this.bookingdetailViewed);
        if(details.orderRoute != ''){
            this.polyLinePath = google.maps.geometry.encoding.decodePath(details.orderRoute);
            // Calculate the bounds of the polyline
            const bounds = new google.maps.LatLngBounds();
                this.polyLinePath.forEach((coordinate: google.maps.LatLng) => {
                bounds.extend(coordinate);
            });
        
            // Center the map to the center of the polyline bounds
            this.center = bounds.getCenter().toJSON();
        }
        this.offcanvasService.open(offcanvas, { position: 'end' });
    }//end fn

    returnToList(){
        this.showDetails = false;
        this.detailViewed = {};

        this.currentPage = 1;
        this.currentSelectedPageList = this.consumerList;
    }//end fn
    
    filterConsumers(tabindex: string){
        this.setSelectedPageDataBasedOnStatus(tabindex);
    }//end

    setSelectedPageDataBasedOnStatus(tabindex: string){
        Object.keys(this.consumerTabs).forEach((x) => {
            this.consumerTabs[x] = false;
        });

        this.consumerTabs[tabindex] = true;
        /* switch (tabindex) {
            case 'all':
                this.currentPage = 1;
                this.filteredRiderList = this.riderList;
            break;
        
            case 'for_verification':
                this.currentPage = 1;
                this.filteredRiderList = this.riderList.filter((x) => (x.isApprove == false))
            break;

            case 'verified':
                this.currentPage = 1;
                this.filteredRiderList = this.riderList.filter((x) => (x.isApprove == true))
            break;
        }//end if */

        /* this.maxPages = this.localservice.getTotalPages(this.filteredRiderList, this.pageSize);
        this.riderPages = Array.from({ length: this.maxPages}, (_, i) => i + 1);
        this.currentSelectedPageList = this.localservice.paginateData(this.filteredRiderList, this.currentPage, this.pageSize)
        
        if(this.maxPages == 1){
            this.disableActionBtns.next = true;
            this.disableActionBtns.prev = true;
        }else{
            this.disableActionBtns.next = false;
            this.disableActionBtns.prev = true;
        }//end if     */
    }//end fn

    detailConsumerTabActive(tabindex: string){
        Object.keys(this.consumerTabs2).forEach((x) => {
            this.consumerTabs2[x] = false;
        });

        this.consumerTabs2[tabindex] = true;
    }//end fn

    /*------------------------------------------
    --------------------------------------------
    moveMap()
    --------------------------------------------
    --------------------------------------------*/
    moveMap(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.center = (event.latLng.toJSON());
    }

    /*------------------------------------------
    --------------------------------------------
    move()
    --------------------------------------------
    --------------------------------------------*/
    move(event: google.maps.MapMouseEvent) {
        if (event.latLng != null) this.display = event.latLng.toJSON();
    }

    setSortOrder(order: string){
        Object.keys(this.sortOrder).forEach((x) => {
            this.sortOrder[x] = false;
        });

        this.sortOrder[order] = true;
    }//end fn
}
