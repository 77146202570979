<div class="wrap">
    <div class="login-grid">
        <div class="logo">
            <img src="../../../../assets/okito/images/okito-logo.svg">
        </div>

        <!-- LOGIN FORM -->
        <div class="login" *ngIf="formtoShow == 'login'">
            <form class="user" (ngSubmit)="attempLogin(this.userForm)">
            <h3>Sign In</h3>
            <div class="login-grp"> <!-- for error -->
                <label>Email Address</label>
                <div class="input-icon-grp">
                    <img src="../../../../assets/okito/images/icons/email.svg" class="input-icon">
                    <input #emailLogin [(ngModel)]="this.userForm.email" type="email" name="email" placeholder="Email Address" class="input-text">
                </div>
            </div>

            <div class="login-grp">
                <div class="label-grp">
                    <label>Password</label>
                    <span (click)="formtoShowUpdate('forgot_password')" style="color:red;cursor: pointer;">Forgot your Password?</span>
                </div>
                <div class="input-icon-grp">
                    <img src="../../../../assets/okito/images/icons/password.svg" class="input-icon">
                    <input [(ngModel)]="this.userForm.password" [type]="isPasswordShown['txt-pass-1']" name="password" placeholder="Password" class="input-text" (keyup.enter)="attempLogin(this.userForm)">
                    <span class="eye-icon" style="cursor: pointer;" (click)="togglePasswordShow('txt-pass-1')">
                        <img src="../../../../assets/okito/images/icons/eye.svg">
                    </span>
                </div>
            </div>
            <div class="login-grp">
                <button type="submit">Login</button>
            </div>
            
            <!-- <div class="alert alert-danger" role="alert">
                Error: Lorem ipsum dolor sit
            </div> -->
            </form>
        </div>


        <!-- OTP VERIFY FORM -->
        <div class="login" *ngIf="formtoShow == 'verify_otp'">
            <h4>Verify</h4>
            <p>
                The verification code (OTP) was sent to your email address. Please enter the code.
                <!-- <br>
                <div *ngIf="this.token$ | async as token">
                    Token: {{ token }}
                </div> -->
            </p>
            <div class="login-grp">
                <input #otpLogin [(ngModel)]="this.otp_code" type="text" name="otp_code" placeholder="Enter OTP" class="input-text" (keyup.enter)="verifyOTP()">
            </div>
            <div class="login-grp">
                <button (click)="verifyOTP()">Verify OTP</button>
            </div>
            <div style="text-align: center;">
                <span class="login-link">Didn't receive an OTP? <span style="cursor: pointer;color:red;"
                    (click)="resendAuthOTP()">Resend</span></span>
            </div>
        </div>


        <!-- FIRST TIME LOGIN -->
        <div class="login" *ngIf="formtoShow == 'verify_email'">
            <div class="update-success">
                <img src="../../../../assets/okito/images/verify.svg">
                <h2>Verify your email</h2>
                <p>We've sent a link to your email address: <span>{{ this.user_email }}</span></p>
                <div class="login-grp">
                    <button (click)="formtoShow = 'login'" >Back to Login</button>
                </div>
                <span class="login-link">Didn't receive an email? <span style="cursor: pointer;color:red;" (click)="resentEmailVlink()" >Resend</span></span>
            </div>
        </div>

        <!-- PASSWORD CHANGE SUCCESS -->
        <div class="login" *ngIf="formtoShow == 'password_reset_success'">
            <div class="update-success">
                <img src="../../../../assets/okito/images/password-success.svg">
                <h2 style="margin-bottom: 1em;">Password reset successful</h2>
                <span>Your password is updated. Login again using new password.</span>
                <div class="login-grp" style="margin-top: 1.5em;">
                    <button (click)="formtoShow = 'login'" >Proceed to login</button>
                </div>
            </div>
        </div>

        <!-- ENTER EMAIL FORGOT PASSWORD-->
        <div class="login" *ngIf="formtoShow == 'forgot_password'">
            <h3>Reset Password</h3>
            <div class="login-grp">
                <label>Enter your Email</label>
                <div class="input-text-grp">
                    <input (keyup.enter)="resetPassword()" #emailForgotPass [(ngModel)]="this.forgot_passemail" type="text" name="" placeholder="Enter your email" class="input-text">
                </div>
            </div>
            <p>
                We will send a verification code to your email for password reset
            </p>
            <div class="login-grp">
                <button (click)="resetPassword()">Submit</button>
            </div>

            <div style="text-align: center; margin-top: 2em;">
                <span (click)="formtoShow = 'login'" style="color:red;cursor: pointer;">Return to Login</span>
            </div>
        </div>

        <div class="login" *ngIf="formtoShow == 'forgot_password_otp_verify'">
            <h3>Verify OTP</h3>
            <div class="login-grp">
                <label>Verify OTP</label>
                <div class="input-text-grp">
                    <input (keyup.enter)="verifyForgotPasswordOTP()" #otpForgotPass [(ngModel)]="this.forgot_pass_otp" type="text" name="" placeholder="Enter OTP"
                        class="input-text">
                </div>
            </div>
            <p>
                Enter the OTP sent to your email address <span>{{ this.forgot_passemail }}</span>
            </p>
            <div class="login-grp">
                <button (click)="verifyForgotPasswordOTP()">Submit</button>
            </div>
            <div style="text-align: center;">
                <span class="login-link">Didn't receive an OTP? <span style="cursor: pointer;color:red;"
                    (click)="resetPassword()">Resend</span></span>
            </div>
        
            <div style="text-align: center; margin-top: 2em;">
                <span (click)="formtoShow = 'login'" style="color:red;cursor: pointer;">Return to Login</span>
            </div>
        </div>

        <div class="login" *ngIf="formtoShow == 'forgot_password_update'">
            <h3>Update Password</h3>
            <div class="login-grp">
                <label>New Password</label>
                <div class="input-text-grp">
                    <div class="input-icon-grp">
                        <img src="../../../../assets/okito/images/icons/password.svg" class="input-icon">
                        <input (keyup.enter)="requestPasswordUpdate()" #newpassForgotPass [(ngModel)]="this.forgot_pass_newpassword" [type]="isPasswordShown['txt-pass-5']" name="password"
                            placeholder="Password" class="input-text">
                        <span class="eye-icon" style="cursor: pointer;" (click)="togglePasswordShow('txt-pass-5')">
                            <img src="../../../../assets/okito/images/icons/eye.svg">
                        </span>
                    </div>
                </div>
            </div>
            <p>
                Please enter your new password
            </p>
            <div class="login-grp">
                <button (click)="requestPasswordUpdate()">Submit</button>
            </div>
        
            <div style="text-align: center; margin-top: 2em;">
                <span (click)="formtoShow = 'login'" style="color:red;cursor: pointer;">Return to Login</span>
            </div>
        </div>


        <!-- CHANGE PASSWORD -->
        <!-- <div class="login" *ngIf="formtoShow == 'change_password'">
            <h3>Change Password</h3>
            <div class="login-grp">
                <label>Temporary Password</label>
                <div class="input-text-grp">
                    <input  [(ngModel)]="this.forgot_passform.temp_password" [type]="isPasswordShown['txt-pass-2']" name="" placeholder="Enter Temporary Password" class="input-text">
                    <span class="eye-icon" style="cursor: pointer;" (click)="togglePasswordShow('txt-pass-2')">
                        <img src="../../../../assets/okito/images/icons/eye.svg">
                    </span>
                </div>
            </div>
            <div class="login-grp">
                <label>New Password</label>
                <div class="input-text-grp">
                    <input  [(ngModel)]="this.forgot_passform.new_password" [type]="isPasswordShown['txt-pass-3']" name="" placeholder="Enter Temporary Password" class="input-text">
                    <span class="eye-icon" style="cursor: pointer;" (click)="togglePasswordShow('txt-pass-3')">
                        <img src="../../../../assets/okito/images/icons/eye.svg">
                    </span>
                </div>
            </div>
            <div class="login-grp">
                <label>Confirm Password</label>
                <div class="input-text-grp">
                    <input [(ngModel)]="this.forgot_passform.confirm_password" [type]="isPasswordShown['txt-pass-4']" name="" placeholder="Enter Temporary Password" class="input-text">
                    <span class="eye-icon" style="cursor: pointer;" (click)="togglePasswordShow('txt-pass-4')">
                        <img src="../../../../assets/okito/images/icons/eye.svg">
                    </span>
                </div>
            </div>
            <p>
                Password must be 8-16 alphanumeric characters long (without spaces), and with at least 1 number
            </p>
            <div class="login-grp">
                <button (click)="updatePassword()">Change Password</button>
            </div>

            <div style="text-align: center; margin-top: 2em;">
                <span (click)="formtoShow = 'login'" style="color:red;cursor: pointer;">Return to Login</span>
            </div>
        </div> -->
    </div>
</div>
