<div class="wrap">
    <app-header></app-header>
    <div class="main-holder">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Transactions</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Transactions</h2>
                </div>
                <hr>
                <div class="list-tabs">
                    <ul>
                        <li [ngClass]="{'active': transTabs['express']}"><a (click)="tabCallFunction('express')" style="cursor: pointer;">Express</a></li>
                        <li [ngClass]="{'active': transTabs['ok_ride']}"><a (click)="tabCallFunction('ok_ride')" style="cursor: pointer;">OK! Ride</a></li>
                        <li [ngClass]="{'active': transTabs['ok_mototaxi']}"><a (click)="tabCallFunction('ok_mototaxi')" style="cursor: pointer;">OK! MotoTaxi</a></li>
                    </ul>
                </div>

                <div class="cards">
                    <div class="row">
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Transactions</small>
                                    <h4>{{ this.transactionList.length }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/commission.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Completed</small>
                                    <h4>{{ completeCount }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/commission.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Cancelled</small>
                                    <h4>{{ cancelledCount }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/earnings.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Ongoing</small>
                                    <h4>{{ ongoingCount }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/payout.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Pending</small>
                                    <h4>{{ pendingCount }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/payout.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-holder">
                    <div class="tools">
                        <div class="list-title">
                            <h4 *ngIf="transTabs['express']">List of Express Transactions</h4>
                            <h4 *ngIf="transTabs['ok_ride']">List of OK Ride Transactions</h4>
                            <h4 *ngIf="transTabs['ok_mototaxi']">List of Motaxi Transactions</h4>
                        </div>
                        <div class="search mg-right-auto">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text"
                                class="text-search" placeholder="Search">
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" (click)="this.downloadReport();">Download</button>
                        </div>
                    </div>
                    <div class="list table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Partner Name</th>
                                    <th>Customer Name</th>
                                    <th>Total Price</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                <tr *ngFor="let trans of this.currentSelectedPageList">
                                    <td>{{ trans.bookingExpressId }}</td>
                                    <td *ngIf="trans.partner != null">{{ trans.partner.firstName }} {{ trans.partner.middleName }} {{
                                        trans.partner.lastName }}</td>
                                    <td *ngIf="trans.partner == null">---</td>
                                    <td *ngIf="trans.user != null">{{ trans.user.name }}</td>
                                    <td *ngIf="trans.user == null">---</td>
                                    <td>{{ trans.totalPrice | currency : 'PHP' }}</td>
                                    <td>{{ trans.status }}</td>
                                    <td>View</td>
                                </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')" [disabled]="this.disableActionBtns.prev"><img
                                    src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <!-- <ol>
                                                                    <li *ngFor="let page of this.riderPages" style="cursor: pointer;" (click)="setPageSelected(page)"><a>{{ page
                                                                            }}</a></li>
                                                        
                                                                </ol> -->
                            <button (click)="nextPrev('next')" [disabled]="this.disableActionBtns.next"><img
                                    src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>