import { gql } from 'apollo-angular';

export const TOPUP_REQUEST_LIST = gql`
  query topupRequestList(
    $page: Int
    $status: PartnerTopupStatusEnum!
    $source: PartnerTopupSourceEnum!
    $partnerFilter: TopupPartnerFilterInput
    $dateFilter: TopupDateFilterInput
    $topUpRequestId: TopupHashedId
  ) {
    topupRequestList(
      page: $page
      status: $status
      source: $source
      partnerFilter: $partnerFilter
      dateFilter: $dateFilter
      topUpRequestId: $topUpRequestId
    ) {
      partnerTopupRequestId
      source
      amount
      status
      paymentId
      dateCreated
      dateUpdated
      partner {
        partnerId
        firstName
        lastName
        mobile
        email
      }
    }
  }
`;

export const TOPUP_OVERVIEW = gql`
  query topUpOverview {
    topUpOverview {
      currentTopUpBalance
      totalTopUpBalance
      totalPartnerTopUpBalance
      totalConvertionTopUpBalance
      totalOthersTopUpBalance
    }
  }
`; 