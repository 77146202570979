import { gql } from 'apollo-angular';

export const CREATE_ADMIN = gql`
    mutation createAdmin($createAdminInput: CreateAdminInput!) {
        createAdmin(createAdminInput: $createAdminInput) {
            adminId
            name
            email
            dateCreated
            status
            isVerify
            isNew
            createdBy
            dateUpdated
        }
    }
`; 