<nav class="nav">
    <div class="nav-grid">
        <ul>
            <li [ngClass]="{'active': this.navArray['home']}"><a style="cursor:pointer;" (click)="navigateMenu('')"><i
                        class="fa fa-dashboard"></i> Overview</a></li>
            <li class="drp-menu"
                [ngClass]="{'active': (this.navArray['admins'] || this.navArray['partners'] || this.navArray['users'])}"
                (click)="openParentMenuFn('users')">
                <a style="cursor:pointer;"><i class="fa fa-user"></i> Users <i class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['users']) || (this.navArray['admins'] || this.navArray['partners'] || this.navArray['users']) ? 'block' : 'none'}">
                    <li [ngClass]="{'active': this.navArray['users']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/users')">Customers</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['partners']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/riders')">Partners</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['admins']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/accounts')">Admins</a>
                    </li>
                </ul>
            </li>
            <li class="drp-menu"
                [ngClass]="{'active': (this.navArray['comcenter_overview'] || this.navArray['comcenter_express'] || this.navArray['comcenter_ride'] || this.navArray['comcenter_motaxi'])}"
                (click)="openParentMenuFn('command_center')">
                <a style="cursor:pointer;"><i class="fa fa-dashboard"></i> Command Center <i class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list"
                    [ngStyle]="{'display': (openParentMenu['command_center']) || (this.navArray['comcenter_overview'] || this.navArray['comcenter_express'] || this.navArray['comcenter_ride'] || this.navArray['comcenter_motaxi']) ? 'block' : 'none'}">
                    <li [ngClass]="{'active': this.navArray['comcenter_overview']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/command-center/overview', true)">Command Center: Overall</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['comcenter_express']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/command-center/express', true)">Command Center: Express</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['comcenter_ride']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/command-center/okride', true)">Command Center: OkRide</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['comcenter_motaxi']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/command-center/mototaxi', true)">Command Center: MotoTaxi</a>
                    </li>
                </ul>
            </li>
            <li class="drp-menu"
                [ngClass]="{'active': (this.navArray['transactions'] || this.navArray['m_top_ups'] || this.navArray['withdrawal_cms_m'] || this.navArray['partner_earnings'])}"
                (click)="openParentMenuFn('transactions')">
                <a style="cursor:pointer;"><i class="fa fa-briefcase"></i> Transactions <i class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['transactions']) || (this.navArray['transactions'] || this.navArray['m_top_ups'] || this.navArray['withdrawal_cms_m'] || this.navArray['partner_earnings']) ? 'block' : 'none'}">
                                <li [ngClass]="{'active': this.navArray['transactions']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/transactions')">Bookings</a>
                                </li>
                                <li [ngClass]="{'active': this.navArray['m_top_ups']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/topups')">Top-ups</a>
                                </li>
                                <li [ngClass]="{'active': this.navArray['withdrawal_cms_m']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/withdrawal-cms')">Withdrawals</a>
                                </li>
                                
                                <!-- <li [ngClass]="{'active': this.navArray['partner_earnings']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/partner-earnings')">Partner Earnings</a>
                                </li> -->
                            </ul>
            </li>
            <li class="drp-menu" [ngClass]="{'active': (this.navArray['coupons_m'] || this.navArray['rewards_cms_m'])}"
                (click)="openParentMenuFn('disc_rewards')">
                <a style="cursor:pointer;"><i class="fa fa-gift"></i> Promos and Rewards <i
                        class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['disc_rewards']) || (this.navArray['coupons_m'] || this.navArray['rewards_cms_m']) ? 'block' : 'none'}">
                                <li [ngClass]="{'active': this.navArray['coupons_m']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/coupons-management')">Coupons</a>
                                </li>
                                <li [ngClass]="{'active': this.navArray['rewards_cms_m']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/rewards-cms')">Rewards</a>
                                </li>
                            </ul>
            </li>
            <li class="drp-menu"
                [ngClass]="{'active': (this.navArray['ads_m'] || this.navArray['vehicle_cms_m'] || this.navArray['location_cms_m'] || this.navArray['surge_cms_m'])}"
                (click)="openParentMenuFn('cms')">
                <a style="cursor:pointer;"><i class="fa fa-cog"></i> CMS <i class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['cms']) || (this.navArray['ads_m'] || this.navArray['vehicle_cms_m'] || this.navArray['location_cms_m'] || this.navArray['surge_cms_m']) ? 'block' : 'none'}">
                                <li [ngClass]="{'active': this.navArray['ads_m']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/ads-management')">Ads Management</a>
                                </li>
                                <li [ngClass]="{'active': this.navArray['vehicle_cms_m']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/vehicle-cms')">Vehicle Management</a>
                                </li>
                                <li [ngClass]="{'active': this.navArray['surge_cms_m']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/surge-cms')">Surge Pricing</a>
                                </li>
                                <!-- <li [ngClass]="{'active': this.navArray['location_cms_m']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/location-cms')">Location Management</a>
                                </li> -->
                            </ul>
            </li>
            <li class="drp-menu" [ngClass]="{'active': (this.navArray['news_and_events_m'] || this.navArray['notification_m'])}"
                (click)="openParentMenuFn('art_notifs')">
                <a style="cursor:pointer;"><i class="fa fa-bullhorn"></i> Articles and Notifications <i
                        class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['art_notifs']) || (this.navArray['news_and_events_m'] || this.navArray['notification_m']) ? 'block' : 'none'}">
                                <li [ngClass]="{'active': this.navArray['news_and_events_m']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/newsevents-management')">News and Events</a></li>
                                <li [ngClass]="{'active': this.navArray['notification_m']}">
                                    <a style="cursor:pointer;" (click)="navigateMenu('admin/notification-management')">Notifications</a>
                                </li>
                            </ul>
            </li>
            <li>
                <a style="cursor: pointer;" (click)="this.accountLogout()">
                    <i class="fa fa-sign-out"></i> Logout
                </a>
            </li>
        </ul>
    </div>
</nav>