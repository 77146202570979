<div class="wrap">
    <app-header></app-header>
    <div class="main-holder" *ngIf="!this.showDetails; else showDetailComponent">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Coupon Management</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Coupon Management</h2>
                </div>
                <hr>
                <div class="cards">
                    <div class="row">
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Active Coupons</small>
                                    <h4>{{ this.couponOverview['totalActive'] }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/coupons.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Redeemed Coupons</small>
                                    <h4>{{ this.couponOverview['totalRedeemed'] }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/verified.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Unused</small>
                                    <h4>{{ this.couponOverview['totalUnused'] }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/unused.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Expired Coupons</small>
                                    <h4>{{ this.couponOverview['totalExpired'] }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/expired.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-holder">
                    <div class="tools">
                        <div class="list-title">
                            <h4>List of Coupons</h4>
                        </div>
                        <div class="search mg-right-auto" style="width:80%;">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                placeholder="Search" style="width:30%;margin-right:10px;">
                            
                                                                    
                                                                   <!--  <div class="btn-group" role="group" aria-label="Sort by.." style="width:15%;">
                                                                        <button  type="button" class="btn btn-primary"><i class="fa fa-sort-amount-asc"></i>&nbsp; Asc</button>
                                                                        <button  type="button" class="btn btn-primary"><i class="fa fa-sort-amount-desc"></i>&nbsp; Desc</button>
                                                                    </div> -->
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" style="margin-right: 1em;" (click)="showCreateCouponForm(createCoupon)">Create Coupon</button>
                        </div>
                    </div>

                    <div class="tools">
                        <div class="search" style="width:80%;">
                            <span style="font-weight:bold;margin-right:5px;" >Filter Service Type:</span>
                            <select (change)="fetchCoupons(this.couponStatusFilter)" class="form-select" style="width:15%;margin-right:20px;" [(ngModel)]="couponFilter['service_type']">
                                <option value="all">All</option>
                                <option value="express">Express</option>
                                <option value="okride">Ok! Ride</option>
                                <option value="mototaxi">Mototaxi</option>
                            </select>
                            <!-- <span style="font-weight:bold;margin-right:5px;">Filter Audience:</span>
                            <select (change)="fetchCoupons(this.couponStatusFilter)" class="form-select" style="width:15%;margin-right:10px;" [(ngModel)]="couponFilter['audience']">
                                <option value="all">All</option>
                                <option value="consumer">Consumer</option>
                                <option value="partner">Partners</option>
                            </select> -->
                        </div>
                    </div>
                    
                    <div class="list-tabs">
                        <ul>
                            <li [ngClass]="{'active': this.couponTabs['all']}"><a (click)="filterDiscountCoupons('all')"
                                    style="cursor: pointer;">All</a></li>
                            <!-- <li [ngClass]="{'active': this.couponTabs['first_order']}"><a (click)="filterDiscountCoupons('first_order')"
                                    style="cursor: pointer;">First Order Discount</a></li> -->
                            <li [ngClass]="{'active': this.couponTabs['fixed_fee']}"><a (click)="filterDiscountCoupons('fixed_fee')"
                                    style="cursor: pointer;">Fixed Fee</a></li>
                            <li [ngClass]="{'active': this.couponTabs['percentage']}"><a (click)="filterDiscountCoupons('percentage')"
                                    style="cursor: pointer;">Percentage</a></li>
                            <li [ngClass]="{'active': this.couponTabs['location']}"><a (click)="filterDiscountCoupons('location')"
                                    style="cursor: pointer;">Location Based</a></li>
                        </ul>
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Title</th>
                                    <th>Subtitle</th>
                                    <th>Date</th>
                                    <th>Type</th>
                                    <th>Service Type</th>
                                    <th>Audience</th>
                                    <th>Qty/Cap</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                    <tr *ngFor="let ad of this.currentSelectedPageList">
                                        <td>{{ ad.couponId }}</td>
                                        <td>{{ ad.title }}</td>
                                        <td>{{ ad.subTitle }}</td>
                                        <td>{{ ad.dateCreated | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</td>
                                        <!-- for type col -->
                                        <td *ngIf="ad.type == 'first_order'">First Order</td>
                                        <td *ngIf="ad.type == 'fixed_fee'">Fixed Fee</td>
                                        <td *ngIf="ad.type == 'percentage'">Percentage</td>
                                        <td *ngIf="ad.type == 'location'">Location</td>
                                        <!-- for service type col -->
                                        <td *ngIf="ad.service == 'express'">Express</td>
                                        <td *ngIf="ad.service == 'okride'">Ok! Ride</td>
                                        <td *ngIf="ad.service == 'mototaxi'">MotoTaxi</td>
                                        <td *ngIf="!ad.service">-</td>
                                        <!-- for audience col -->
                                        <td *ngIf="ad.audience == 'all'">All</td>
                                        <td *ngIf="ad.audience == 'partner'">Partners</td>
                                        <td *ngIf="ad.audience == 'consumer'">Consumer</td>
                                        <td>{{ ad.quantity }}</td>
                                        <td><a (click)="viewDetails(ad)" style="cursor: pointer;">View</a></td>
                                    </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')"><img src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <!-- <ol>
                                <li class="active"><a href="#">1</a></li>
                                <li><a href="#">2</a></li>
                                <li><a href="#">3</a></li>
                            </ol> -->
                            <button (click)="nextPrev('next')"><img src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <ng-template #showDetailComponent>
        <div class="main-holder">
            <app-nav></app-nav>
            <div class="main">
                <div class="main-content">
                    <div class="bread">
                        <ol>
                            <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><a (click)="this.returnToList()" style="cursor: pointer;">Coupon Management</a></li>
                            <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                            <li><span>Coupon  Details</span></li>
                        </ol>
                    </div>
                    <div class="profile-holder">
                        <div class="profile">
                            <div class="ads-img">
                                <img [src]="this.detailViewed['photo']" *ngIf="this.detailViewed['photo'] != ''">
                            </div>
                            <div class="profile-info">
                                <h4>{{ this.detailViewed['title'] }}</h4>
                                <div style="display: block;">
                                    <h6 style="font-size: 14px;">Published Date: {{ this.detailViewed['dateCreated'] | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A'}}</h6>
                                    <h6 style="font-size: 14px;">Last Updated Date: {{ this.detailViewed['dateUpdated'] | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A' }}</h6>
                                </div>
                                <div class="tags">
                                    <span class="stat stat-published" *ngIf="this.detailViewed['status']">Published</span>
                                    <span class="stat stat-unpublished" *ngIf="!this.detailViewed['status']">Unpublished</span>
                                </div>
                            </div>
                        </div>
                        <div class="profile-actions" *ngIf="this.enableEdit; else disableEdit">
                            <button class="btn btn-sm btn-default" (click)="disableEditForm()">Cancel Edit</button>
                            <button class="btn btn-sm btn-default" (click)="updateThisDetails()">Save
                                Details</button>
                        </div>
                        
                        <ng-template #disableEdit>
                            <div class="profile-actions">
                                <button type="button" class="btn btn-sm btn-outline" (click)="viewLargerModal(viewImageLarger)"
                                    style="margin-right: .5em;">View Attachment</button>
                                <button class="btn btn-sm btn-default" (click)="enableEditForm()">Edit
                                    Coupon Details</button>
                                <button class="btn btn-sm btn-default" (click)="this.returnToList()">Return to List</button>
                            </div>
                        </ng-template>
                    </div>
                    <div class="panel">
                        <h3>Coupon Information</h3>
                        <div class="panel-info">
                            <div class="modal-upload-photo"  *ngIf="enableEdit">
                                <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)" accept="image/*">
                                <div class="docs-holder">
                                    <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                                </div>
                                <div class="btn-photo">
                                    <label for="modal-input-file" class="btn btn-sm btn-outline">Upload New Photo</label>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Title</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['title']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Subtitle</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['subTitle']">
                                    </div>
                                </div>
                            </div>
                            
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Service</label>
                                    <div class="col-sm-10">
                                        <select class="form-select" aria-label="Default select example" [disabled]="!enableEdit"
                                            [(ngModel)]="this.detailViewed['service']">
                                            <option value="express">Express</option>
                                            <option value="okride">OK Ride</option>
                                            <option value="mototaxi">Mototaxi</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                           <!--  <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Category</label>
                                    <div class="col-sm-10">
                                        <select class="form-select" aria-label="Default select example" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['category']">
                                            <option value="promos">Promo</option>
                                            <option value="special_discount">Special Discount</option>
                                        </select>
                                    </div>
                                </div>
                            </div> -->
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Type</label>
                                    <div class="col-sm-10">
                                        <select class="form-select" aria-label="Default select example" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['type']">
                                            <!--<option value="first_order">First Order</option>-->
                                            <option value="fixed_fee">Fixed Fee</option>
                                            <option value="percentage">Percentage</option>
                                            <!--<option value="location">Location Based</option>-->
                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Start Date</label>
                                    <div class="form-control-grp col-sm-10">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                                            #d="ngbDatepicker" [disabled]="!enableEdit"/>
                                        <img *ngIf="enableEdit" src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="margin-right: 10px; cursor: pointer;">
                                    </div>
                                </div>
                            </div>
                            
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">End Date</label>
                                    <div class="form-control-grp col-sm-10">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp2" [(ngModel)]="model2" ngbDatepicker
                                            #d2="ngbDatepicker" [disabled]="!enableEdit"/>
                                        <img *ngIf="enableEdit" src="../../../../assets/okito/images/calendar.svg" (click)="d2.toggle()" style="margin-right: 10px; cursor: pointer;">
                                    </div>
                                </div>
                            </div>

                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Value</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit"
                                            [(ngModel)]="this.detailViewed['value']" AppCurrencyFormatterDirective>
                                    </div>
                                </div>
                            </div>

                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Promo Cap</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['quantity']">
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Minimum Order Value</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['minValue']" AppCurrencyFormatterDirective>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Maximum Order Value</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['maxValue']" AppCurrencyFormatterDirective>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Mechanics</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['mechanics']"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Service</label>
                                    <div class="col-sm-10">
                                        <select class="form-select" aria-label="Default select example" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['service']">
                                            <option value="express">Express</option>
                                            <option value="okride">OK Ride</option>
                                            <option value="mototaxi">Mototaxi</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-grp">
                                <div class="mb-3 row">
                                    <label class="col-sm-2 col-form-label">Location</label>
                                    <div class="col-sm-10">
                                        <div class="row">
                                            <div class="col-md">
                                                <select (change)="onSelectProvince($event)" class="form-select" aria-label="Default select example" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['province']">
                                                    <option [value]="selectedProvince.provDesc" [attr.data-provcode]="selectedProvince.provCode">{{ selectedProvince.provDesc }}</option>
                                                    <option *ngFor="let province of this.locationData.provinces" [value]="province.provDesc" [attr.data-provcode]="province.provCode">{{ province.provDesc }}</option>
                                                </select>
                                            </div>
                                            <div class="col-md">
                                                <select class="form-select" aria-label="Default select example" [disabled]="!enableEdit" [(ngModel)]="this.detailViewed['city']">
                                                    <option [value]="selectedCity.citymunDesc" [attr.data-citycode]="selectedCity.citymunCode">{{ selectedCity.citymunDesc }}</option>
                                                    <option *ngFor="let city of this.filteredCities" [value]="city.citymunDesc" [attr.data-citycode]="city.citymunCode">
                                                        {{ city.citymunDesc }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #createCoupon let-modal class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Create Coupon</h5>
        <!-- <div class="modal-check">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="chk1" [(ngModel)]="this.newAdPublishToggle">
                <label class="form-check-label" for="chk1">Publish Now</label>
            </div>
        </div> -->
    </div>
    <div class="modal-body">
        <div class="modal-form">
            <div class="form-item">
                <label class="form-label">Coupon Photo</label>
                <div class="modal-upload-photo">
                    <input type="file" class="input-file" id="modal-input-file" (change)="onFileSelected($event)" accept="image/*">
                    <div class="docs-holder">
                        <img [src]="this.imageSrc" *ngIf="this.imageSrc != undefined">
                    </div>
                    <div class="btn-photo">
                        <label for="modal-input-file" class="btn btn-sm btn-outline">Upload Photo</label>
                    </div>
                </div>
            </div>

            <div class="form-item">
                <label class="form-label">Title</label>
                <input type="text" class="form-control" placeholder="Enter title" [(ngModel)]="couponFormNew['title']">
            </div>
            <div class="form-item">
                <label class="form-label">Subtitle</label>
                <input type="text" class="form-control" placeholder="Enter subtitle"
                    [(ngModel)]="couponFormNew['subTitle']">
            </div>
            <div class="form-item">
                <label class="form-label">Category</label>
                <select class="form-select" aria-label="Default select example"
                    [(ngModel)]="couponFormNew['category']">
                    <option value="promos">Promo</option>
                    <option value="special_discount">Special Discount</option>
                </select>
            </div>
            <div class="form-item">
                <label class="form-label">Type</label>
                <select class="form-select" aria-label="Default select example" [(ngModel)]="couponFormNew['type']">
                    <!-- <option value="first_order">First Order</option> -->
                    <option value="fixed_fee">Fixed Fee</option>
                    <option value="percentage">Percentage</option>
                    <option value="location">Location Based</option>
                </select>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Value</label>
                        <input type="text" class="form-control" placeholder="Enter promo cap" [(ngModel)]="couponFormNew['value']" AppCurrencyFormatterDirective>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Promo Cap</label>
                        <input type="text" class="form-control" placeholder="Enter promo cap" [(ngModel)]="couponFormNew['quantity']">
                    </div>
                </div>
            </div>
            <div class="row publish-date">
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Start Date</label>
                        <div class="form-control-grp">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                                #d="ngbDatepicker" />
                            <img src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="cursor: pointer;">
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">End Date</label>
                        <div class="form-control-grp">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp2" [(ngModel)]="model2" ngbDatepicker
                                #d2="ngbDatepicker" />
                            <img src="../../../../assets/okito/images/calendar.svg" (click)="d2.toggle()" style="cursor: pointer;">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Minimum Order Value</label>
                        <input type="text" class="form-control" placeholder="Enter minimum order value" [(ngModel)]="couponFormNew['minValue']" AppCurrencyFormatterDirective>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Maximum Order Value</label>
                        <input type="text" class="form-control" placeholder="Enter maximum order value" [(ngModel)]="couponFormNew['maxValue']" AppCurrencyFormatterDirective>
                    </div>
                </div>
            </div>
            <div class="form-item">
                <label class="form-label">Description/Mechanics</label>
                <textarea class="form-control" placeholder="Type or paste your text here"
                    [(ngModel)]="couponFormNew['mechanics']"></textarea>
            </div>
            <!-- <div class="form-item">
                <label class="form-label">Button Link</label>
                <input type="text" class="form-control" placeholder="Enter button link">
            </div> -->

            <label>ADDITIONAL FILTERS</label>
            <div class="form-item">
                <label class="form-label">Service</label>
                <select class="form-select" aria-label="Default select example" [(ngModel)]="couponFormNew['service']">
                    <option value="express">Express</option>
                    <option value="okride">OK Ride</option>
                    <option value="mototaxi">Mototaxi</option>
                </select>
            </div>
            <!-- <div class="form-item">
                <label class="form-label">Audience</label>
                <select class="form-select" aria-label="Default select example" [(ngModel)]="couponFormNew['audience']">
                    <option value="consumer">Consumer</option>
                    <option value="partner">Partners</option>
                </select>
            </div> -->
            <div class="row">
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">Location</label>
                        <select (change)="onSelectProvince($event)" class="form-select" aria-label="Default select example" [(ngModel)]="couponFormNew['province']">
                            <option *ngFor="let province of this.locationData.provinces" [value]="province.provDesc" [attr.data-provcode]="province.provCode">{{ province.provDesc }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-item">
                        <label class="form-label">&nbsp;</label>
                        <select class="form-select" aria-label="Default select example" [(ngModel)]="couponFormNew['city']">
                            <option *ngFor="let city of this.filteredCities" [value]="city.citymunDesc" [attr.data-citycode]="city.citymunCode">{{ city.citymunDesc }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" class="btn btn-default" (click)="createCouponData(confirmPublish)">Create Coupon</button>
    </div>
</ng-template>

<ng-template #successCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Coupon successfully created</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>


<ng-template #confirmPublish let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body">
        <p>Are you sure you want to create this Coupon?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="proceedCreateCoupon(successCreate)">Yes, Create</button>
    </div>
</ng-template>

<ng-template #viewImageLarger let-modal id="photo" class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Attachment Viewing</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <div class="modal-body">
        <div class="image-bigviewer">
            <img class="flex-item" [src]="this.detailViewed['photo']" *ngIf="this.detailViewed['photo'] != ''"
                style="height: 80vh;">
        </div>
    </div>
    <div class="modal-footer">
        <div style="width: 25vh;">
            <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Close</button>
        </div>
    </div>
</ng-template>