import { Component, TemplateRef, inject } from '@angular/core';
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule, JsonPipe } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { WithdrawalRequest, Coupon, LocationData, City, Province} from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { NgbModal,NgbAlertModule, NgbDatepickerModule, NgbDateStruct, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import { Apollo } from 'apollo-angular';
import { MomentModule } from 'ngx-moment';
import { AuthStore } from '@store/auth.store';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { WITHDRAWAL_REQUESTS } from '@gql_queries/withdrawals/queries';
import { 
  CANCEL_WITHDRAW_REQUEST,
  PROCESS_WITHDRAW_REQUEST,
  COMPLETE_WITHDRAW_REQUEST 
} from '@gql_queries/withdrawals/mutations';

@Component({
  selector: 'app-dashboard-payment-management',
  standalone: true,
  imports: [NgbDatepickerModule, NgbAlertModule, FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, JsonPipe, NgbTimepickerModule, RouterModule , MomentModule],
  templateUrl: './dashboard-payment-management.component.html',
  styleUrl: './dashboard-payment-management.component.css'
})


export class DashboardPaymentManagementComponent {
  today = new Date();
  dateToday: Date;
  dateSevenDaysAgo: Date;

  public withdrawalList: WithdrawalRequest[] = [];

  public searchKeyword: string = '';
  public currentSelectedPageList: WithdrawalRequest[] = [];
  public maxPages: number = 0;
  public disableActionBtns: any = {
      next: false,
      prev: false,
  };
  public couponPages: number[] = [];
  public currentPage: number = 1;
  public pageSize: number = 15;

  public withdrawTabs: Record<string, any> = {
      for_review: true,
      processing: false,
      completed: false,
      cancelled: false,
  };

  public processWithdrawal: Record<string, any> = {
    data: {},
    action: '',
  };
  public processWithdrawalModals: Record<string, any> = {
    confirmStr: '',
    sucessStr: '',
  };

  constructor(
    private router: Router,
    private apollo: Apollo,
    private authStore: AuthStore
  ) {
    this.dateToday = new Date();
    this.dateSevenDaysAgo = new Date(this.today);
    this.dateSevenDaysAgo.setDate(this.today.getDate() - 7);
  }

  //withdrawalRequests: WithdrawalRequest[] = [];
  private modalService = inject(NgbModal);

  fetchWithdrawalRequests(status: string, action: string = '') {
    var fromPage = 1;

    if(action != ''){
        fromPage = this.currentPage;
        switch(action){
            case 'next':
                this.currentPage++
            break;
  
            case 'prev':
                if(this.currentPage != 1){
                    this.currentPage--
                }//end if
            break;
        }//end swithc    
    }//end if

    this.apollo
      .watchQuery({
        fetchPolicy: 'network-only',
        query: WITHDRAWAL_REQUESTS,
        variables: {
          "status": status,
          "page": this.currentPage,
        }
      }).valueChanges.subscribe({
        next: (res: any) => {
          if (res.length != 0) {
            this.withdrawalList = res.data['withdrawalRequests'];
            this.currentSelectedPageList = this.withdrawalList;
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
                // Optional: Redirect to login page or show message
                this.router.navigate(['/admin/login']);
            });
        }
        }
      });
  } 

  filterWithdrawalRequest(tabindex: string){
      this.withdrawalList = [];
      this.currentSelectedPageList = [];

      Object.keys(this.withdrawTabs).forEach((x) => {
          this.withdrawTabs[x] = false;
      });

      this.withdrawTabs[tabindex] = true;

      this.currentPage = 1;
      this.fetchWithdrawalRequests(tabindex);
  }//end

  nextPrev(action: string){
      var tabActive = '';
      Object.keys(this.withdrawTabs).forEach((x) => {
          if(this.withdrawTabs[x]){
              tabActive = x;
          }//end if
      });

      switch(action){
          case 'next':
              this.fetchWithdrawalRequests(tabActive , 'next');
          break;

          case 'prev':
              this.fetchWithdrawalRequests(tabActive , 'prev');
          break;
      }//end swithc
  }//end fn

  searchData(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      this.searchKeyword = inputElement.value;

      if (this.searchKeyword.trim() === '') {
          console.log('Input is empty');
          this.currentSelectedPageList = this.withdrawalList;
      } else {
          this.currentSelectedPageList = this.withdrawalList.filter(object => {
              // Helper function to recursively search through nested objects
              const searchInObject = (obj: any): boolean => {
                  for (const key in obj) {
                      const value = obj[key];
                      
                      // Skip if null or undefined
                      if (value == null) continue;
                      
                      // If value is an object or array, search recursively
                      if (typeof value === 'object') {
                          if (searchInObject(value)) return true;
                      }
                      // Check if the value includes the search term
                      else if (value.toString().toLowerCase().includes(this.searchKeyword.toLowerCase())) {
                          return true;
                      }
                  }
                  return false;
              };
              
              return searchInObject(object);
          });
      }
  }//end nfn

  closeAllModals(){
    this.modalService.dismissAll();
  }//end fn

  processWithdrawalAction(action: string, withdrawalData: WithdrawalRequest, confirmModal: TemplateRef<any>){
    this.processWithdrawal['data'] = withdrawalData;
    this.processWithdrawal['action'] = action;

    switch(action){
      case 'cancel':
        this.processWithdrawalModals['confirmStr'] = 'Are you sure you want to cancel this withdrawal request?';
        this.processWithdrawalModals['successStr'] = 'Withdrawal request has been successfully cancelled.';
      break;

      case 'process':
        this.processWithdrawalModals['confirmStr'] = 'Are you sure you want to process this withdrawal request?';
        this.processWithdrawalModals['successStr'] = 'Withdrawal request has been successfully processed.';
      break;

      case 'complete':
        this.processWithdrawalModals['confirmStr'] = 'Are you sure you want to complete this withdrawal request?';
        this.processWithdrawalModals['successStr'] = 'Withdrawal request has been successfully completed.';
      break;
    }//end switch

    this.modalService.open(confirmModal, { size: 'lg',  backdrop: 'static', keyboard: false });
  }//end fn
  
  proceedWithdrawalProcess(successModal: TemplateRef<any>){
    switch(this.processWithdrawal['action']){
      case 'cancel':
        this.cancelWithdrawalRequest(successModal);  
      break;

      case 'process':
        this.processWithdrawalRequest(successModal);
      break;

      case 'complete':
        this.completeWithdrawalRequest(successModal);
      break;
    }//end switch
    
  }//end if

  cancelWithdrawalRequest(successModal: TemplateRef<any>, remarks: string = ''){ 
    this.apollo
      .mutate({
        mutation: CANCEL_WITHDRAW_REQUEST,
        variables: {
          "walletRequestId": this.processWithdrawal['data'].partnerWalletRequestId,
          "remarks": remarks
        }
      }).subscribe({
        next: (res: any) => {
          if (res.length != 0) {
            let cancelledInstance = res.data['cancelWithdrawRequest'];
            
            this.currentSelectedPageList = this.withdrawalList.filter(item => item.partnerWalletRequestId !== cancelledInstance.partnerWalletRequestId);
            this.withdrawalList = this.withdrawalList.filter(item => item.partnerWalletRequestId !== cancelledInstance.partnerWalletRequestId);
            this.processWithdrawal['data'] = {};
            this.processWithdrawal['action'] = '';
            this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false });
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
              this.authStore.logout().subscribe(() => {
                  // Optional: Redirect to login page or show message
                  this.router.navigate(['/admin/login']);
              });
          }
        }
      });
  }//end cancelled


  processWithdrawalRequest(successModal: TemplateRef<any>){ 
    this.apollo
      .mutate({
        mutation: PROCESS_WITHDRAW_REQUEST,
        variables: {
          "walletRequestId": this.processWithdrawal['data'].partnerWalletRequestId,
        }
      }).subscribe({
        next: (res: any) => {
          if (res.length != 0) {
            let processedInstance = res.data['processWithdrawRequest'];
            this.currentSelectedPageList = this.withdrawalList.filter(item => item.partnerWalletRequestId !== processedInstance.partnerWalletRequestId);
            this.withdrawalList = this.withdrawalList.filter(item => item.partnerWalletRequestId !== processedInstance.partnerWalletRequestId);
            
            this.processWithdrawal['data'] = {};
            this.processWithdrawal['action'] = '';

            this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false });
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
              this.authStore.logout().subscribe(() => {
                  // Optional: Redirect to login page or show message
                  this.router.navigate(['/admin/login']);
              });
          }
        }
      });
  }//end processing

  completeWithdrawalRequest(successModal: TemplateRef<any>){ 
    this.apollo
      .mutate({
        mutation: COMPLETE_WITHDRAW_REQUEST,
        variables: {
          "walletRequestId": this.processWithdrawal['data'].partnerWalletRequestId,
        }
      }).subscribe({
        next: (res: any) => {
          if (res.length != 0) {
            let completeInstance = res.data['completeWithdrawRequest'];
            this.currentSelectedPageList = this.withdrawalList.filter(item => item.partnerWalletRequestId !== completeInstance.partnerWalletRequestId);
            this.withdrawalList = this.withdrawalList.filter(item => item.partnerWalletRequestId !== completeInstance.partnerWalletRequestId);
            
            this.processWithdrawal['data'] = {};
            this.processWithdrawal['action'] = '';

            this.modalService.open(successModal, { size: 'md',  backdrop: 'static', keyboard: false });
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
              this.authStore.logout().subscribe(() => {
                  // Optional: Redirect to login page or show message
                  this.router.navigate(['/admin/login']);
              });
          }
        }
      });
  }//end processing

  formatDate(date: Date): string {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  ngOnInit(): void {
    this.fetchWithdrawalRequests('for_review');
  }
  
}
