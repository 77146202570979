<div class="wrap">
    <header>
        <div class="logo">
            <a href="#"><img src="./../../../assets/okito-comcenter/images/okito-logo.svg"></a>
        </div>
        <div class="date">
            <img src="./../../../assets/okito-comcenter/images/date.svg">
            <small>{{ date | date:'dd-MM-yyyy' }}</small>
            <small>{{ date | date:'h:mm:ss a' }}</small>
        </div>
    </header>
    <div class="main-content">
        <div class="cards">
            <div class="row">
                <div class="col-md">
                    <div class="card-item card-gradient">
                        <div class="card-content">
                            <small>Total Customers</small>
                            <h4>{{ this.overview.totalUser | number: '1.0-0' }}</h4>
                        </div>
                        <img src="./../../../assets/okito-comcenter/images/card-icons/users.svg" class="icon-size-64">
                    </div>
                </div>
            </div>
        </div>
        <div class="cards">
            <div class="row">
                <div class="col-md">
                    <div class="card-panel">
                        <div class="card-item card-gray">
                            <div class="card-content">
                                <small>Partners: 2-Wheels</small>
                                <h4>{{ this.overview.twoWheels.totalApprovedPartners | number: '1.0-0' }}</h4>
                            </div>
                            <img src="./../../../assets/okito-comcenter/images/card-icons/motorcycle.svg" class="icon-size-56">
                        </div>
                        <div class="card-panel-item">
                            <div class="card-panel-content">
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>Express</small>
                                        <h4>{{ this.overview.twoWheels.expressCount | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/express.svg" class="icon-size-56">
                                </div>
                                <hr>
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>MotoTaxi</small>
                                        <h4>{{ this.overview.twoWheels.motoTaxiCount | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/mototaxi.svg" class="icon-size-56">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card-panel">
                        <div class="card-item card-gray">
                            <div class="card-content">
                                <small>Partners: 4-Wheels</small>
                                <h4>{{ this.overview.fourWheels.totalApprovedPartners | number: '1.0-0' }}</h4>
                            </div>
                            <img src="./../../../assets/okito-comcenter/images/card-icons/car.svg" class="icon-size-56">
                        </div>
                        <div class="card-panel-item">
                            <div class="card-panel-content">
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>Express</small>
                                        <h4>{{ this.overview.fourWheels.expressCount | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/express.svg" class="icon-size-56">
                                </div>
                                <hr>
                                <div class="card-item card-borderless">
                                    <div class="card-content">
                                        <small>Ride</small>
                                        <h4>{{ this.overview.fourWheels.okRideCount | number: '1.0-0' }}</h4>
                                    </div>
                                    <img src="./../../../assets/okito-comcenter/images/card-icons/ride.svg" class="icon-size-56">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>