<div class="wrap">
    <app-header></app-header>
    <div class="main-holder">
        <!--*ngIf="!this.showDetails; else showDetailComponent"-->
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol *ngIf="!showDetails">
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Surge Pricing Management</span></li>
                    </ol>
                    <ol *ngIf="showDetails">
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><a (click)="this.returnToList()" style="cursor: pointer;">Surge Pricing Management</a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Surge Pricing Management Details</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Surge Pricing Management</h2>
                </div>
                <hr>
                <div class="list-tabs" *ngIf="!showDetails">
                    <ul>
                        <li [ngClass]="{'active': this.surgeTabs['express']}"><a (click)="setTabPanelActive('express')"
                                style="cursor: pointer;">Express</a></li>
                        <li [ngClass]="{'active': this.surgeTabs['mototaxi']}"><a (click)="setTabPanelActive('mototaxi')"
                                style="cursor: pointer;">MotoTaxi</a></li>
                        <li [ngClass]="{'active': this.surgeTabs['ride']}"><a (click)="setTabPanelActive('ride')"
                                style="cursor: pointer;">Ride</a></li>
                    </ul>
                </div>
                <div class="list-holder" *ngIf="!showDetails">
                    <div class="tools">
                        <div class="search">
                            <div class="search" style="width: 20em !important;">
                                <img src="../../../../assets/okito/images/icons/magnifying.svg">
                                <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                    placeholder="Search">
                            </div>
                        </div>
                        <div class="search mg-right-auto" style="width:58%; ">
                            <span style="font-weight:bold;margin-right:10px;margin-left:20px;">Sorting: </span>
                            <select class="form-select" style="width:30%;margin-right:10px;" [(ngModel)]="surgeSortBy"
                                (ngModelChange)="onSortChange()">
                                <option value="dateCreated">Date Created</option>
                                <option value="multiplier">Multiplier</option>
                                <!-- <option value="totalPrice">Total Price</option> -->
                            </select>
                        
                            <div class="btn-group" role="group" aria-label="Sort by.." style="width:5%;">
                                <button type="button" [ngClass]="{'active': surgeSortDirection === 'asc'}" class="btn btn-secondary"
                                    (click)="surgeSortDirection = 'asc'; onSortChange()">Ascending</button>
                                <button type="button" [ngClass]="{'active': surgeSortDirection === 'desc'}" class="btn btn-secondary"
                                    (click)="surgeSortDirection = 'desc'; onSortChange()">Descending</button>
                            </div>
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" (click)="openAddSurgePrice(addSurgePrice)">Add Surge Pricing</button>
                        </div>
                    </div>
                    <div class="list">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Date (From)</th>
                                    <th>Date (To)</th>
                                    <th>Time (From)</th>
                                    <th>Time (To)</th>
                                    <th>Rate</th>
                                    <th>Priority</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecords">
                                <tr *ngFor="let surge of currentSelectedPageList">
                                    <td>{{surge.title}}</td>
                                    <td>{{surge.startDate | date:'MM-dd-yyyy'}}</td>
                                    <td>{{surge.endDate | date:'MM-dd-yyyy'}}</td>
                                    <td>{{surge.timeStart | date:'h:mm a'}}</td>
                                    <td>{{surge.timeEnd | date:'h:mm a'}}</td>
                                    <td>{{surge.multiplier}}</td>
                                    <td>{{surge.isPriority ? 'Yes' : 'No'}}</td>
                                    <td>{{surge.isActive ? 'Active' : 'Inactive'}}</td>
                                    <td><a (click)="viewDetails(surge)" style="cursor: pointer;">View</a></td>
                                </tr>
                                </ng-container>
                                <ng-template #noRecords>
                                    <tr>
                                        <td colspan="9" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button class="pager-prev"><img src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <button class="pager-next"><img src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>

                <div class="list-holder" *ngIf="showDetails">
                    
                    <div class="tools">
                        <div class="search">
                            <!-- <div class="search" style="width: 20em !important;">
                                <img src="../../../../assets/okito/images/icons/magnifying.svg">
                                <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                    placeholder="Search">
                            </div> -->
                        </div>
                        <div class="search mg-right-auto" style="width:58%; ">
                            <!-- <span style="font-weight:bold;margin-right:10px;margin-left:20px;">Sorting: </span>
                            <select class="form-select" style="width:30%;margin-right:10px;" [(ngModel)]="surgeSortBy"
                                (ngModelChange)="onSortChange()">
                                <option value="dateCreated">Date Created</option>
                                <option value="multiplier">Multiplier</option>
                            </select> -->
                    
                            <!-- <div class="btn-group" role="group" aria-label="Sort by.." style="width:5%;">
                                <button type="button" [ngClass]="{'active': surgeSortDirection === 'asc'}" class="btn btn-secondary"
                                    (click)="surgeSortDirection = 'asc'; onSortChange()">Ascending</button>
                                <button type="button" [ngClass]="{'active': surgeSortDirection === 'desc'}" class="btn btn-secondary"
                                    (click)="surgeSortDirection = 'desc'; onSortChange()">Descending</button>
                            </div> -->
                        </div>
                        <div class="profile-actions">
                            <button class="btn btn-sm btn-default" style="min-width: 100px;" (click)="enableEditForm()" *ngIf="!enableEdit">Edit Details</button>
                            <button class="btn btn-sm btn-default" style="min-width: 100px;" (click)="confirmUpdateSurgeData(confirmUpdate)" *ngIf="enableEdit">Save</button>
                            <button class="btn btn-sm btn-outline" style="min-width: 100px;" (click)="disableEditForm()" *ngIf="enableEdit">Cancel</button>
                            <button class="btn btn-sm btn-outline" style="min-width: 100px;" (click)="this.returnToList()" *ngIf="!enableEdit">Back to List</button>
                        </div>
                    </div>
                    <div class="panel" *ngIf="showDetails">
                        <div class="panel-info">
                            <div class="form-item">
                                <label class="form-label">Service</label>
                                <select class="form-select" aria-label="Default select example" [disabled]="!enableEdit"
                                    [(ngModel)]="this.updateSurgePriceForm['serviceType']">
                                    <option value="express">Express</option>
                                    <option value="okride">OK Ride</option>
                                    <option value="mototaxi">Mototaxi</option>
                                </select>
                            </div>
                            <div class="form-item">
                                <label class="form-label">Name</label>
                                <input type="text" class="form-control" placeholder="Enter name" 
                                    [(ngModel)]="this.updateSurgePriceForm['title']"
                                    [disabled]="!enableEdit">
                            </div>
                            <div class="mb-3 row">
                                <div class="panel-grp">
                                    <div class="mb-3 row form-item">
                                        <label class="form-label">Start Date and Time</label>
                                        <div class="col-md-9 col-sm-9">
                                            <div class="form-item">
                                                <input type="text" class="form-control" placeholder="Enter name" [(ngModel)]="this.detailViewed['startDate']"
                                                    disabled="true">
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-3">
                                            <div class="form-item" style="margin-top: -10px;">
                                                <label class="form-label"></label>
                                                <div class="form-control-grp"
                                                    style="align-items: center; justify-content: center; display: flex; height: 60px; margin-bottom: 20px;">
                                                    <ngb-timepicker [(ngModel)]="time" [meridian]="meridian" 
                                                        [disabled]="!enableEdit" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="panel-grp">
                                    <div class="mb-3 row form-item">
                                        <label class="form-label">End Date and Time</label>
                                        <div class="col-md-9 col-sm-9">
                                            <div class="form-item">
                                                <input type="text" class="form-control" placeholder="Enter name" [(ngModel)]="this.detailViewed['endDate']"
                                                    disabled="true">
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-3">
                                            <div class="form-item" style="margin-top: -10px;">
                                                <label class="form-label"></label>
                                                <div class="form-control-grp"
                                                    style="align-items: center; justify-content: center; display: flex; height: 60px; margin-bottom: 20px;">
                                                    <ngb-timepicker [(ngModel)]="time2" [meridian]="meridian" 
                                                        [disabled]="!enableEdit" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                               <!--  <br>
                                <div class="col-md-12" style="margin-bottom: 10px;">
                                    <div class="modal-check mt-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="chk1" [(ngModel)]="surgeIsAllDay"
                                                (change)="onAllDayChange($event)"
                                                [disabled]="!enableEdit">
                                            <label class="form-check-label" for="chk1">All Day. Starting 12:00am to 11:59PM</label>
                                        </div>
                                    </div>
                                </div> -->
                                <br>
                                <div class="col-md-12">
                                    <div class="form-item">
                                        <label class="form-label">Rate (from 1.1 to 2.0)</label>
                                        <br>
                                        <div class="input-group rate-input-group">
                                            <button class="btn btn-default input-group-prepend" type="button" 
                                                (click)="decreaseRate()"
                                                [disabled]="!enableEdit">-</button>
                                            <input type="text" class="form-control" 
                                                [(ngModel)]="updateSurgePriceForm['multiplier']" 
                                                readonly>
                                            <button class="btn btn-default input-group-append" type="button" 
                                                (click)="increaseRate()"
                                                [disabled]="!enableEdit">+</button>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="col-md-12" style="margin-top: 10px;">
                                    <div class="modal-check mt-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="priority" 
                                                [(ngModel)]="updateSurgePriceForm['isPriority']"
                                                [disabled]="!enableEdit">
                                            <label class="form-check-label" for="priority">Priority. This surge setting will be prioritized over other existing surges.</label>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div class="col-md-12" style="margin-top: 10px;">
                                    <div class="modal-check mt-2">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="priority"
                                                [(ngModel)]="updateSurgePriceForm['isActive']" [disabled]="!enableEdit">
                                            <label class="form-check-label" for="priority">Is Active?.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="price-logs-holder" *ngIf="showDetails">
                       <!--  <div class="price-logs">
                            <div class="logs-item">
                                <label>Created:</label>
                                <span>{{this.detailViewed['dateCreated']}}</span>
                            </div>
                            <div class="logs-item">
                                <label>Created by:</label>
                                <span>{{this.detailViewed['createdBy']}}</span>
                            </div>
                        </div>
                        <div class="price-logs">
                            <div class="logs-item">
                                <label>Last Edited:</label>
                                <span>{{this.detailViewed['editedDate']}}</span>
                            </div>
                            <div class="logs-item">
                                <label>Edited by:</label>
                                <span>{{this.detailViewed['editedBy']}}</span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #addSurgePrice let-modal class="modal">
    <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Add Surge Price</h5>
    </div>
    <div class="modal-body">
        <div class="form-item form-error">
            <span class="error-required">Required</span>
            <label class="form-label">Name</label>
            <input type="text" class="form-control" placeholder="Enter name" [(ngModel)]="surgePriceForm['title']">
        </div>
        <br>
        <div class="row">
            <div class="panel-grp">
                <div class="mb-3 row form-item">
                    <label class="form-label">Start Date and Time</label>
                    <div class="col-md-9 col-sm-9">
                        <div class="form-item">
                            <div class="form-control-grp">
                                <input class="form-control" placeholder="yyyy-mm-dd" name="startDate" 
                                    [(ngModel)]="model" ngbDatepicker #startDate="ngbDatepicker" readonly />
                                <img src="../../../../assets/okito/images/calendar.svg" (click)="startDate.toggle()"
                                    style="cursor: pointer;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3">
                        <div class="form-item" style="margin-top: -30px;">
                            <label class="form-label"></label>
                            <div class="form-control-grp"
                                style="align-items: center; justify-content: center; display: flex; height: 60px; margin-bottom: 20px;">
                                <ngb-timepicker [(ngModel)]="time" [meridian]="meridian" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel-grp">
                <div class="mb-3 row form-item">
                    <label class="form-label">End Date and Time</label>
                    <div class="col-md-9 col-sm-9">
                        <div class="form-item">
                            <div class="form-control-grp">
                                <input class="form-control" placeholder="yyyy-mm-dd" name="endDate" 
                                    [(ngModel)]="model2" ngbDatepicker #endDate="ngbDatepicker" readonly />
                                <img src="../../../../assets/okito/images/calendar.svg" (click)="endDate.toggle()" 
                                    style="cursor: pointer;">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3">
                        <div class="form-item" style="margin-top: -30px;">
                            <label class="form-label"></label>
                            <div class="form-control-grp"
                                style="align-items: center; justify-content: center; display: flex; height: 60px; margin-bottom: 20px;">
                                <ngb-timepicker [(ngModel)]="time2" [meridian]="meridian" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="col-md-12">
                <div class="modal-check mt-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="chk1" 
                            [(ngModel)]="surgeIsAllDay" 
                            (change)="onAllDayChange($event)">
                        <label class="form-check-label" for="chk1">All Day. Starting 12:00am to 11:59PM</label>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <div class="form-item">
                    <label class="form-label">Rate (from 1.1 to 2.0)</label>
                    <br>
                    <div class="input-group rate-input-group">
                        <button class="btn btn-default input-group-prepend" type="button" (click)="decreaseRate()">-</button>
                        <input type="text" class="form-control" [(ngModel)]="surgePriceForm['multiplier']" readonly>
                        <button class="btn btn-default input-group-append" type="button" (click)="increaseRate()">+</button>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="modal-check">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="priority" [(ngModel)]="surgePriceForm['isPriority']">
                <label class="form-check-label" for="priority">Priority. This surge setting will be prioritized over other
                    existing surges.</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">Cancel</button>
        <button type="button" id="publish" class="btn btn-default" (click)="openConfirmCreate(confirmCreate)">Save</button>
    </div>
</ng-template>

<ng-template #confirmCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body">
        <p>Are you sure you want to create this Surge Price?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="createSurgeData(successCreate)" >Yes, Create</button>
    </div>
</ng-template>

<ng-template #successCreate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Surge Price successfully created</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>


<ng-template #confirmUpdate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <!-- FOR CONFIRM VALIDATE PARTNER -->
    <div class="modal-body">
        <p>Are you sure you want to update this Surge Price?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.dismiss('Cross click')">No, Cancel it</button>
        <button type="button" class="btn btn-default" (click)="updateSurgeData(successUpdate)">Yes, Update</button>
    </div>
</ng-template>

<ng-template #successUpdate let-modal id="photo" class="modal">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>

    <!-- SUCCESS VALIDATE -->
    <div class="modal-body success-body">
        <img src="../../../../assets/okito/images/modal-success.svg">
        <p>Surge Price successfully updated</p>
        <button class="btn btn-default" (click)="closeAllModals()">Close</button>
    </div>
</ng-template>