import { Component, TemplateRef, inject } from '@angular/core';
import { HeaderComponent } from '@components/dashboard/header/header.component';
import { NavComponent } from '@components/dashboard/navigation/nav.component';
import { FooterComponent } from '@components/dashboard/footer/footer.component';
import { CommonModule, JsonPipe } from '@angular/common';
import { DashboardService } from '@services/dashboard.service';
import { WithdrawalRequest, Coupon, LocationData, City, Province, RewardRaffle} from '@interfaces/models.interface';
import { ServerResponse } from '@interfaces/general.interface';
import { LocalService } from '@services/local.service';
import { FormsModule } from '@angular/forms'; // Import FormsModule here
import { NgbModal,NgbAlertModule, NgbDatepickerModule, NgbDateStruct, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { MomentModule } from 'ngx-moment';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-dashboard-rewards',
    standalone: true,
    templateUrl: './dashboard-rewards.component.html',
    styleUrl: './dashboard-rewards.component.css',
    imports: [NgbDatepickerModule, NgbAlertModule, FooterComponent, HeaderComponent, NavComponent, CommonModule, FormsModule, JsonPipe, NgbTimepickerModule, RouterModule , MomentModule],
})
export class DashboardRewardsComponent {
    today = new Date();
    model?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate(), };
    model2?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate(), };
    model3?: NgbDateStruct = { year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate(), };
    time = { hour: 0, minute: 0 };
    time2 = { hour: 0, minute: 0 };
    time3 = { hour: 0, minute: 0 };
	  meridian = false;    

    constructor(
      private dasher: DashboardService,
      private localservice: LocalService,
      private apollo: Apollo
    ) {}
    
    public rewardsList: RewardRaffle[] = [];

    public searchKeyword: string = '';
    public currentSelectedPageList: RewardRaffle[] = [];
    public maxPages: number = 0;
    public disableActionBtns: any = {
        next: false,
        prev: false,
    };
    public couponPages: number[] = [];
    public currentPage: number = 1;
    public pageSize: number = 15;
  
    public withdrawTabs: Record<string, any> = {
        for_review: true,
        processing: false,
        completed: false,
        cancelled: false,
    };
  
    public processWithdrawal: Record<string, any> = {
      data: {},
      action: '',
    };
    public processWithdrawalModals: Record<string, any> = {
      confirmStr: '',
      sucessStr: '',
    };

    imageSrc?: string | ArrayBuffer | null; 
    vPhoto: File | null = null;

    newRewardsForm: Record<string, any> = {
      "title": "",
      "audience": "",
      "type": "",
      "noWinner": 0,
      "points": 0,
      "photo": "",
      "mechanics": "",
      "termsCondition": "",
      "dateStart": "",
      "dateEnd": "",
      "dateDraw": ""
    };
    
    copyForm: Record<string, any> = {};
    public detailViewed: Record<string, any> = {};
    public showDetails: boolean = false;
    public enableEdit: boolean = false;

    //withdrawalRequests: WithdrawalRequest[] = [];
    private modalService = inject(NgbModal)

    fetchRewardsList(status: string, action: string = '') {
        var fromPage = 1;
    
        if(action != ''){
            fromPage = this.currentPage;
            switch(action){
                case 'next':
                    this.currentPage++
                break;
      
                case 'prev':
                    if(this.currentPage != 1){
                        this.currentPage--
                    }//end if
                break;
            }//end swithc    
        }//end if
    
        this.apollo
          .watchQuery({
            fetchPolicy: 'network-only',
            query: gql`
              query raffles($page:Int) {
                raffles(page:$page) {
                  rewardRaffleId
                  title
                  photo
                  audience
                  noWinner
                  status
                  points
                  mechanics
                  termsCondition
                  dateStart
                  dateEnd
                  dateDraw
                  dateCreated
                  dateUpdated
                  ticketCount
                  rewardRaffleEntryList {
                    rewardRaffleEntryId
                    userType
                    ticketNumber
                    dateCreated
                  }
                }
              }
            `,
            variables: {
              page: this.currentPage
            }
          }).valueChanges.subscribe((res: any) => {
            if(res.length != 0){
              this.rewardsList = res.data['raffles'];
              this.currentSelectedPageList = this.rewardsList;
            }//end if
          });
      }
    
      nextPrev(action: string){
          var tabActive = '';
          Object.keys(this.withdrawTabs).forEach((x) => {
              if(this.withdrawTabs[x]){
                  tabActive = x;
              }//end if
          });
    
          switch(action){
              case 'next':
                  this.fetchRewardsList(tabActive , 'next');
              break;
    
              case 'prev':
                  this.fetchRewardsList(tabActive , 'prev');
              break;
          }//end swithc
      }//end fn
    
      searchData(event: Event) {
          const inputElement = event.target as HTMLInputElement;
          this.searchKeyword = inputElement.value;
    
          if (this.searchKeyword.trim() === '') {
              console.log('Input is empty');
              this.currentSelectedPageList = this.rewardsList
          }else{
              this.currentSelectedPageList =  this.rewardsList.filter(object =>
                  Object.values(object).some(value => 
                      (value ? value.toString().toLowerCase() : '').includes(this.searchKeyword.toLowerCase())
                  )
              );
          }//end if
      }//end nfn

      createReward(confirmModal: TemplateRef<any>){
        let startdate = this.model?.year + '-' + ((this.model!.month.toString().length > 1) ? this.model!.month: '0'+this.model!.month) + '-' + ((this.model!.day.toString().length > 1) ? this.model!.day: '0'+this.model!.day);
        let starttime = ((this.time.hour.toString().length > 1) ? this.time.hour: '0'+this.time.hour) + ':' + ((this.time.minute.toString().length > 1) ? this.time.minute: '0'+this.time.minute)+ ':00';
        this.newRewardsForm['startDateTime'] = startdate + ' ' + starttime;

        let enddate = this.model2?.year + '-' + ((this.model2!.month.toString().length > 1) ? this.model2!.month: '0'+this.model2!.month) + '-' + ((this.model2!.day.toString().length > 1) ? this.model2!.day: '0'+this.model2!.day);
        let endtime = ((this.time2.hour.toString().length > 1) ? this.time2.hour: '0'+this.time2.hour) + ':' + ((this.time2.minute.toString().length > 1) ? this.time2.minute: '0'+this.time2.minute)+ ':00';
        this.newRewardsForm['endDateTime'] = enddate + ' ' + endtime;

        let drawdate = this.model3?.year + '-' + ((this.model3!.month.toString().length > 1) ? this.model3!.month: '0'+this.model3!.month) + '-' + ((this.model3!.day.toString().length > 1) ? this.model3!.day: '0'+this.model3!.day);
        let drawtime = ((this.time3.hour.toString().length > 1) ? this.time3.hour: '0'+this.time3.hour) + ':' + ((this.time3.minute.toString().length > 1) ? this.time3.minute: '0'+this.time3.minute)+ ':00';
        this.newRewardsForm['dateDrawTime'] = drawdate + ' ' + drawtime;
        

        var errors: number = 0;
        Object.keys(this.newRewardsForm).forEach((x) => {
          if(x != 'photo'){
            if(this.newRewardsForm[x] == ''){
              errors ++;
            }//end if
          }//end if
        });

        if(errors != 0){
          alert('Please enter all required fields');
        }else{
          this.modalService.open(confirmModal, { size: 'md',  backdrop: 'static', keyboard: false });
        }//end if
      }//end fn

      proceedCreateReward(successModal: TemplateRef<any>){
        const formData:any = new FormData();      
        if (this.vPhoto) {
          formData.append('file', this.vPhoto, this.vPhoto.name);
        }

        this.dasher.uploadPhoto(formData, 'rewards').subscribe((res: any) => {
          this.newRewardsForm['photo'] = res.photo;
          this.createRewardsMutate(successModal);
        });
      }//end if

      createRewardsMutate(successModal: TemplateRef<any>){ 
        this.copyForm = this.newRewardsForm;
        delete this.copyForm['type']; 
        this.copyForm['points'] = parseInt(this.copyForm['points']);
        this.copyForm['noWinner'] = parseInt(this.copyForm['noWinner']);

        this.apollo
          .mutate({
            mutation: gql`
              mutation createRewardRaffle($createRaffleInput: CreateRaffleInput!) {
                createRewardRaffle(createRaffleInput:$createRaffleInput) {
                  rewardRaffleId
                  title
                  noWinner
                  points
                  mechanics
                  termsCondition
                  dateStart
                  dateEnd
                  dateDraw
                  dateCreated
                  dateUpdated
                }
              }
            `,
            variables:{
              "createRaffleInput": this.copyForm,
            }
          }).subscribe((res: any) => {
            if(res.length != 0){
              let createRewardRaffle: RewardRaffle = res.data['createRewardRaffle'];

              this.copyForm = {};
              this.newRewardsForm = {
                "title": "",
                "audience": "",
                "type": "",
                "noWinner": 0,
                "points": 0,
                "photo": "",
                "mechanics": "",
                "termsCondition": "",
                "dateStart": "",
                "dateEnd": "",
                "dateDraw": ""
              };
              
              
              /* this.rewardsList = [createRewardRaffle, ...this.rewardsList];
              this.currentSelectedPageList = this.rewardsList; */
              this.fetchRewardsList('for_review');
              this.closeAllModals();

              this.modalService.open(successModal, { size: 'lg',  backdrop: 'static', keyboard: false });
            }//end if
          });
      }//end cancelled

      viewDetails(details: RewardRaffle){
          this.showDetails = true;
          this.detailViewed = cloneDeep(details);
        
          let startD = new Date(this.detailViewed['dateStart']);
          let endD = new Date(this.detailViewed['dateEnd']);
          let drawD = new Date(this.detailViewed['dateDraw']);

          this.model = { year: startD.getFullYear(), month: startD.getMonth() + 1, day: startD.getDate() };
          this.model2 = { year: endD.getFullYear(), month: endD.getMonth() + 1, day: endD.getDate(), };
          this.model3 = { year: drawD.getFullYear(), month: drawD.getMonth() + 1, day: drawD.getDate(), };

      }//end fn
    
      onFileSelected(event: any): void {
        const file: File = event.target.files[0];
        if (file) {
          const reader = new FileReader(); 
          reader.onload = (e: any) => {
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(file);
          this.vPhoto = file;
        }//end
      }//end fn
    
      closeAllModals(){
        this.modalService.dismissAll();
      }//end fn

      ngOnInit(): void {
        this.fetchRewardsList('for_review');
      }

      showCreateRewardForm(modal: TemplateRef<any>){
        this.modalService.open(modal,{ size: 'lg',  backdrop: 'static', keyboard: false });
      }//end fn
      
      enableEditForm(){
        this.enableEdit = true;
      }//end fn
      
      disableEditForm(){
        this.enableEdit = false;
      }//end fn

      returnToList(){
          this.showDetails = false;
          this.detailViewed = {};
      
          this.currentPage = 1;
          this.currentSelectedPageList = this.rewardsList;
      }//end fn

      updateReward(confirmModal: TemplateRef<any>){
        let startdate = this.model?.year + '-' + ((this.model!.month.toString().length > 1) ? this.model!.month: '0'+this.model!.month) + '-' + ((this.model!.day.toString().length > 1) ? this.model!.day: '0'+this.model!.day);
        let starttime = ((this.time.hour.toString().length > 1) ? this.time.hour: '0'+this.time.hour) + ':' + ((this.time.minute.toString().length > 1) ? this.time.minute: '0'+this.time.minute)+ ':00';

        let enddate = this.model2?.year + '-' + ((this.model2!.month.toString().length > 1) ? this.model2!.month: '0'+this.model2!.month) + '-' + ((this.model2!.day.toString().length > 1) ? this.model2!.day: '0'+this.model2!.day);
        let endtime = ((this.time2.hour.toString().length > 1) ? this.time2.hour: '0'+this.time2.hour) + ':' + ((this.time2.minute.toString().length > 1) ? this.time2.minute: '0'+this.time2.minute)+ ':00';

        let drawdate = this.model3?.year + '-' + ((this.model3!.month.toString().length > 1) ? this.model3!.month: '0'+this.model3!.month) + '-' + ((this.model3!.day.toString().length > 1) ? this.model3!.day: '0'+this.model3!.day);
        let drawtime = ((this.time3.hour.toString().length > 1) ? this.time3.hour: '0'+this.time3.hour) + ':' + ((this.time3.minute.toString().length > 1) ? this.time3.minute: '0'+this.time3.minute)+ ':00';
        
        this.detailViewed['points'] = parseInt(this.detailViewed['points']);
        this.detailViewed['noWinner'] = parseInt(this.detailViewed['noWinner']);

        this.detailViewed['dateStart'] = startdate + ' ' + starttime;
        this.detailViewed['dateEnd'] = enddate + ' ' + endtime;
        this.detailViewed['dateDraw'] = drawdate + ' ' + drawtime;

        var errors: number = 0;
        Object.keys(this.detailViewed).forEach((x) => {
          if(x != 'photo' && x != 'ticketCount' && x != 'rewardRaffleEntryList'){
            if(this.detailViewed[x] == ''){
              console.log(x);
              errors ++;
            }//end if
          }//end if
        });

        if(errors != 0){
          alert('Please enter all required fields');
        }else{
          this.modalService.open(confirmModal, { size: 'md',  backdrop: 'static', keyboard: false });
        }//end if
      }//end fn

      updateThisDetails(successModal: TemplateRef<any>){
        const formData:any = new FormData();      
        if (this.vPhoto) {
            formData.append('file', this.vPhoto, this.vPhoto.name);
            this.dasher.uploadPhoto(formData, 'rewards').subscribe((res: any) => {
                this.detailViewed['photo'] = res.photo;
                let copyUpdateForm = {...this.detailViewed};
                let raffleID = copyUpdateForm['rewardRaffleId'];
                delete copyUpdateForm['__typename']; 
                delete copyUpdateForm['rewardRaffleId']; 
                delete copyUpdateForm['dateCreated']; 
                delete copyUpdateForm['dateUpdated']; 
                delete copyUpdateForm['ticketCount']; 
                delete copyUpdateForm['status'];
                delete copyUpdateForm['audience'];
                delete copyUpdateForm['rewardRaffleEntryList']; 
                
                this.updateRaffleDetails(raffleID, copyUpdateForm, successModal);
                this.disableEditForm();
            });
        }else{
          let copyUpdateForm = {...this.detailViewed};
          let raffleID = copyUpdateForm['rewardRaffleId'];
          delete copyUpdateForm['__typename']; 
          delete copyUpdateForm['rewardRaffleId']; 
          delete copyUpdateForm['dateCreated']; 
          delete copyUpdateForm['dateUpdated']; 
          delete copyUpdateForm['ticketCount']; 
          delete copyUpdateForm['status']; 
          delete copyUpdateForm['audience'];
          delete copyUpdateForm['rewardRaffleEntryList']; 
          
          this.updateRaffleDetails(raffleID, copyUpdateForm, successModal);
        }//end if
      }//end fn

      updateRaffleDetails(raffleID: string, formUpdate: any, successModal: TemplateRef<any>){
        this.apollo
          .mutate({
            mutation: gql`
              mutation updateRewardRaffle(
                $rewardRaffleId: HashedId!
                $updateRaffleInput: UpdateRaffleInput!
              ) {
                updateRewardRaffle(
                  updateRaffleInput: $updateRaffleInput
                  rewardRaffleId: $rewardRaffleId
                ) {
                  rewardRaffleId
                  noWinner
                  points
                  dateUpdated
                }
              }
            `,
            variables:{
              "rewardRaffleId": raffleID,
              "updateRaffleInput": formUpdate
            }
          }).subscribe((res: any) => {
            if(res.length != 0){
              this.disableEditForm();
              this.vPhoto = null;
              this.fetchRewardsList('for_review');
              this.closeAllModals();
              console.log(res); 

              this.modalService.open(successModal, { size: 'lg',  backdrop: 'static', keyboard: false });
            }//end if
          });
      }
}
