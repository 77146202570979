<div class="wrap">
    <app-header></app-header>
    <div class="main-holder">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                        <li><span>Transactions</span></li>
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Transactions</h2>
                </div>
                <hr>
                <div class="list-tabs">
                    <ul>
                        <li [ngClass]="{'active': transTabs['express']}"><a (click)="tabCallFunction('express')" style="cursor: pointer;">Express</a></li>
                        <li [ngClass]="{'active': transTabs['okride']}"><a (click)="tabCallFunction('okride')" style="cursor: pointer;">OK! Ride</a></li>
                        <li [ngClass]="{'active': transTabs['mototaxi']}"><a (click)="tabCallFunction('mototaxi')" style="cursor: pointer;">OK! MotoTaxi</a></li>
                    </ul>
                </div>

                <div class="cards">
                    <div class="row">
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Transactions</small>
                                    <h4>{{ this.transactionList.length }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/commission.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Completed</small>
                                    <h4>{{ completeCount }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/commission.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Cancelled</small>
                                    <h4>{{ cancelledCount }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/earnings.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Ongoing</small>
                                    <h4>{{ ongoingCount }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/payout.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Pending</small>
                                    <h4>{{ pendingCount }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/payout.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-holder">
                    <div class="tools">
                        <!-- <div class="list-title" style="width:15%;">
                            <h4 *ngIf="transTabs['express']">List of Express Transactions</h4>
                            <h4 *ngIf="transTabs['okride']">List of OK Ride Transactions</h4>
                            <h4 *ngIf="transTabs['mototaxi']">List of Motaxi Transactions</h4>
                        </div> -->
                        <div class="search mg-right-auto">
                            <img src="../../../../assets/okito/images/icons/magnifying.svg">
                            <input (input)="searchData($event)" [(ngModel)]="this.searchKeyword" type="text" class="text-search"
                                placeholder="Search">
                        </div>
                        <div class="search mg-right-auto" style="width:58%; ">
                            <span style="font-weight:bold;margin-right:10px;margin-left:20px;">Sorting: </span>
                            <select class="form-select" style="width:30%;margin-right:10px;" [(ngModel)]="transSortBy"
                                (ngModelChange)="onSortChange()">
                                <option value="dateCreated">Date Created</option>
                                <option value="status">Status</option>
                                <option value="totalPrice">Total Price</option>
                            </select>
                        
                            <div class="btn-group" role="group" aria-label="Sort by.." style="width:5%;">
                                <button type="button" [ngClass]="{'active': transSortDirection === 'asc'}" class="btn btn-secondary"
                                    (click)="transSortDirection = 'asc'; onSortChange()">Ascending</button>
                                <button type="button" [ngClass]="{'active': transSortDirection === 'desc'}" class="btn btn-secondary"
                                    (click)="transSortDirection = 'desc'; onSortChange()">Descending</button>
                            </div>
                        </div>
                        <div class="actions">
                            <button class="btn btn-sm btn-default" (click)="this.downloadReport();">Download</button>
                        </div>
                    </div>
                    <div class="list table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Partner Name</th>
                                    <th>Customer Name</th>
                                    <th>Total Price</th>
                                    <th>Status</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="currentSelectedPageList.length != 0; else noRecordsMain">
                                <tr *ngFor="let trans of this.currentSelectedPageList">
                                    <td>{{ (this.transTabs['express']) ? trans.bookingExpressId : trans.bookingRideId }}</td>
                                    <td *ngIf="trans.partner; else noPartner">{{ trans.partner.firstName }} {{ trans.partner.middleName }} {{ trans.partner.lastName }}</td>
                                    <ng-template #noPartner><td>---</td></ng-template>
                                    <td *ngIf="trans.user; else noUser">{{ trans.user.name }}</td>
                                    <ng-template #noUser><td>---</td></ng-template>
                                    <td>{{ trans.totalPrice | currency : 'PHP' }}</td>
                                    <td>
                                        <span class='stat stat-amend' *ngIf="trans.status == 'canceled'">Cancelled</span>
                                        <span class='stat stat-active' *ngIf="trans.status == 'pending'">Pending</span>
                                        <span class='stat stat-amend' *ngIf="trans.status == 'accepted'">Accepted</span>
                                        <span class='stat stat-amend' *ngIf="trans.status == 'ongoing_pickup'">On Going Pickup</span>
                                        <span class='stat stat-approved' *ngIf="trans.status == 'arrive_pickup'">Arrived Pickup</span>
                                        <span class='stat stat-approved' *ngIf="trans.status == 'ongoing_destination'">Ongoing Destination</span>
                                        <span class='stat stat-approved' *ngIf="trans.status == 'drop_off'">Drop Off</span>
                                        <span class='stat stat-approved' *ngIf="trans.status == 'completed'">Completed</span>
                                    </td>
                                    <td><a (click)="viewTopupDetails(trans, upDetails)" style="cursor: pointer;">View</a></td>
                                </tr>
                                </ng-container>
                                <ng-template #noRecordsMain>
                                    <tr>
                                        <td colspan="8" class="no-records-msg">
                                            <h6 class="ponds-contents-med-bold">No records found</h6>
                                        </td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                    <div class="list-footer">
                        <div class="pager">
                            <button (click)="nextPrev('prev')" [disabled]="this.disableActionBtns.prev"><img
                                    src="../../../../assets/okito/images/icons/prev.svg"></button>
                            <!-- <ol>
                                                                    <li *ngFor="let page of this.riderPages" style="cursor: pointer;" (click)="setPageSelected(page)"><a>{{ page
                                                                            }}</a></li>
                                                        
                                                                </ol> -->
                            <button (click)="nextPrev('next')" [disabled]="this.disableActionBtns.next"><img
                                    src="../../../../assets/okito/images/icons/next.svg"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #upDetails let-offcanvas>
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel">
                <small>{{ this.detailViewed['dateCreated'] | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD h:mm:ss A'
                    }}</small>
                <small>Booking ID</small>
                {{ (this.transTabs['express']) ? this.detailViewed['bookingExpressId'] : this.detailViewed['bookingRideId']  }}
            </h5>
            <span class="stat stat-canceled" *ngIf="this.detailViewed['status'] === 'pending'">Pending</span>
            <span class="stat stat-ongoing" *ngIf="this.detailViewed['status'] === 'accepted'">Accepted</span>
            <span class="stat stat-ongoing" *ngIf="this.detailViewed['status'] === 'ongoing_pickup'">Ongoing -
                Pickup</span>
            <span class="stat stat-ongoing" *ngIf="this.detailViewed['status'] === 'ongoing_destination'">Ongoing -
                Destination</span>
            <span class="stat stat-completed" *ngIf="this.detailViewed['status'] === 'completed'">Completed</span>
            <span class="stat stat-canceled" *ngIf="this.detailViewed['status'] === 'canceled'">Cancelled</span>
            <button type="button" class="btn-close text-reset" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <div class="booking-holder">
                <google-map height="250px" width="370px" [center]="center" [zoom]="zoom" (mapClick)="moveMap($event)"
                    (mapMousemove)="move($event)">
                    <map-polyline [path]="polyLinePath" [options]="polyLineOptions" />
                </google-map>
                <div class="booking-rider-details">
                        <div class="pfp">
                            <img src="./../../../assets/okito/images/avatar.png">
                        </div>
                        <div class="rider-info" *ngIf="this.detailViewed['partner'] !== null">
                            {{ this.detailViewed['partner']['firstName'] }} {{ this.detailViewed['partner']['middleName'] }}
                            {{ this.detailViewed['partner']['lastName'] }}<br>
                            <small>{{ this.detailViewed['vehicleCategory'] }} - {{ this.detailViewed['vehicleId'] }}</small>
                        </div>
                        <div class="rider-info" *ngIf="this.detailViewed['partner'] === null">
                            ---
                        </div>
                        <div class="rider-rating" *ngIf="this.detailViewed['partner'] !== null">
                            <img src="./../../../assets/okito/images/star.svg">
                    
                    
                            <h4 *ngIf="this.transTabs['express'] && this.detailViewed['partnerRating'] === null">0.00</h4>
                            <h4 *ngIf="this.transTabs['express'] && this.detailViewed['partnerRating'] !== null">{{
                                this.detailViewed['partnerRating']['rate'] | number: '1.2-2' }}</h4>
                            <h4 *ngIf="!this.transTabs['express'] && this.detailViewed['partnerRating'] === null">0.00</h4>
                            <h4 *ngIf="!this.transTabs['express'] && this.detailViewed['partnerRating'] !== null">{{
                                this.detailViewed['partnerRating']['rate'] | number: '1.2-2' }}</h4>
                        </div>
                    </div>
                <div class="booking-address">
                    <div class="booking-address-details">
                        <img src="./../../../assets/okito/images/pickup.svg">
                        <div class="sender-details">
                            <label>Pick-up Location</label>
                            <h4>{{ this.detailViewed['startFormattedAddress'] }}</h4>
                            <div class="sender">
                                <span>{{ this.detailViewed['senderName'] }}</span>
                                <span>{{ this.detailViewed['senderMobile'] }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="booking-address-details">
                        <img src="./../../../assets/okito/images/dropoff.svg">
                        <div class="sender-details">
                            <h4>{{ this.detailViewed['endFormattedAddress'] }}</h4>
                            <div class="sender">
                                <span>{{ this.detailViewed['receipientName'] }}</span>
                                <span>{{ this.detailViewed['receipientMobile'] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="booking-note">
                    <label>Note to rider</label>
                    <p>{{ this.detailViewed['notes'] }}</p>
                </div>
                <div class="booking-distance">
                    <div class="fees-grp">
                        <div class="distance-label">
                            <img src="./../../../assets/okito/images/dropoff.svg">
                            <label>Distance</label>
                        </div>
                        <span>{{ (this.transTabs['express']) ? this.detailViewed['totalDistance'] :
                            this.detailViewed['kmDistance'] }} km</span>
                    </div>
                </div>
                <div class="pwdsc">
                    <div class="pwdsc-id">
                        <img src="./../../../assets/okito/images/pwdsc.png">
                        <div class="pwdsc-info">
                            <label>Discount</label>
                            <h4>PWD/SC/Student Card</h4>
                        </div>
                    </div>
                </div>
                <div class="booking-fees">
                    <div class="fees-grp">
                        <label>Delivery Fee</label>
                        <span>{{ this.detailViewed['farePrice'] | currency:'PHP' }}</span>
                    </div>
                    <div class="fees-grp">
                        <label>Discount</label>
                        <span class="discount">-{{ this.detailViewed['discountPrice'] | currency:'PHP' }}</span>
                    </div>
                    <div class="total-fees">
                        <label>Total Delivery Fee</label>
                        <div class="total">
                            <img src="./../../../assets/okito/images/cash.svg">
                            <span>{{ this.detailViewed['totalPrice'] | currency:'PHP' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel">
                <small>{{ this.detailViewed['dateCreated'] | date:'YYYY-MM-DD h:mm:ss A' }}</small>
            </h5>
            <button type="button" class="btn-close text-reset" (click)="offcanvas.dismiss('Cross click')"></button>
        </div>
        <div class="offcanvas-body">
            <div class="booking-holder">
                <h5>Top Up Details</h5>
                <div class="details-grp">
                    <label>Transaction ID</label>
                    <span>{{ (this.transTabs['express']) ? this.detailViewed['bookingExpressId'] : this.detailViewed['bookingRideId']  }}</span>
                </div>
                <div class="details-grp">
                    <label>Partner's Mobile Number</label>
                    <span>{{ this.detailViewed['partner']['mobile'] }}</span>
                </div>
                <div class="details-grp">
                    <label>Partner's Email Address</label>
                    <span>{{ this.detailViewed['partner']['email'] }}</span>
                </div>
                <div class="details-grp">
                    <label>Partner's Name</label>
                    <span>{{ this.detailViewed['partner']['firstName'] + ' ' +
                        this.detailViewed['partner']['lastName']}}</span>
                </div>
                <div class="details-grp">
                    <label>Vehicle Type</label>
                    <span>{{ this.detailViewed['partner']['vehicleType'] }}</span>
                </div>
                <div class="details-grp">
                            <label>Service Type</label>
                            <span>Ok! Express, Ok! MotoTaxi</span>
                        </div>
                <div class="details-grp">
                    <label>Amount</label>
                    <span>{{ this.detailViewed['totalPrice'] | currency:'PHP' }}</span>
                </div>
                
            </div>
    
        </div> -->
    </ng-template>
</div>