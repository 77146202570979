<div class="wrap">
    <app-header></app-header>
    <div class="main-holder">
        <app-nav></app-nav>
        <div class="main">
            <div class="main-content">
                <div class="bread">
                    <ol>
                        <li><a [routerLink]="['/']"><img src="../../../../assets/okito/images/icons/home.svg"></a></li>
                        <!-- <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                                                <li><a href="#">User</a></li>
                                                <img src="../../../../assets/okito/images/icons/chevron-right.svg">
                                                <li><span>User Details</span></li> -->
                    </ol>
                </div>
                <div class="content-title">
                    <h2>Overview</h2>
                </div>
                <hr>
                <div class="list-holder">
                    <div class="tools">
                        <div class="calendar">
                            <label class="form-label" style="margin-right: 5px;">Date</label>
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker
                                #d="ngbDatepicker" />
                            <img src="../../../../assets/okito/images/calendar.svg" (click)="d.toggle()" style="cursor: pointer;">
                        </div>
                        <div class="pager">
                            <ol>
                                <li class="active"><a href="#">Past 7 Days</a></li>
                                <li><a href="#">Past 30 Days</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div class="cards">
                    <div class="row">
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Registered</small>
                                    <h4>{{ this.homepageSummary['totalUser'] | number: '1.0-0' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/registered.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Active Customers</small>
                                    <h4>{{ this.homepageSummary['totalActiveUser'] | number: '1.0-0' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/users.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Distance Covered</small>
                                    <h4>{{ this.homepageSummary['totalDistance'] | number: '1.2-2' }} km</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/distance.svg">
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="card-item">
                                <div class="card-content">
                                    <small>Total Rider Supply</small>
                                    <h4>{{ this.homepageSummary['totalPartner'] | number: '1.0-0' }}</h4>
                                </div>
                                <img src="../../../../assets/okito/images/card-icons/supply.svg">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-multiple">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card-multiple-block">
                                <div class="card-multiple-item">
                                    <img src="../../../../assets/okito/images/card-icons/earnings.svg">
                                    <div class="card-content">
                                        <small>Total Partner Sales</small>
                                        <h4>{{ this.homepageSummary['totalSales'] | currency:'PHP' }}</h4>
                                    </div>
                                </div>
                                <div class="card-multiple-item">
                                    <img src="../../../../assets/okito/images/card-icons/payout.svg">
                                    <div class="card-content">
                                        <small>Total Okito Commission</small>
                                        <h4>{{ this.homepageSummary['totalEarnings'] | currency:'PHP' }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8 border-left">
                            <div class="card-multiple-inline">
                                <div class="card-inline-item">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                        <small>Motorcycle</small>
                                        <h4>{{ this.homepageSummary['vehicleOverview']['Motorcycle'] | number: '1.0-0' }}</h4>
                                    </div>
                                </div>
                                <div class="card-inline-item">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                        <small>Sedan</small>
                                        <h4>{{ this.homepageSummary['vehicleOverview']['Sedan'] | number: '1.0-0' }}</h4>
                                    </div>
                                </div>
                                <div class="card-inline-item border-right-none">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                        <small>SUV</small>
                                        <h4>{{ this.homepageSummary['vehicleOverview']['SUV'] | number: '1.0-0' }}</h4>
                                    </div>
                                </div>
                                <div class="card-inline-item">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                        <small>MPV</small>
                                        <h4>{{ this.homepageSummary['vehicleOverview']['MPV'] | number: '1.0-0' }}</h4>
                                    </div>
                                </div>
                                <div class="card-inline-item">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                        <small>Pick Up</small>
                                        <h4>{{ this.homepageSummary['vehicleOverview']['Pickup'] | number: '1.0-0'}}</h4>
                                    </div>
                                </div>
                                <div class="card-inline-item border-right-none">
                                    <!-- <img src="../../../../assets/okito/images/card-icons/payout.svg"> -->
                                    <div class="card-content">
                                        <small>Van</small>
                                        <h4>{{ this.homepageSummary['vehicleOverview']['FB/Van'] | number: '1.0-0' }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
