import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { AuthStore } from '@store/auth.store';
import { CommonModule, JsonPipe } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { COMMAND_CENTER_EXPRESS_OVERVIEW } from '@gql_queries/command_center/queries';

interface CommandCenterExpressOverview {
  twoWheelsBookingCount: number;
  fourWheelsBookingCount: number;
  pending: {
    twoWheels: number;
    fourWheels: number;
  };
  ongoing: {
    twoWheels: number;
    fourWheels: number;
  };
  completed: {
    twoWheels: number;
    fourWheels: number;
  };
  cancelled: {
    twoWheels: number;
    fourWheels: number;
  };
}

@Component({
  selector: 'app-dashboard-comcenter-expresspage',
  standalone: true,
  imports:      [ CommonModule ],
  templateUrl: './dashboard-comcenter-expresspage.component.html',
  styleUrls: [
    './dashboard-comcenter-expresspage.component.scss',
    '../../../../assets/okito-comcenter/css/main.css',
    '../../../../assets/okito-comcenter/vendor/bootstrap/css/bootstrap.min.css',
    '../../../../assets/okito-comcenter/vendor/font-awesome/css/font-awesome.min.css'
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class DashboardComcenterExpresspageComponent {
  private refreshInterval: any;
  date = new Date();
  
  constructor(
    private apollo: Apollo,
    private authStore: AuthStore,
    private router: Router
  ) { 
     // Update time every second
     setInterval(() => {
      this.date = new Date();
    }, 1000);
  }

  ngOnInit() {
    // Initial fetch
    this.fetchOverviewPageData();
    
    // Set up interval (30000ms = 30s)
    this.refreshInterval = setInterval(() => {
      this.fetchOverviewPageData();
    }, 30000);
  }

  ngOnDestroy() {
    // Clean up interval when component is destroyed
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  public overview: CommandCenterExpressOverview = {
    twoWheelsBookingCount: 0,
    fourWheelsBookingCount: 0,
    pending: {
      twoWheels: 0,
      fourWheels: 0
    },
    ongoing: {
      twoWheels: 0,
      fourWheels: 0
    },
    completed: {
      twoWheels: 0,
      fourWheels: 0
    },
    cancelled: {
      twoWheels: 0,
      fourWheels: 0
    }
  };

  
  fetchOverviewPageData() {
    this.apollo
      .watchQuery({
        fetchPolicy: 'network-only',
        query: COMMAND_CENTER_EXPRESS_OVERVIEW
      }).valueChanges.subscribe({
        next: (res: any) => {
          if(res.data['commandCenterExpressOverview']){
            const overview = res.data['commandCenterExpressOverview'];
            this.overview = overview;
            console.log(this.overview);
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }
        }
      });
  }
}
