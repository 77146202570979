<!-- <nav class="nav">
    <div class="nav-grid">
        <h3>Dashboard</h3>
        <ul>
            <li [ngClass]="{'active': this.navArray['home']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('')">
                    <img src="../../../../assets/okito/images/nav/icon-1-active.svg" *ngIf="this.navArray['home']; else menu1Icon;">
                    <ng-template #menu1Icon>
                    <img src="../../../../assets/okito/images/nav/icon-1.svg">
                    </ng-template>
                    &nbsp;Overview
                </span>
            </li>
            <li [ngClass]="{'active': this.navArray['users']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/users')">
                <img src="../../../../assets/okito/images/nav/icon-2-active.svg" *ngIf="this.navArray['users']; else menu2Icon;">
                <ng-template #menu2Icon>
                    <img src="../../../../assets/okito/images/nav/icon-2.svg">
                </ng-template>
                &nbsp;Customers
                </span>
            </li>
            <li [ngClass]="{'active': this.navArray['partners']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/riders')">
                <img src="../../../../assets/okito/images/nav/icon-3-active.svg" *ngIf="this.navArray['partners']; else menu3Icon;">
                <ng-template #menu3Icon>
                    <img src="../../../../assets/okito/images/nav/icon-3.svg">
                </ng-template>
                &nbsp;Partners
                </span>
            </li>
            <li [ngClass]="{'active': this.navArray['partner_earnings']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/partner-earnings')">
                    <img src="../../../../assets/okito/images/nav/icon-13-active.svg"
                        *ngIf="this.navArray['partner_earnings']; else menu14Icon;">
                    <ng-template #menu14Icon>
                        <img src="../../../../assets/okito/images/nav/icon-13.svg">
                    </ng-template>
                    &nbsp;Partners Earnings
                </span>
            </li>
            <li [ngClass]="{'active': this.navArray['transactions']}">
            <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/transactions')">
                <img src="../../../../assets/okito/images/nav/icon-14-active.svg"
                    *ngIf="this.navArray['transactions']; else menu13;">
                <ng-template #menu13>
                    <img src="../../../../assets/okito/images/nav/icon-14.svg">
                </ng-template>
                &nbsp;Booking Transactions
            </span>
            </li>
            <li [ngClass]="{'active': this.navArray['m_top_ups']}" (click)="navigateMenu('admin/topups')">
                <span class="nav-link" style="cursor:pointer;" >
                    <img src="../../../../assets/okito/images/nav/icon-15-active.svg"
                        *ngIf="this.navArray['m_top_ups']; else menu15Icon;">
                    <ng-template #menu15Icon>
                        <img src="../../../../assets/okito/images/nav/icon-15.svg">
                    </ng-template>
                    &nbsp;Top-Ups
                </span>
            </li>
            
            <li [ngClass]="{'active': this.navArray['withdrawal_cms_m']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/withdrawal-cms')">
                    <img src="../../../../assets/okito/images/nav/icon-8-active.svg"
                        *ngIf="this.navArray['withdrawal_cms_m']; else menu9Icon;">
                    <ng-template #menu9Icon>
                        <img src="../../../../assets/okito/images/nav/icon-8.svg">
                    </ng-template>
                    &nbsp;Withdrawal Management
                </span>
            </li>
            
            <li [ngClass]="{'active': this.navArray['coupons_m']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/coupons-management')">
                    <img src="../../../../assets/okito/images/nav/icon-7-active.svg"
                        *ngIf="this.navArray['coupons_m']; else menu8Icon;">
                    <ng-template #menu8Icon>
                        <img src="../../../../assets/okito/images/nav/icon-7.svg">
                    </ng-template>
                    &nbsp;Coupons
                </span>
            </li>
            


            <li [ngClass]="{'active': this.navArray['rewards_cms_m']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/rewards-cms')">
                    <img src="../../../../assets/okito/images/nav/icon-12-active.svg"
                        *ngIf="this.navArray['rewards_cms_m']; else menu10Icon;">
                    <ng-template #menu10Icon>
                        <img src="../../../../assets/okito/images/nav/icon-12.svg">
                    </ng-template>
                    &nbsp;Rewards Management
                </span>
            </li>

            <li [ngClass]="{'active': this.navArray['vehicle_cms_m']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/vehicle-cms')">
                    <img src="../../../../assets/okito/images/nav/icon-11-active.svg"
                        *ngIf="this.navArray['vehicle_cms_m']; else menu11Icon;">
                    <ng-template #menu11Icon>
                        <img src="../../../../assets/okito/images/nav/icon-11.svg">
                    </ng-template>
                    &nbsp;Vehicle Management
                </span>
            </li>

            <li [ngClass]="{'active': this.navArray['location_cms_m']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/location-cms')">
                    <img src="../../../../assets/okito/images/nav/icon-10-active.svg"
                        *ngIf="this.navArray['location_cms_m']; else menu12Icon;">
                    <ng-template #menu12Icon>
                        <img src="../../../../assets/okito/images/nav/icon-10.svg">
                    </ng-template>
                    &nbsp;Location Management
                </span>
            </li>

            <li [ngClass]="{'active': this.navArray['ads_m']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/ads-management')">
                    <img src="../../../../assets/okito/images/nav/icon-4-active.svg"
                        *ngIf="this.navArray['ads_m']; else menu5Icon;">
                    <ng-template #menu5Icon>
                        <img src="../../../../assets/okito/images/nav/icon-4.svg">
                    </ng-template>
                    &nbsp;Ads Management
                </span>
            </li>
            <li [ngClass]="{'active': this.navArray['news_and_events_m']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/newsevents-management')">
                    <img src="../../../../assets/okito/images/nav/icon-5-active.svg"
                        *ngIf="this.navArray['news_and_events_m']; else menu6Icon;">
                    <ng-template #menu6Icon>
                        <img src="../../../../assets/okito/images/nav/icon-5.svg">
                    </ng-template>
                    &nbsp;News and Events
                </span>
            </li>
            <li [ngClass]="{'active': this.navArray['notification_m']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/notification-management')">
                    <img src="../../../../assets/okito/images/nav/icon-6-active.svg"
                        *ngIf="this.navArray['notification_m']; else menu7Icon;">
                    <ng-template #menu7Icon>
                        <img src="../../../../assets/okito/images/nav/icon-6.svg">
                    </ng-template>
                    &nbsp;Notification
                </span>
            </li>
            <li [ngClass]="{'active': this.navArray['admins']}">
                <span class="nav-link" style="cursor:pointer;" (click)="navigateMenu('admin/accounts')">
                    <img src="../../../../assets/okito/images/nav/icon-2-active.svg"
                        *ngIf="this.navArray['admins']; else menu4Icon;">
                    <ng-template #menu4Icon>
                        <img src="../../../../assets/okito/images/nav/icon-2.svg">
                    </ng-template>
                    &nbsp;Admins
                </span>
            </li>
        </ul>
    </div>
    <div class="nav-grid logout">
        <ul>
            <li><a style="cursor: pointer;" (click)="this.accountLogout()">
                <img src="../../../../assets/okito/images/nav/logout.svg"> Logout
            </a></li>
        </ul>
    </div>
</nav> -->


<nav class="nav">
    <div class="nav-grid">
        <ul>
            <li [ngClass]="{'active': this.navArray['home']}"><a  style="cursor:pointer;" (click)="navigateMenu('')"><i class="fa fa-dashboard"></i> Overview</a></li>
            <li class="drp-menu" [ngClass]="{'active': (this.navArray['admins'] || this.navArray['partners'] || this.navArray['users'])}" (click)="openParentMenuFn('users')">
                <a style="cursor:pointer;"><i class="fa fa-user"></i> Users <i class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['users']) || (this.navArray['admins'] || this.navArray['partners'] || this.navArray['users']) ? 'block' : 'none'}">
                    <li [ngClass]="{'active': this.navArray['users']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/users')">Customers</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['partners']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/riders')">Partners</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['admins']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/accounts')">Admins</a>
                    </li>
                </ul>
            </li>
            <li class="drp-menu" [ngClass]="{'active': (this.navArray['transactions'] || this.navArray['m_top_ups'] || this.navArray['withdrawal_cms_m'])}" (click)="openParentMenuFn('transactions')">
                <a style="cursor:pointer;"><i class="fa fa-briefcase"></i> Transactions <i class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['transactions']) || (this.navArray['transactions'] || this.navArray['m_top_ups'] || this.navArray['withdrawal_cms_m']) ? 'block' : 'none'}">
                    <li [ngClass]="{'active': this.navArray['transactions']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/transactions')">Bookings</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['m_top_ups']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/topups')">Top-ups</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['withdrawal_cms_m']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/withdrawal-cms')">Withdrawals</a>
                    </li>
                </ul>
            </li>
            <li class="drp-menu" [ngClass]="{'active': (this.navArray['coupons_m'] || this.navArray['rewards_cms_m'])}" (click)="openParentMenuFn('disc_rewards')">
                <a style="cursor:pointer;"><i class="fa fa-gift"></i> Discounts and Rewards <i class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['disc_rewards']) || (this.navArray['coupons_m'] || this.navArray['rewards_cms_m']) ? 'block' : 'none'}">
                    <li [ngClass]="{'active': this.navArray['coupons_m']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/coupons-management')">Coupons</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['rewards_cms_m']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/rewards-cms')">Rewards</a>
                    </li>
                </ul>
            </li>
            <li class="drp-menu" [ngClass]="{'active': (this.navArray['ads_m'] || this.navArray['vehicle_cms_m'] || this.navArray['location_cms_m'])}" (click)="openParentMenuFn('cms')">
                <a style="cursor:pointer;"><i class="fa fa-cog"></i> CMS <i class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['cms']) || (this.navArray['ads_m'] || this.navArray['vehicle_cms_m'] || this.navArray['location_cms_m']) ? 'block' : 'none'}">
                    <li [ngClass]="{'active': this.navArray['ads_m']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/ads-management')">Ads Management</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['vehicle_cms_m']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/vehicle-cms')">Vehicle Management</a>
                    </li>
                    <li [ngClass]="{'active': this.navArray['location_cms_m']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/location-cms')">Location Management</a>
                    </li>
                </ul>
            </li>
            <li class="drp-menu" [ngClass]="{'active': (this.navArray['news_and_events_m'] || this.navArray['notification_m'])}" (click)="openParentMenuFn('art_notifs')">
                <a style="cursor:pointer;"><i class="fa fa-bullhorn"></i> Articles and Notifications <i
                        class="arrow fa fa-angle-down"></i></a>
                <ul class="drp-list" [ngStyle]="{'display': (openParentMenu['art_notifs']) || (this.navArray['news_and_events_m'] || this.navArray['notification_m']) ? 'block' : 'none'}">
                    <li [ngClass]="{'active': this.navArray['news_and_events_m']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/newsevents-management')">News and Events</a></li>
                    <li [ngClass]="{'active': this.navArray['notification_m']}">
                        <a style="cursor:pointer;" (click)="navigateMenu('admin/notification-management')">Notifications
</a>
                    </li>
                </ul>
            </li>
            <li><a style="cursor: pointer;" (click)="this.accountLogout()"><i class="fa fa-sign-out"></i> Logout</a></li>
        </ul>
    </div>
</nav>