import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { AuthStore } from '@store/auth.store';
import { CommonModule, JsonPipe } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { COMMAND_CENTER_OVERVIEW } from '@gql_queries/command_center/queries';


interface CommandCenterOverview {
  totalUser: number;
  twoWheels: {
    totalApprovedPartners: number;
    expressCount: number;
    motoTaxiCount: number;
  };
  fourWheels: {
    totalApprovedPartners: number;
    expressCount: number;
    okRideCount: number;
  };
}

@Component({
  selector: 'app-dashboard-comcenter-overview',
  standalone: true,
  imports:      [ CommonModule ],
  templateUrl: './dashboard-comcenter-overview.component.html',
  styleUrls: [
    './dashboard-comcenter-overview.component.scss',
    '../../../../assets/okito-comcenter/css/main.css',
    '../../../../assets/okito-comcenter/vendor/bootstrap/css/bootstrap.min.css',
    '../../../../assets/okito-comcenter/vendor/font-awesome/css/font-awesome.min.css'
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class DashboardComcenterOverviewComponent implements OnInit, OnDestroy {
  private refreshInterval: any;
  date = new Date();
  
  constructor(
    private router: Router,
    private apollo: Apollo,
    private authStore: AuthStore
  ) { 
     // Update time every second
     setInterval(() => {
      this.date = new Date();
    }, 1000);
  }

  ngOnInit() {
    // Initial fetch
    this.fetchOverviewPageData();
    
    // Set up interval (30000ms = 30s)
    this.refreshInterval = setInterval(() => {
      this.fetchOverviewPageData();
    }, 30000);
  }

  ngOnDestroy() {
    // Clean up interval when component is destroyed
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  public overview: CommandCenterOverview = {
    totalUser: 0,
    twoWheels: {
      totalApprovedPartners: 0,
      expressCount: 0,
      motoTaxiCount: 0
    },
    fourWheels: {
      totalApprovedPartners: 0,
      expressCount: 0,
      okRideCount: 0
    }
  };

  
  fetchOverviewPageData() {
    this.apollo
      .watchQuery({
        fetchPolicy: 'network-only',
        query: COMMAND_CENTER_OVERVIEW
      }).valueChanges.subscribe({
        next: (res: any) => {
          if(res.data['commandCenterOverview']){
            const overview = res.data['commandCenterOverview'];
            this.overview = overview;
            console.log(this.overview);
          }
        },
        error: (error) => {
          if (error.message === 'Unauthorized' || error?.networkError?.status === 403) {
            this.authStore.logout().subscribe(() => {
              // Optional: Redirect to login page or show message
              this.router.navigate(['/admin/login']);
            });
          }
        }
      });
  }
}
